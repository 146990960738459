import React, { useRef } from "react";
import PropTypes from "prop-types";
import * as S from "./ContextMenu.styles";

export interface IContextMenuItem {
    displayText: string;
    isLink: boolean;
    onClick: () => void;
}

export interface IContextMenuProps {
    isOpen: boolean;
    topElement?: React.ReactNode;
    menuItems: IContextMenuItem[];
    onClose: () => void;
}

/**
 * Context Menu component.
 * @param [isOpen] - boolean property. Used to manage visibiility state.
 * @param [topElement] - Inserts a custom element above the standard menu items.
 * @param [menuItem] - Array of menu items.
 * @param [onClose] - Callback is fired when the user mouseups outside of the context menu.
 */
//TODO: We should separate the functionality and styles to increase its reusability. In the meantime, I added the "...rest" prop to enable overwriting styles elsewhere.
const ContextMenu: React.FC<IContextMenuProps> = ({ isOpen, topElement, menuItems, onClose, ...rest }) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleMouseUpEvent = (event: MouseEvent): void => {
        if (ref && ref !== null) {
            const cur = ref.current;
            if (cur !== null) {
                if (!cur?.contains(event.target as Node)) {
                    onClose();
                }
            }
        }
    };

    React.useEffect(() => {
        window.addEventListener("mouseup", handleMouseUpEvent);
        return (): void => {
            window.removeEventListener("mouseup", handleMouseUpEvent);
        };
    }, [isOpen]);

    return (
        <S.ContextMenu ref={ref} isOpen={isOpen} {...rest}>
            {topElement}
            {menuItems.map((item) => (
                <S.IContextMenuItem isLink={item.isLink} onClick={item.onClick} key={item.displayText}>
                    {item.displayText}
                </S.IContextMenuItem>
            ))}
        </S.ContextMenu>
    );
};

ContextMenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    topElement: PropTypes.element,
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            displayText: PropTypes.string.isRequired,
            isLink: PropTypes.bool.isRequired,
            onClick: PropTypes.func.isRequired
        }).isRequired
    ).isRequired,
    onClose: PropTypes.func.isRequired
};

export default ContextMenu;
