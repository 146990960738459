// eslint-disable-next-line import/named
import { Configuration, LogLevel, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENTID ?? "",
        authority: process.env.REACT_APP_AUTH_AUTHORITY ?? "",
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? "",
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }

                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        //console.info(message);
                }
            }
        }
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["User.Read"]
};

const graphMe: RedirectRequest = {
    scopes: ["User.Read"],
    redirectStartPage: "/"
};

export const protectedResources = {
    graphMe: {
        ...graphMe,
        endpoint: process.env.REACT_APP_AUTH_GRAPH_ME_ENDPOINT
    },
    wavecore: {
        endpoint: `${process.env.REACT_APP_WAVE_CORE_SERVICE_URL}`,
        scopes: [`${process.env.REACT_APP_WAVE_CORE_SERVICE_CLIENT_SCOPE}`] // e.g. api://xxxxxx/access_as_user
    },
    feeCalculator: {
        endpoint: `${process.env.REACT_APP_FEE_CALC_SERVICE_URL}`,
        scopes: [`${process.env.REACT_APP_FEE_CALC_SERVICE_CLIENT_SCOPE}`]
    }
};
