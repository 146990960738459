import axios from "axios";
import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";
import { protectedResources } from "../auth/authConfig";
import { QueryKeysEnum } from "../types/queryKeys";
import { IManagedContext, TaskAutomationKeyUnion } from "../types/shared";

const getManagedProcessByAccessToken = async <T>(
    context: IManagedContext,
    taskId: TaskAutomationKeyUnion,
    accessToken: string | undefined
): Promise<T> => {
    const url = `${protectedResources.wavecore.endpoint}/api/ManagedProcessByAccessToken/${context.ResourceType}/${context.ProjectSlugOrProjectId}/${context.Id}/${taskId}/${accessToken}`;
    const response = await axios({
        method: "GET",
        url: url,
        headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0"
        }
    });

    return response.data;
};

const useGetManagedProcessByAccessToken = <TMangedProcess>(
    context: IManagedContext | undefined,
    taskId: TaskAutomationKeyUnion,
    accessToken: string | undefined,
    options?: UseQueryOptions<
    TMangedProcess,
    unknown,
    TMangedProcess,
    (IManagedContext | TaskAutomationKeyUnion | QueryKeysEnum | undefined)[]
    >
): UseQueryResult<TMangedProcess> =>
    useQuery(
        [QueryKeysEnum.projectTeamAssignmentApproval, context, taskId],
        () =>
            context
                ? getManagedProcessByAccessToken<TMangedProcess>(
                    context,
                    taskId,
                    accessToken
                )
                : Promise.reject(),
        {
            ...options
        }
    );

export default useGetManagedProcessByAccessToken;
