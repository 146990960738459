import styled from "styled-components";
import { Text } from "@breakingwave/react-ui-components";

const LoadingSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    div:first-child {
        height: auto;
    }
`;

const PageTitleSection = styled.section`
    background-color: #000;
    margin-bottom: 24px;
`;

const InformationText = styled(Text)`
    display: flex;
    margin-bottom: 32px;

    &::before {
        content: "!";
        left: 20px;
        top: 23px;
        border-radius: 50%;
        border: 1px solid white;
        min-width: 10px;
        height: 10px;
        font-size: 8px;
        line-height: 8px;
        text-align: center;
        font-weight: bold;
        margin-right: 12px;
        margin-top: 8px;
    }
`;

const BodySection = styled.section`
    background-color: #232323;
    padding: 40px 0 56px;
`;

// TODO: Make this one a component on its own
const Hr = styled.hr<{ type?: string; color?: string; hidden?: boolean }>`
    border-top: 1px ${({ type }) => type ?? "solid"} ${({ color }) => color ?? "#000"};
    background-color: transparent;
    visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
`;

export { LoadingSection, PageTitleSection, InformationText, BodySection, Hr };
