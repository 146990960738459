import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, Select, Tick, FormControl, FormErrorText } from "@breakingwave/react-ui-components";
import { useTeamAssignmentStepsContext } from "../_state/context";
import { TeamAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import countries from "../../../../../../../utils/countries";
import { TeamAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { countryStepSchema, CountryStepInputs } from "../index.schema";
import * as S from "../../wizard.styles";

const Country: React.FC = () => {
    const { state, dispatch } = useTeamAssignmentStepsContext();

    // Fully typed, shorter spelling of the step name for easy access
    const step = state[TeamAssignmentWizardStepIdentifierEnum.Country];

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<CountryStepInputs>({
        resolver: yupResolver(countryStepSchema),
        defaultValues: {
            country: {
                label: step.answer.country,
                value: step.answer.isoString
            }
        }
    });

    const processAndGoToNext = (data: CountryStepInputs): void => {
        const { country } = data;

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.SET_COUNTRY,
            payload: {
                ...step,
                answer: {
                    country: country.label as typeof countries[keyof typeof countries],
                    isoString: country.value as keyof typeof countries,
                    displayValue: country.label as typeof countries[keyof typeof countries]
                }
            }
        });

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP
        });
    };

    const goBack = (): void => {
        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP
        });
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={500}>
                    Which country will they be working in?
                </Text>
            </S.QuestionWrapper>
            <S.AnswerWrapper>
                <FormControl as="fieldset" style={{ width: 232 }}>
                    <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                            <Select
                                ref={field.ref}
                                defaultValue={
                                    step.answer.country
                                        ? { label: step.answer.country, value: step.answer.isoString }
                                        : undefined
                                }
                                onValueChange={field.onChange}
                                placeholder="Select..."
                                options={Object.entries(countries).map(([key, name]) => ({
                                    value: key,
                                    label: name
                                }))}
                                error={!!errors.country}
                            />
                        )}
                    />
                    {errors.country && <FormErrorText>{errors.country.message}</FormErrorText>}
                </FormControl>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button variant="secondary" onClick={goBack}>
                    Back
                </Button>
                <Button iconSvg={<Tick />} iconSize={9} onClick={handleSubmit(processAndGoToNext)}>
                    Next
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default Country;
