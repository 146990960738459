import { useNavigate } from "react-router-dom";

const errorCodeDictionary = {
    100: "Attestation is missing expected parameters.",
    101: "Attestation state is not valid",
    102: "Attestation state is not valid.  Please try again in a few minutes.",
    103: "Unable to obtain attestation state",
    110: "Training is missing expected parameters.",
    111: "Training state is not valid.  Please ensure you have completed the 'attestation' task.  If you have completed the 'attestation' task then please try again in a few minutes.",
    112: "Training state is not valid.  Please try again in a few minutes.",
    113: "Unable to obtain training state",
    120: "Device selection is missing expected parameters.",
    121: "Device selection state is not valid.  Please ensure you have completed the 'training' task.  If you have completed the 'training' then please try again in a few minutes.",
    122: "Device selection state is not valid.  Please try again in a few minutes.",
    123: "Unable to obtain device selection state",
    124: "country from URL is not found or not valid",
    125: "uType from URL is not found or not valid",
    130: "Join project approval is missing expected parameters"
};

export interface INavigateErrorFunction {
    (code: keyof typeof errorCodeDictionary): void;
}

const useNavigateError = (): INavigateErrorFunction => {
    const navigate = useNavigate();

    return (code: keyof typeof errorCodeDictionary): void => {
        const errorMessage = errorCodeDictionary[code];
        console.log(`Error code: ${code}, error message: ${errorMessage}`);
        navigate("/");

        // TODO: BW are not keen on the error page. Find another solution.
        //navigate(`/error?code=${code}&message=${errorMessage ?? ""}`);
    };
};

export default useNavigateError;
