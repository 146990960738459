import React from "react";
import { Outlet } from "react-router-dom";
import { OnboardingProjectAdminProvider } from "../pages/public/onboarding-project-admin/_state/context";


const OnboardingProjectAdminRoute: React.FC = () => (
    <OnboardingProjectAdminProvider>
        <Outlet />
    </OnboardingProjectAdminProvider>
);

export default OnboardingProjectAdminRoute;
