/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { Button, Checkbox, Heading, Text } from "@breakingwave/react-ui-components";
import Column from "../../../components/grid/Column";
import Container from "../../../components/grid/Container";
import Row from "../../../components/grid/Row";
import Layout from "../../../components/layout";
import Spinner from "../../../components/spinner";
import { IManagedContext, ManagedProcessResourceTypeEnum, TaskGroupStatusesEnum } from "../../../types/shared";
import useNavigateError from "../../../hooks/useNavigateError";
import formatRateDisplay from "../../../utils/formatRateDisplay";
import useNotifyAutomationService from "../../../api/notifyAutomationService";
import { ProjectTeamAssignmentTaskAutomationKeyEnum } from "../../../types/onboarding";
import { ITeamAssignmentResponse } from "../../../types/teamAssignment";
import useGetManagedProcessByAccessToken from "../../../api/getManagedProcessByAccessToken";
import { footerProps, headerProps, htmlHeadProps } from "./OnboardingApproval.config";
import * as S from "./OnboardingApproval.styles";

const OnboardingApproval: React.FC = () => {
    const navigateError = useNavigateError();
    const [searchParams] = useSearchParams();

    const [managedContext, setManagedContext] = useState<IManagedContext>();    
    const [accessToken, setAccessToken] = useState<string>();
    const [termsCheckbox, setTermsCheckbox] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const {
        data: projectTeamAssignment,
        isLoading: isGetProjectTeamAssignmentApprovalLoading
    } = useGetManagedProcessByAccessToken<ITeamAssignmentResponse>(managedContext, ProjectTeamAssignmentTaskAutomationKeyEnum.SPONSOR_APPROVED_ONBOARDING_REQUEST, accessToken, {
        enabled: !!(managedContext && accessToken) && !errorMessage
    });

    const approvalDataResponseRef = React.useRef(projectTeamAssignment);
    approvalDataResponseRef.current = projectTeamAssignment;

    const projectSlug = useMemo(() => projectTeamAssignment?.ProjectSlug ?? "",[projectTeamAssignment]);
    const approvalConfirmationTask = useMemo(() => projectTeamAssignment?.TaskItems.find((task) => task.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.SPONSOR_APPROVED_ONBOARDING_REQUEST), [projectTeamAssignment]);

    const {
        isLoading: isNotifyAutomationServiceLoading,
        mutateAsync: notifyAutomationService,
        isSuccess: isNotifyAutomationServiceSuccess
    } = useNotifyAutomationService(managedContext!, projectSlug, accessToken, ProjectTeamAssignmentTaskAutomationKeyEnum.SPONSOR_APPROVED_ONBOARDING_REQUEST);

    useEffect(() => {
        const { id, p, t } = Object.fromEntries([...searchParams]); 

        if (id && p && t) {
            setManagedContext({
                Id: id,
                ProjectSlugOrProjectId: p,
                ResourceType: ManagedProcessResourceTypeEnum.ProjectTeamAssignment
            });

            setAccessToken(t);
        } else {
            navigateError(100);
        }
    }, []);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (!approvalDataResponseRef.current) {
                setErrorMessage("This link may have expired.");
            }
        }, 60000);

        return (() => {
            clearTimeout(timeOut);
        });
    }, []);    

    const confirmApproval = (): void => {
        if (managedContext && accessToken) {
            notifyAutomationService(null);
        }
    };

    return (
        <>
            {errorMessage &&
                <S.ErrorWrapper>
                    <h1>
                       An error has occurred whilst fetching the approval data
                    </h1>
                    <p>
                        {errorMessage}
                    </p>
                </S.ErrorWrapper>
            }

            {(!projectTeamAssignment && !errorMessage) && (
                <S.LoadingSection>
                    <Spinner size={3} padding={"0px"} />
                    <h1>Please wait...</h1>
                </S.LoadingSection>
            )}
            {!isGetProjectTeamAssignmentApprovalLoading && 
                projectTeamAssignment && (
                    
                <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
                    <S.PageTitleSection>
                        <Container isResponsive={true}>
                            <Row>
                                <Column>
                                    <Heading variant="h5" weight={300}>
                                        Request for additional team member
                                    </Heading>
                                    <S.Hr color="#595959" style={{ margin: "16px 0 24px" }} />
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <S.ProjectInfoGroup>
                                        <div>
                                            <Text size={14} weight={300} color="white.50">
                                                Project:
                                            </Text>
                                            <Text color="white.100">
                                                {projectTeamAssignment.ProjectName}
                                            </Text>
                                        </div>
                                        <div>
                                            <Text size={14} weight={300} color="white.50">
                                                Requested by:
                                            </Text>
                                            <Text color="white.100">{projectTeamAssignment.CreatedByName}</Text>
                                        </div>
                                        <div>
                                            <Text size={14} weight={300} color="white.50">
                                                Date of request:
                                            </Text>
                                            <Text color="white.100">
                                                {dayjs(projectTeamAssignment.DateCreatedUtc).format(
                                                    "DD MMM YYYY"
                                                )}
                                            </Text>
                                        </div>
                                    </S.ProjectInfoGroup>
                                </Column>
                            </Row>
                        </Container>
                    </S.PageTitleSection>
                    <S.BodySection>
                        <Container isResponsive={true}>
                            <Row>
                                <Column md={8}>
                                    <Heading variant="h6" weight={300} style={{ paddingLeft: 12, marginBottom: 16 }}>
                                        Approval Summary
                                    </Heading>
                                    <S.Hr color="#595959" />
                                    <S.RequestDetailsList>
                                        <S.RequestDetailsListItem>
                                            <Text color="white.75">Team Member</Text>
                                            <Text>
                                                {projectTeamAssignment.FirstName}{" "}
                                                {projectTeamAssignment.LastName}
                                            </Text>
                                        </S.RequestDetailsListItem>
                                        <S.RequestDetailsListItem>
                                            <Text color="white.75">Subscription type</Text>
                                            <Text>
                                                {`${projectTeamAssignment.SubscriptionType} subscription`}
                                            </Text>
                                        </S.RequestDetailsListItem>
                                        <S.RequestDetailsListItem>
                                            <Text color="white.75">Country</Text>
                                            <Text>{projectTeamAssignment.Country}</Text>
                                        </S.RequestDetailsListItem>
                                        <S.RequestDetailsListItem>
                                            <Text color="white.75">Target Start date</Text>
                                            <Text>
                                                {dayjs(projectTeamAssignment.StartDate).format(
                                                    "DD MMMM YYYY"
                                                )}
                                            </Text>
                                        </S.RequestDetailsListItem>
                                        <S.RequestDetailsListItem>
                                            <Text color="white.75">
                                                {projectTeamAssignment.RateType} cost
                                            </Text>
                                            <Text>
                                                {formatRateDisplay(
                                                    projectTeamAssignment.Rate,
                                                    projectTeamAssignment.RateCurrency
                                                )}
                                            </Text>
                                        </S.RequestDetailsListItem>
                                    </S.RequestDetailsList>
                                </Column>
                            </Row>
                            <Row>
                                <Column md={8}>
                                    <Heading variant="h6" weight={300} style={{ marginBottom: 4 }}>
                                        Terms
                                    </Heading>
                                    <Text color="white.50" style={{ marginBottom: 56 }}>
                                        Approval for this team member signals commitment to proceed in initiating the Breaking Wave process and may incur fees if cancelled.
                                        <br />
                                        <br />
                                        All subscriptions are rolling and charged monthly in arrears. They can be cancelled at any time, however you will be charged the fee for the full month in the month of cancellation.
                                    </Text>
                                </Column>
                                <Column md={8}>
                                    {isNotifyAutomationServiceSuccess ||
                                    approvalConfirmationTask?.Status === TaskGroupStatusesEnum.Complete ? (
                                            <>
                                                <Button disabled style={{ marginBottom: 24 }}>
                                                Approved
                                                </Button>
                                                <Text>This has been approved</Text>
                                            </>
                                        ) : (
                                            <>
                                                <Checkbox
                                                    name="onboardingApprovalCheck"
                                                    onClick={() => setTermsCheckbox(!termsCheckbox)}
                                                >
                                                Please check this box if you are happy to proceed
                                                </Checkbox>
                                                <Button
                                                    isLoading={isNotifyAutomationServiceLoading}
                                                    onClick={confirmApproval}
                                                    disabled={!termsCheckbox || isNotifyAutomationServiceLoading}
                                                    style={{ marginTop: 40 }}
                                                >
                                                Approve
                                                </Button>
                                            </>
                                        )}
                                </Column>
                            </Row>
                        </Container>
                    </S.BodySection>
                </Layout>
            )}
        </>
    );
};

export default OnboardingApproval;
