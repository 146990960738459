import axios from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { QueryKeysEnum } from "../types/queryKeys";
import { IProjectTeamMemberDisplayItemsResponse } from "../types/teamMemberDisplayItems";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { protectedResources } from "../auth/authConfig";

const getProjectTeamMemberDisplayItems = async (accessToken: string, projectSlug: string): Promise<IProjectTeamMemberDisplayItemsResponse[]> => {
    const response = await axios({
        method: "GET",
        url: `${protectedResources.wavecore.endpoint}/api/ProjectTeamMemberDisplayItems/${projectSlug}`,
        headers: {
            authorization: `Bearer ${accessToken}`
        }
    });

    return response.data;
};

const useGetProjectTeamMemberDisplayItems = <SelectData = IProjectTeamMemberDisplayItemsResponse[]>(
    projectSlug: string,
    options?: UseQueryOptions<IProjectTeamMemberDisplayItemsResponse[], unknown, SelectData, QueryKeysEnum[]>
): UseQueryResult<SelectData> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);

    return useQuery(
        [QueryKeysEnum.teamMemberDisplayItems],
        () =>
            getAccessToken().then((accessToken) => {
                if (accessToken && isAuthenticated) {
                    return getProjectTeamMemberDisplayItems(accessToken, projectSlug);
                }
                return Promise.reject();
            }),
        {
            ...options,
            enabled: isAuthenticated && options?.enabled !== false
        }
    );
};

export default useGetProjectTeamMemberDisplayItems;
