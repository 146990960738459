import { 
    RevokeProjectAdminRightsWizardStepIdentifierEnum, 
    IRevokeProjectAdminRightsWizardStepsState } from "./types";

const initialRevokeProjectAdminRightsWizardStepsState: IRevokeProjectAdminRightsWizardStepsState = {
    [RevokeProjectAdminRightsWizardStepIdentifierEnum.Confirmation]: {
        order: 1,
        isStepAnswered: false,
        isStepActive: true
    }
};

export default initialRevokeProjectAdminRightsWizardStepsState;
