import React from "react";
import { OffboardTeamMemberWizardStepsActionTypesEnum } from "./actions";
import { OffboardTeamMemberWizardStepIdentifierEnum, IOffboardTeamMemberWizardStepsState } from "./types";
import initialOffboardTeamMemberWizardStepsState from "./initialState";

export interface ILoadNextStepAction {
    type: OffboardTeamMemberWizardStepsActionTypesEnum.LOAD_NEXT_STEP;
}

export interface ILoadPrevStepAction {
    type: OffboardTeamMemberWizardStepsActionTypesEnum.LOAD_PREV_STEP;
}

export interface ISetConfirmationAction {
    type: OffboardTeamMemberWizardStepsActionTypesEnum.SET_CONFIRMATION;
    payload: IOffboardTeamMemberWizardStepsState[OffboardTeamMemberWizardStepIdentifierEnum.Confirmation];
}

export type OffboardTeamMemberWizardStepsActions =
    | ILoadNextStepAction
    | ILoadPrevStepAction
    | ISetConfirmationAction;

export const reducer: React.Reducer<IOffboardTeamMemberWizardStepsState, OffboardTeamMemberWizardStepsActions> = (state, action) => {
    const activeStep = Object.keys(state)
        .filter((f) => state[f as keyof IOffboardTeamMemberWizardStepsState].isStepActive)
        .reduce((r) => r.toString());
    const activeStepOrder = state[activeStep as keyof IOffboardTeamMemberWizardStepsState].order;

    switch (action.type) {
        case OffboardTeamMemberWizardStepsActionTypesEnum.LOAD_NEXT_STEP: {
            if (activeStepOrder >= 1 && activeStepOrder < Object.keys(state).length) {
                const nextStepFromActiveStepOrder = Object.keys(state).find(
                    (f) => state[f as keyof IOffboardTeamMemberWizardStepsState].order === activeStepOrder + 1
                );
                return {
                    ...state,
                    [activeStep as keyof IOffboardTeamMemberWizardStepsState]: {
                        ...state[activeStep as keyof IOffboardTeamMemberWizardStepsState],
                        isStepActive: false,
                        isStepAnswered: true
                    },
                    [nextStepFromActiveStepOrder as keyof IOffboardTeamMemberWizardStepsState]: {
                        ...state[nextStepFromActiveStepOrder as keyof IOffboardTeamMemberWizardStepsState],
                        isStepActive: true
                    }
                };
            } else {
                // Error: Next step not found.
                return state;
            }
        }
        case OffboardTeamMemberWizardStepsActionTypesEnum.LOAD_PREV_STEP: {
            const prevStepFromActiveStepOrder = Object.keys(state).find(
                (f) => state[f as keyof IOffboardTeamMemberWizardStepsState].order === activeStepOrder - 1
            );
            if (activeStepOrder > 1 && activeStepOrder <= Object.keys(state).length) {
                return {
                    ...state,
                    [activeStep as keyof IOffboardTeamMemberWizardStepsState]: {
                        ...state[activeStep as keyof IOffboardTeamMemberWizardStepsState],
                        isStepActive: false
                    },
                    [prevStepFromActiveStepOrder as keyof IOffboardTeamMemberWizardStepsState]: {
                        ...state[prevStepFromActiveStepOrder as keyof IOffboardTeamMemberWizardStepsState],
                        isStepActive: true,
                        isStepAnswered: false
                    }
                };
            } else {
                // Error: Previous step not found.
                return state;
            }
        }

        case OffboardTeamMemberWizardStepsActionTypesEnum.SET_CONFIRMATION: {
            return {
                ...state,
                [OffboardTeamMemberWizardStepIdentifierEnum.Confirmation]: action.payload
            };
        }
        default:
            return state;
    }
};

interface IOffboardTeamMemberWizardStepsContextProps {
    state: IOffboardTeamMemberWizardStepsState;
    dispatch: React.Dispatch<OffboardTeamMemberWizardStepsActions>;
}

export const OffboardTeamMemberWizardStepsContext = React.createContext<IOffboardTeamMemberWizardStepsContextProps>({
    state: initialOffboardTeamMemberWizardStepsState,
    dispatch: () => null
});

export const useOffboardTeamMemberWizardStepsContext = (): IOffboardTeamMemberWizardStepsContextProps =>
    React.useContext(OffboardTeamMemberWizardStepsContext);
