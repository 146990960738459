import React from "react";
import { Outlet } from "react-router-dom";
import { OnboardingProvider } from "../pages/public/onboarding/_state/context";

const OnboardingRoute: React.FC = () => (
    <OnboardingProvider>
        <Outlet />
    </OnboardingProvider>
);

export default OnboardingRoute;
