import * as yup from "yup";

type ConfirmationStepInputs = yup.InferType<typeof confirmationStepSchema>;

export const confirmationStepSchema = yup
    .object({
        confirmation: yup.bool().oneOf([true], "Please confirm that you are happy to proceed")
    })
    .required();

export type {
    ConfirmationStepInputs
};
