import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useGetOnboardingStatus from "../../../api/getOnboardingStatus";
import Layout from "../../../components/layout";
import Spinner from "../../../components/spinner";
import { OnboardingStatusEnum } from "../../../types/shared";
import Row from "../../../components/grid/Row";
import Column from "../../../components/grid/Column";
import dots from "../../../assets/images/mixed-space-dots-circular@3x.png";
import { footerProps, headerProps, htmlHeadProps } from "./LandingHome.config";
import * as S from "./LandingHome.styles";
import { Container } from "../../../components/grid/index.styles";

const LandingHomePage: React.FC = () => {

    const navigate = useNavigate();

    const {
        isLoading: isLoadingOnboardingStatus,
        isError: loadOnboardingStatusError,
        data: OnboardingStatusResponse
    } = useGetOnboardingStatus();

    useEffect(() => {
        if (OnboardingStatusResponse && OnboardingStatusResponse.OnboardingStatus === OnboardingStatusEnum.ActiveProjectTeamMember) {
            navigate("/project");
        }
    }, [OnboardingStatusResponse]);

    return (         
        <>        
            {isLoadingOnboardingStatus && (
                <S.LoadingSection>
                    <Spinner padding="20px" />
                    <h1>
                        Loading account
                    </h1>
                </S.LoadingSection>
            )}

            {loadOnboardingStatusError || 
             (OnboardingStatusResponse && OnboardingStatusResponse.OnboardingStatus === OnboardingStatusEnum.Onboarding) && 
                <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
                    <S.BodySection>
                        <Container isResponsive={true}>
                            <Row>
                                <Column md={{ size: 4, offset: 4 }}>
                                    <img
                                        src={dots}
                                        alt="thank you page mixed space dots circular"
                                        style={{ marginBottom: 16 }}
                                    />
                                </Column>
                            </Row>    

                            {loadOnboardingStatusError &&
                                <S.LoadingSection>
                                    <h1>
                                    An error occured whilst loading your account
                                    </h1>
                                    <Link to="/">&#8592; Go Home</Link>
                                </S.LoadingSection>
                            }

                            {OnboardingStatusResponse && OnboardingStatusResponse.OnboardingStatus === OnboardingStatusEnum.Onboarding && 
                                <S.LoadingSection>
                                    <h1>
                                        We&apos;re in the final stages of getting you onboarded. We will send you an email as soon as we&apos;re done. Thanks
                                    </h1>                
                                </S.LoadingSection>
                            }

                        </Container>
                    </S.BodySection>
                </Layout>
            }
        </>
    );
};

export default LandingHomePage;
