import { OffboardTeamMemberWizardStepIdentifierEnum, IOffboardTeamMemberWizardStepsState } from "./types";

const initialOffboardTeamMemberWizardStepsState: IOffboardTeamMemberWizardStepsState = {
    [OffboardTeamMemberWizardStepIdentifierEnum.Confirmation]: {
        order: 1,
        isStepAnswered: false,
        isStepActive: true
    }
};

export default initialOffboardTeamMemberWizardStepsState;
