import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export interface IHtmlHeadProps {
    pageTitle: string;
    description?: string | null;
    indexPage: boolean;
}

/**
 * This component enables us to make page level HTML metadata changes. It must be rendered as a child of <Layout />.
 * @param {string} pageTitle - A meta prop that should contain the page title.
 * @param {string | null} [description] - A meta prop that should contain the page description.
 * @param {boolean} indexPage - A boolean prop that indicates whether the page should be visible to crawlers or not.
 */
const HtmlHead: React.FC<IHtmlHeadProps> = ({ pageTitle, description, indexPage }) => {
    const location = useLocation();

    // TODO: Title doesn't update if we call react-router-dom's navigate function
    // inside a useEffect when component mounts. This is a workaround until we
    // fix the race condition with helmet lib.
    document.title = pageTitle;

    return (
        <Helmet>
            <html lang="en" />
            <title>{pageTitle}</title>
            <meta name="title" content={pageTitle} />
            {description && <meta name="description" content={description} />}
            {!indexPage && <meta name="robots" content="noindex nofollow" />}
            <meta property="og:title" content={pageTitle} />
            <meta property="og:site_name" content="Breaking Wave" />
            <meta property="og:type" content="website" />{" "}
            {description && <meta property="og:description" content={description} />}
            <meta property="og:url" content={location.pathname} />
            <meta property="og:locale" content="en_GB" />
            {/* TODO: <meta property="og:locale:alternate" content="de_DE" /> */}
            {/* TODO: Add meta data for images */}
        </Helmet>
    );
};

HtmlHead.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    description: PropTypes.string,
    indexPage: PropTypes.bool.isRequired
};

export default HtmlHead;
