import React from "react";
import { RevokeProjectAdminRightsWizardStepsActionTypesEnum } from "./actions";
import { RevokeProjectAdminRightsWizardStepIdentifierEnum, IRevokeProjectAdminRightsWizardStepsState } from "./types";
import initialRevokeProjectAdminRightsWizardStepsState from "./initialState";

export interface ILoadNextStepAction {
    type: RevokeProjectAdminRightsWizardStepsActionTypesEnum.LOAD_NEXT_STEP;
}

export interface ILoadPrevStepAction {
    type: RevokeProjectAdminRightsWizardStepsActionTypesEnum.LOAD_PREV_STEP;
}

export interface ISetConfirmationAction {
    type: RevokeProjectAdminRightsWizardStepsActionTypesEnum.SET_CONFIRMATION;
    payload: IRevokeProjectAdminRightsWizardStepsState[RevokeProjectAdminRightsWizardStepIdentifierEnum.Confirmation];
}

export type RevokeProjectAdminRightsWizardStepsActions =
    | ILoadNextStepAction
    | ILoadPrevStepAction
    | ISetConfirmationAction;

export const reducer: React.Reducer<IRevokeProjectAdminRightsWizardStepsState, RevokeProjectAdminRightsWizardStepsActions> = (state, action) => {
    const activeStep = Object.keys(state)
        .filter((f) => state[f as keyof IRevokeProjectAdminRightsWizardStepsState].isStepActive)
        .reduce((r) => r.toString());
    const activeStepOrder = state[activeStep as keyof IRevokeProjectAdminRightsWizardStepsState].order;

    switch (action.type) {
        case RevokeProjectAdminRightsWizardStepsActionTypesEnum.LOAD_NEXT_STEP: {
            if (activeStepOrder >= 1 && activeStepOrder < Object.keys(state).length) {
                const nextStepFromActiveStepOrder = Object.keys(state).find(
                    (f) => state[f as keyof IRevokeProjectAdminRightsWizardStepsState].order === activeStepOrder + 1
                );
                return {
                    ...state,
                    [activeStep as keyof IRevokeProjectAdminRightsWizardStepsState]: {
                        ...state[activeStep as keyof IRevokeProjectAdminRightsWizardStepsState],
                        isStepActive: false,
                        isStepAnswered: true
                    },
                    [nextStepFromActiveStepOrder as keyof IRevokeProjectAdminRightsWizardStepsState]: {
                        ...state[nextStepFromActiveStepOrder as keyof IRevokeProjectAdminRightsWizardStepsState],
                        isStepActive: true
                    }
                };
            } else {
                // Error: Next step not found.
                return state;
            }
        }
        case RevokeProjectAdminRightsWizardStepsActionTypesEnum.LOAD_PREV_STEP: {
            const prevStepFromActiveStepOrder = Object.keys(state).find(
                (f) => state[f as keyof IRevokeProjectAdminRightsWizardStepsState].order === activeStepOrder - 1
            );
            if (activeStepOrder > 1 && activeStepOrder <= Object.keys(state).length) {
                return {
                    ...state,
                    [activeStep as keyof IRevokeProjectAdminRightsWizardStepsState]: {
                        ...state[activeStep as keyof IRevokeProjectAdminRightsWizardStepsState],
                        isStepActive: false
                    },
                    [prevStepFromActiveStepOrder as keyof IRevokeProjectAdminRightsWizardStepsState]: {
                        ...state[prevStepFromActiveStepOrder as keyof IRevokeProjectAdminRightsWizardStepsState],
                        isStepActive: true,
                        isStepAnswered: false
                    }
                };
            } else {
                // Error: Previous step not found.
                return state;
            }
        }

        case RevokeProjectAdminRightsWizardStepsActionTypesEnum.SET_CONFIRMATION: {
            return {
                ...state,
                [RevokeProjectAdminRightsWizardStepIdentifierEnum.Confirmation]: action.payload
            };
        }
        default:
            return state;
    }
};

interface IRevokeProjectAdminRightsWizardStepsContextProps {
    state: IRevokeProjectAdminRightsWizardStepsState;
    dispatch: React.Dispatch<RevokeProjectAdminRightsWizardStepsActions>;
}

export const RevokeProjectAdminRightsWizardStepsContext = React.createContext<IRevokeProjectAdminRightsWizardStepsContextProps>({
    state: initialRevokeProjectAdminRightsWizardStepsState,
    dispatch: () => null
});

export const useRevokeProjectAdminRightsWizardStepsContext = (): IRevokeProjectAdminRightsWizardStepsContextProps =>
    React.useContext(RevokeProjectAdminRightsWizardStepsContext);
