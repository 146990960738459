import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ProjectPageProvider } from "../pages/private/project/_state/context";
import { protectedResources } from "../auth/authConfig";

const PrivateRoute: React.FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    useEffect(() => {
        if (!isAuthenticated) {
            instance.loginRedirect(protectedResources.graphMe).catch((e) => {
                console.log(e);
            });
        }
    }, []);

    return (
        <>
            {isAuthenticated && (
                <ProjectPageProvider>
                    <Outlet />
                </ProjectPageProvider>
            )}
        </>
    );
};

export default PrivateRoute;
