import React from "react";
import PropTypes from "prop-types";
import { Text } from "@breakingwave/react-ui-components";
import Container from "../grid/Container";
import Row from "../grid/Row";
import Column from "../grid/Column";
import * as S from "./Footer.styles";

export interface IFooterProps {
    hasFooterLinks: boolean;
    isFooterResponsive: boolean;
}

/**
 * A simple Footer component.
 * @param {boolean} hasFooterLinks - A boolean prop which is passed down via <Layout /> to render footer with or without links. It disregards public links in the footer when set to false.
 * @param {boolean} isFooterResponsive - A boolean prop which is passed down via <Layout /> that makes <Footer /> container responsive.
 */
const Footer: React.FC<IFooterProps> = ({ hasFooterLinks, isFooterResponsive }) => (
    <S.Footer>
        <Container isResponsive={isFooterResponsive}>
            {hasFooterLinks ? (
                <>
                    <div className="mb-custom-9">
                        <Row>
                            <Column md={9}>
                                <S.FooterLinksList className="mb-custom-10-mobile">
                                    <li>
                                        <a href={"mailto:community@breakingwave.com"} target="_blank" rel="noreferrer">
                                            Getting in touch
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={"https://breakingwave.bamboohr.com/jobs/"}
                                            target="_blank"
                                            rel="noopener noreferrer external"
                                        >
                                            Job Enquiries
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={
                                                "https://www.db.com/legal-resources/privacy-notice?language_id=1&kid=company-privacy-notice.redirect-en.shortcut#show-content-of-cookies"
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer external"
                                        >
                                            Cookie Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={
                                                "https://www.db.com/what-we-do/responsibility/sustainability/responsible-banking/human-rights?language_id=1"
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer external"
                                        >
                                            Human Rights
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={
                                                "https://www.db.com/legal-resources/report-misconduct"
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer external"
                                        >
                                            Report Misconduct
                                        </a>
                                    </li>
                                </S.FooterLinksList>
                            </Column>
                            <S.DividerAboveTablet>
                                <hr className="mb-custom-4-mobile" />
                            </S.DividerAboveTablet>
                            <Column md={3}>
                                <S.PushRightBelowTablet>
                                    <Text size={14} color="white.75" className="mb-custom-20-mobile">
                                        &copy; Breaking Wave
                                    </Text>
                                </S.PushRightBelowTablet>
                            </Column>
                        </Row>
                    </div>
                    <Row>
                        <S.DividerBelowTablet>
                            <hr className="mb-custom-12" />
                        </S.DividerBelowTablet>
                    </Row>
                    <Row>
                        <Column>
                            <S.FooterDBInitiativeWrapper>
                                <S.FooterDBInitiativeSvg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="footer-content__dbinitiative--logo"
                                    width="130"
                                    height="43"
                                >
                                    <title>DB Initiative logo</title>
                                    <image
                                        width="130"
                                        height="43"
                                        href={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/logo/db_initiative/anin_small_application_black_lefthanded_whitespace_cropped.svg`}
                                    />
                                </S.FooterDBInitiativeSvg>
                            </S.FooterDBInitiativeWrapper>
                        </Column>
                    </Row>
                </>
            ) : (
                <Row>
                    <Column md={6}>
                        <p className="mb-custom-12-mobile">&copy; Breaking Wave</p>
                    </Column>
                    <Column md={6}>
                        <S.FooterDBInitiativeWrapper>
                            <S.FooterDBInitiativeSvg xmlns="http://www.w3.org/2000/svg" width="130" height="43">
                                <title>DB Initiative logo</title>
                                <image
                                    width="130"
                                    height="43"
                                    href={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/logo/db_initiative/anin_small_application_black_lefthanded_whitespace_cropped.svg`}
                                />
                            </S.FooterDBInitiativeSvg>
                        </S.FooterDBInitiativeWrapper>
                    </Column>
                </Row>
            )}
        </Container>
    </S.Footer>
);

Footer.propTypes = {
    hasFooterLinks: PropTypes.bool.isRequired,
    isFooterResponsive: PropTypes.bool.isRequired
};

export default Footer;
