import * as yup from "yup";
import { UserRolesEnum } from "../../../../../../types/userRoles";

type ProjectAdminNameStepInputs = yup.InferType<typeof projectAdminNameStepSchema>;
type ConfirmationStepInputs = yup.InferType<typeof confirmationStepSchema>;

export interface IProjectAdminNameStepContext {
    userRole: UserRolesEnum | undefined;
}

const projectAdminNameStepSchema = yup
    .object({
        firstName: yup.string().trim().required("Please provide the project admin's first name"),
        lastName: yup.string().trim().required("Please provide the project admin's last name"),
        contactEmailAddress: yup
            .string()
            .email("Please provide a valid project admin's email address")
            .trim()
            .test({
                test: (contactEmailAddress: string | undefined, ctx) => {
                    const context = ctx.options.context as IProjectAdminNameStepContext;                    

                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const isAdmin = context.userRole === UserRolesEnum.GlobalSuperAdmin || context.userRole === UserRolesEnum.ProjectSuperAdmin;

                    // // If the projectDomain is not configured in the project catalogue then block unless
                    // // they're a GlobalSuperAdmin or ProjectSuperAdmin
                    // if (!context.projectDomain && !isAdmin) {
                    //     return ctx.createError({ 
                    //         message: "Project domain is not configured.  Only the super admins may add a user to this project." 
                    //     });
                    // }

                    // // If we're not an admin then we may only onboard people with the configured 'projectDomain' email
                    // if (!isAdmin) {
                    //     const regex = new RegExp(`${context.projectDomain}$`);
                    //     if (!regex.test(contactEmailAddress || "")) {
                    //         return ctx.createError({ 
                    //             message: `Please provide a '@${context.projectDomain}' email address` 
                    //         });
                    //     }
                    // }

                    return true;
                }
            })
            .required("Please provide the project admin's email address")        
    })
    .required();

const confirmationStepSchema = yup
    .object({
        confirmation: yup.bool().oneOf([true], "Please confirm that you are happy to proceed")
    })
    .required();

export {
    projectAdminNameStepSchema,
    confirmationStepSchema
};

export type {
    ProjectAdminNameStepInputs,
    ConfirmationStepInputs
};
