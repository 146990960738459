import React from "react";
import PropTypes from "prop-types";
import * as S from "./Spinner.styles";

interface ISpinnerProps {
    size?: number;
    padding?: string;
}

const Spinner: React.FC<ISpinnerProps> = ({ size, padding }) => {
    const dimension = `${size ?? 4}rem`;
    return (
        <S.Spinner padding={padding}>
            <div style={{ width: dimension, height: dimension }} className="spinner"></div>
        </S.Spinner>
    );
};

export default Spinner;

Spinner.propTypes = {
    size: PropTypes.number,
    padding: PropTypes.string
};
