import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { IProjectCatalogue } from "../types/projectCatalogue";
import { QueryKeysEnum } from "../types/queryKeys";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { protectedResources } from "../auth/authConfig";

/* eslint-disable @typescript-eslint/no-non-null-assertion */

const getProjectCatalogue = async (
    accessToken: string
): Promise<IProjectCatalogue> => {
    const apiResponse = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_WAVE_CORE_SERVICE_URL}/api/projectcatalogue`,
        headers: {
            authorization: `Bearer ${accessToken}`
        }
    });
    return apiResponse.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useGetProjectCatalogue = (
    options?: UseQueryOptions<IProjectCatalogue, unknown, IProjectCatalogue, QueryKeysEnum[]>
) => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(
        protectedResources.wavecore.scopes
    );
    return useQuery(
        [QueryKeysEnum.projectCatalogue], 
        () => getAccessToken().then((accessToken) =>
            accessToken && isAuthenticated ? 
                getProjectCatalogue(accessToken).then((projectCatalgoue) => projectCatalgoue) : Promise.reject()
        ), {
            ...options
        }
    );
};

export default useGetProjectCatalogue;
