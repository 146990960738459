import React from "react";
import PropTypes from "prop-types";
import { useGridContext } from "./context";
import * as S from "./index.styles";

export interface IRowProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

/**
 * Row component provides a wrapper around columns. Only column components should be rendered within.
 * @param {React.ReactNode} children - A React node that the <Row /> renders. Only column component should be passed.
 */
const Row: React.FC<IRowProps> = ({ children, ...rest }) => {
    const { isResposive } = useGridContext();
    return (
        <S.Row isResponsive={isResposive} {...rest}>
            {children}
        </S.Row>
    );
};

Row.propTypes = {
    children: PropTypes.node.isRequired
};

export default Row;
