import React from "react";
import { useProjectAdminAssignmentStepsContext } from "../_state/context";
import { 
    ProjectAdminAssignmentWizardStepIdentifierEnum, 
    IProjectAdminAssignmentWizardStepsState } from "../_state/types";
import Confirmation from "./Confirmation";
import * as S from "../../wizard.styles";

const ProjectAdminAssignmentWizardSteps: React.FC = () => {

    const { 
        state
    } = useProjectAdminAssignmentStepsContext();


    const [activeStep, setActiveStep] = React.useState(Object.values(state).find((f) => f.order === 1).identifier);

    React.useEffect(() => {
        const activeStepFromTeamAssignmentWizard = Object.keys(state).find(
            (f) => state[f as keyof IProjectAdminAssignmentWizardStepsState].isStepActive
        );
        setActiveStep(activeStepFromTeamAssignmentWizard);
    }, [state]);

    return (
        <S.Form autoComplete="off">
            {activeStep === ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation && <Confirmation />}
        </S.Form>
    );
};

export default ProjectAdminAssignmentWizardSteps;
