/**
 * <PAGE>.config.ts is a page-level unique configuration file.
 *
 * Each page is wrapped within <Layout />, which constructs the HTML of the page. To make the page customizable, we need to set some objects which are passed down even further to components that <Layout /> uses.
 * It might be a good idea to utilize this file if we ever need to make more page-level customization.
 * Another benefit is that this separation helps us to keep the page file small.
 */

import { IHtmlHeadProps } from "../../../components/html-head";
import { IHeaderProps } from "../../../components/header";
import { IFooterProps } from "../../../components/footer";

const htmlHeadProps: IHtmlHeadProps = {
    pageTitle: "Welcome | Breaking Wave",
    description:
        "We are a newly created Deutsche Bank company building a digital future for the bank and its customers",
    indexPage: false
};

const headerProps: IHeaderProps = {
    showHideOnScroll: false,
    hasLoginButtonAndAvatar: false,
    isHeaderResponsive: true
};

const footerProps: IFooterProps = {
    hasFooterLinks: false,
    isFooterResponsive: true
};

export { htmlHeadProps, headerProps, footerProps };
