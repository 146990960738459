export enum ProjectAdminAssignmentWizardStepIdentifierEnum {
    Confirmation = "Confirmation",
}

export interface IProjectAdminAssignmentWizardStepsState {
    [ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            confirmation: boolean;
        };
    };    
}
