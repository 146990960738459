
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from "react";
import { toast } from "react-toastify";
import { protectedResources } from "../auth/authConfig";
import useAadAccessToken from "../hooks/useAadAccessToken";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const projectTeamMemberCSVExport = () => {

    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);

    const [isLoading, setIsLoading] = useState(false);

    const downloadCsv = (projectSlug: string): Promise<void> => getAccessToken().then((accessToken) => {

        setIsLoading(true);

        if (accessToken && isAuthenticated) {
            const anchor = document.createElement("a");
            const headers = new Headers();
            headers.set("authorization", `Bearer ${accessToken}`);
            const url = `${process.env.REACT_APP_WAVE_CORE_SERVICE_URL}/api/ExportProjectTeamMembersToCsv/${projectSlug}`;
            let fileName = "";

            return fetch(url, { headers })
                .then((response) => {
                    const header = response.headers.get("content-disposition");
                    const parts = header!.split(";");
                    fileName = parts[1].split("=")[1];
                    return response.blob();
                })
                .then((blob) => {
                    const objectUrl = window.URL.createObjectURL(blob);
                  
                    anchor.href = objectUrl;
                    anchor.download = fileName;
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);

                    setIsLoading(false);
                }).catch(() => {
                    setIsLoading(false);
                    toast.error("An error occurred whilst processing your download");
                });
        } else {
            setIsLoading(false);
            return Promise.reject();
        }        
    });

    return {
        downloadCsv,
        isLoading
    };
};

export default projectTeamMemberCSVExport;
