import { ProjectTeamAssignmentTaskAutomationKeyEnum } from "../types/onboarding";

const getOnboardingTaskStatusLoadingDescription = (automationKey: ProjectTeamAssignmentTaskAutomationKeyEnum | undefined): string => {
    switch (automationKey) {
        case ProjectTeamAssignmentTaskAutomationKeyEnum.CREATE_USER:
            return "Creating your account";

        case ProjectTeamAssignmentTaskAutomationKeyEnum.ADD_USER_TO_MFA_SETUP_AAD_GROUP:
            return "Setting up your account";

        case ProjectTeamAssignmentTaskAutomationKeyEnum.SEND_MFA_SETUP_REQUEST_EMAIL:
            return "We're just finishing up";
            
        default:
            return "Please wait..";
    }
};

export default getOnboardingTaskStatusLoadingDescription;
