import styled from "styled-components";

const ContextMenu = styled.div<{ isOpen: boolean }>`
    background-color: #191718;
    border: solid 1px #939393;
    border-radius: 5px;
    position: absolute;
    display: ${(p) => (p.isOpen ? "block" : "none")};
`;

const IContextMenuItem = styled.div<{ isLink: boolean }>`
    padding: 9px 12px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.75);
    border-top: solid 1px #3c3c3c;
    cursor: pointer;

    &:hover {
        color: #46e1ff;
        text-decoration: ${(p) => (p.isLink ? "underline" : "none")};
    }
`;

export { ContextMenu, IContextMenuItem };
