import { QueryClient } from "react-query";

const refreshInterval: number = parseInt(process.env.REACT_APP_REFRESH_QUERIES_MS ?? "120000");

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60000, // Regard state as fresh for 60s
            refetchInterval: refreshInterval, // Refetch stale queries every X ms specified in the env setting
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            cacheTime: Infinity, // Disable cache garbage-collection
            retryDelay: (n): number => n ** 2 + 1500
        }
    }
});

export { queryClient };
