import { IProject } from "../../../../types/projectCatalogue";
import { IProjectPageState } from "./types";

const initialProjectPageState: IProjectPageState = {
    selectedProject: {} as IProject,
    teamAssignmentWizard: {
        isActive: false,
        isCompleted: false,
        tempTeamAssignmentStepsData: undefined
    },
    joinProjectWizard: {
        isActive: false,
        isCompleted: false,
        tempJoinProjectStepsData: undefined,
        lastSavedJoinUPN: undefined
    },
    leaveProjectWizard: {
        isActive: false,
        tempLeavingMemberData: undefined,
        lastSavedLeaveUPN: undefined
    },
    offboardTeamMemberWizard: {
        isActive: false,
        tempOffboardingMemberData: undefined,
        lastSavedOffboardUPN: undefined
    },
    projectAdminAssignmentWizard: {
        isActive: false,
        isCompleted: false,
        tempProjectAdminAssignmentStepsData: undefined,
        lastSavedAdminAssignmentUPN: undefined
    },
    revokeProjectAdminRightsWizard: {
        isActive: false,
        isCompleted: false,
        tempRevokeProjectAdminRightsStepsData: undefined,
        lastSavedRevokeUPN: undefined
    }
};

export default initialProjectPageState;
