import { MemberTypeEnum } from "../../../../../../../types/shared";
import { JoinProjectWizardStepIdentifierEnum, IJoinProjectWizardStepsState } from "./types";

const initialJoinProjectWizardStepsState: IJoinProjectWizardStepsState = {
    [JoinProjectWizardStepIdentifierEnum.MemberType]: {
        order: undefined,
        isStepAnswered: undefined,
        isStepActive: undefined,
        answer: MemberTypeEnum.DB
    },
    [JoinProjectWizardStepIdentifierEnum.SearchedMemberData]: {
        order: 1,
        isStepAnswered: false,
        isStepActive: true,
        answer: {
            upn: "",
            searchMemberLabel: "",
            searchMemberValue: "",
            team: "",
            personalDetails: {
                firstName: "",
                lastName: "",
                email: "",
                displayValue: "-"
            },
            country: {
                country: "",
                isoString: "",
                displayValue: "-"
            },
            deviceType: {
                deviceType: undefined
            },
            startDate: {
                isoString: "",
                displayValue: "-"
            },
            subscriptionType: {
                subscriptionType: null,
                displayValue: "-"
            },
            rate: {
                rate: NaN,
                rateType: undefined,
                rateCurrency: undefined,
                displayValue: "-"
            },
            endDate: {
                endDateMode: undefined,
                isoString: undefined,
                displayValue: undefined
            },
            homeProject: {
                displayValue: "-"
            }
        }
    },
    [JoinProjectWizardStepIdentifierEnum.Confirmation]: {
        order: 2,
        isStepAnswered: false,
        isStepActive: false,
        answer: {
            confirmation: false
        }
    }
};

export default initialJoinProjectWizardStepsState;
