import styled, { css } from "styled-components";
import { IStepIndicatorCircleProps } from ".";

const Circle = styled.span<IStepIndicatorCircleProps>`
    display: flex;
    width: 18px;
    height: 18px;
    border: 1px solid;
    border-radius: 50%;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 9px;
    user-select: none;

    ${({ active, completed, success, disabled }) => {
        if (active) {
            return css`
                color: #000;
                background-color: #b8b8b8;
                border-color: #b8b8b8;
            `;
        } else if (completed) {
            return css`
                color: #000;
                background-color: #bfccff;
                border-color: #bfccff;
            `;
        } else if (success) {
            return css`
                color: #000;
                background-color: #2afe9d;
                border-color: #2afe9d;
            `;
        } else if (disabled) {
            return css`
                color: #b8b8b8;
                background-color: transparent;
                border-color: #b8b8b8;
            `;
        } else {
            return null;
        }
    }}
`;

export { Circle };
