import React from "react";
import { LeaveProjectWizardStepsActionTypesEnum } from "./actions";
import { LeaveProjectWizardStepIdentifierEnum, ILeaveProjectWizardStepsState } from "./types";
import initialLeaveProjectWizardStepsState from "./initialState";

export interface ILoadNextStepAction {
    type: LeaveProjectWizardStepsActionTypesEnum.LOAD_NEXT_STEP;
}

export interface ILoadPrevStepAction {
    type: LeaveProjectWizardStepsActionTypesEnum.LOAD_PREV_STEP;
}

export interface ISetConfirmationAction {
    type: LeaveProjectWizardStepsActionTypesEnum.SET_CONFIRMATION;
    payload: ILeaveProjectWizardStepsState[LeaveProjectWizardStepIdentifierEnum.Confirmation];
}

export type LeaveProjectWizardStepsActions =
    | ILoadNextStepAction
    | ILoadPrevStepAction
    | ISetConfirmationAction;

export const reducer: React.Reducer<ILeaveProjectWizardStepsState, LeaveProjectWizardStepsActions> = (state, action) => {
    const activeStep = Object.keys(state)
        .filter((f) => state[f as keyof ILeaveProjectWizardStepsState].isStepActive)
        .reduce((r) => r.toString());
    const activeStepOrder = state[activeStep as keyof ILeaveProjectWizardStepsState].order;

    switch (action.type) {
        case LeaveProjectWizardStepsActionTypesEnum.LOAD_NEXT_STEP: {
            if (activeStepOrder >= 1 && activeStepOrder < Object.keys(state).length) {
                const nextStepFromActiveStepOrder = Object.keys(state).find(
                    (f) => state[f as keyof ILeaveProjectWizardStepsState].order === activeStepOrder + 1
                );
                return {
                    ...state,
                    [activeStep as keyof ILeaveProjectWizardStepsState]: {
                        ...state[activeStep as keyof ILeaveProjectWizardStepsState],
                        isStepActive: false,
                        isStepAnswered: true
                    },
                    [nextStepFromActiveStepOrder as keyof ILeaveProjectWizardStepsState]: {
                        ...state[nextStepFromActiveStepOrder as keyof ILeaveProjectWizardStepsState],
                        isStepActive: true
                    }
                };
            } else {
                // Error: Next step not found.
                return state;
            }
        }
        case LeaveProjectWizardStepsActionTypesEnum.LOAD_PREV_STEP: {
            const prevStepFromActiveStepOrder = Object.keys(state).find(
                (f) => state[f as keyof ILeaveProjectWizardStepsState].order === activeStepOrder - 1
            );
            if (activeStepOrder > 1 && activeStepOrder <= Object.keys(state).length) {
                return {
                    ...state,
                    [activeStep as keyof ILeaveProjectWizardStepsState]: {
                        ...state[activeStep as keyof ILeaveProjectWizardStepsState],
                        isStepActive: false
                    },
                    [prevStepFromActiveStepOrder as keyof ILeaveProjectWizardStepsState]: {
                        ...state[prevStepFromActiveStepOrder as keyof ILeaveProjectWizardStepsState],
                        isStepActive: true,
                        isStepAnswered: false
                    }
                };
            } else {
                // Error: Previous step not found.
                return state;
            }
        }

        case LeaveProjectWizardStepsActionTypesEnum.SET_CONFIRMATION: {
            return {
                ...state,
                [LeaveProjectWizardStepIdentifierEnum.Confirmation]: action.payload
            };
        }
        default:
            return state;
    }
};

interface ILeaveProjectWizardStepsContextProps {
    state: ILeaveProjectWizardStepsState;
    dispatch: React.Dispatch<LeaveProjectWizardStepsActions>;
}

export const LeaveProjectWizardStepsContext = React.createContext<ILeaveProjectWizardStepsContextProps>({
    state: initialLeaveProjectWizardStepsState,
    dispatch: () => null
});

export const useLeaveProjectWizardStepsContext = (): ILeaveProjectWizardStepsContextProps =>
    React.useContext(LeaveProjectWizardStepsContext);
