import React from "react";
import PropTypes from "prop-types";
import { GridContext } from "./context";
import * as S from "./index.styles";

export interface IContainerProps {
    children: React.ReactNode;
    isResponsive?: boolean;
    fixedWidth?: number;
}

/**
 * Containers provide a wrapper and horizontally center the site's contents. This component uses Context API and, currently, it only has 'isResponsive' state. This boolean state is applied to container's Row and Column children. see one of the pages in /pages for an example.
 * @param {React.ReactNode} children - A React node that the <Container /> renders. It should be preferably a <Row /> component.
 * @param {boolean} isResponsive - A boolean prop that sets the responsiveness of the <Container />, as well as container's <Row /> and <Column />. Defaults to true.
 * @param {string} fixedWidth - A numeric prop that makes the container width fixed. Defaults to 1320px.
 */
const Container: React.FC<IContainerProps> = ({ children, ...rest }) => (
    <GridContext.Provider value={{ isResposive: rest.isResponsive ?? true }}>
        <S.Container {...rest}>{children}</S.Container>
    </GridContext.Provider>
);

Container.defaultProps = {
    fixedWidth: 1320,
    isResponsive: true
};

Container.propTypes = {
    children: PropTypes.node.isRequired,
    isResponsive: PropTypes.bool,
    fixedWidth: PropTypes.number
};

export default Container;
