import { Dayjs } from "dayjs";
import { EndDateModeEnum, RateCurrencyEnum, RateTypeEnum, MemberTypeEnum, DeviceTypeEnum } from "../../../../../../../types/shared";

export enum TeamAssignmentWizardStepIdentifierEnum {
    MemberType = "memberType",
    TeamMemberName = "teamMemberName",
    SubscriptionType = "subscriptionType",
    Rate = "rate",
    Country = "country",    
    StartDate = "startDate",
    EndDate = "endDate",
    DeviceSelection = "deviceSelection",
    TeamSelection = "teamSelection",
    Confirmation = "confirmation"
}

export interface ITeamAssignmentWizardStepsState {
    [TeamAssignmentWizardStepIdentifierEnum.MemberType]: {
        order: undefined;
        isStepAnswered: undefined;
        isStepActive: undefined;
        answer: MemberTypeEnum;
    };
    [TeamAssignmentWizardStepIdentifierEnum.TeamMemberName]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            firstName: string;
            lastName: string;
            contactEmailAddress: string;
            displayValue: string;
        };
    };
    [TeamAssignmentWizardStepIdentifierEnum.Country]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            country: string; // Country name
            isoString: string; // alpha-2 country code
            displayValue: string;
        };
    };
    [TeamAssignmentWizardStepIdentifierEnum.StartDate]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            /**
             * The default format that the DatePicker component returns is a Dayjs type.
             */
            startDate: Dayjs | null;
            /**
             * UTC formatted iso date string.
             * Format: YYYY-MM-DDTHH:mm:ssZ
             * Example: 2023-07-23T00:00:00Z
             */
            isoString: string;
            /**
             * Format: DD MMM YYYY
             * Example: 23 Jul 2022
             */
            displayValue: string;
        };
    };
    [TeamAssignmentWizardStepIdentifierEnum.SubscriptionType]: {
        order: undefined;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            subscriptionType: string | null;
            displayValue: string;
        };
    };
    [TeamAssignmentWizardStepIdentifierEnum.Rate]: {
        order: undefined;
        isStepAnswered: undefined;
        isStepActive: undefined;
        answer: {
            rate: number;
            rateType: RateTypeEnum | undefined;
            rateCurrency: RateCurrencyEnum | undefined;
            displayValue: string;
        };
    };
    [TeamAssignmentWizardStepIdentifierEnum.EndDate]: {
        order: undefined;
        isStepAnswered: undefined;
        isStepActive: undefined;
        answer: {
            endDateMode: EndDateModeEnum | undefined;
            /**
             * UTC formatted iso date string.
             * Format: YYYY-MM-DDTHH:mm:ssZ
             * Example: 2023-07-23T00:00:00Z
             * Note that, if the EndDateModeEnum is "Specified", we will post EndDate: undefined. In the same manner, the EndDate key is only returned from the WaveCore if the EndDateModeEnum is "Specified".
             */
            isoString: string | undefined;
            /**
             * Format: DD MMM YYYY
             * Example: 23 Jul 2022
             */
            displayValue: string | undefined;
        };
    };
    [TeamAssignmentWizardStepIdentifierEnum.DeviceSelection]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            deviceType: DeviceTypeEnum | undefined;
        };        
    };
    [TeamAssignmentWizardStepIdentifierEnum.TeamSelection]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            team: string | undefined;
            displayValue: string;
        };        
    };    
    [TeamAssignmentWizardStepIdentifierEnum.Confirmation]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            confirmation: boolean;
        };
    };
}

/**
 * This special type is constructed out of ITeamAssignmentWizardStepsState interface.
 * For the Add Team Member mode of the wizard reducer function work, each state defined in the context provider must have an "order" key. Since MemberType and Rate state entries aren't part of wizard steps but still exist in the wizard flow, we needed to omit them to prevent TypeScript errors.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type ITeamAssignmentWizardStepsState__UndefinedFiltered = Omit<
ITeamAssignmentWizardStepsState,
TeamAssignmentWizardStepIdentifierEnum.MemberType | 
TeamAssignmentWizardStepIdentifierEnum.Rate | 
TeamAssignmentWizardStepIdentifierEnum.EndDate |
TeamAssignmentWizardStepIdentifierEnum.SubscriptionType
>;
