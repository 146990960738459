import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, Tick, Checkbox, FormControl, FormErrorText } from "@breakingwave/react-ui-components";
import { ProjectAdminAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import { useProjectPageContext } from "../../../../_state/context";
import { ProjectPageActionTypesEnum } from "../../../../_state/actions";
import { ProjectAdminAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { confirmationStepSchema, ConfirmationStepInputs } from "../index.schema";
import { useProjectAdminAssignmentStepsContext } from "../_state/context";
import useCreateProjectAdminAssignment from "../../../../../../../api/createProjectAdminAssignment";
import { MemberTypeEnum } from "../../../../../../../types/shared";
import { IProjectAdminAssignmentErrorResponse } from "../../../../../../../types/projectAdminAssignment";
import { projectAdminBlockerReason } from "../../../../../../../utils/projectAdminAssignmentBlockerReason";
import * as S from "../../wizard.styles";

const Confirmation: React.FC = () => {
    const { 
        state: {
            selectedProject: {
                ProjectSlug,
                DisplayName
            },
            projectAdminAssignmentWizard: {
                tempProjectAdminAssignmentStepsData
            }
        }, 
        dispatch: projectPageDispatch
    } = useProjectPageContext();

    const { state: projectAdminAssignmentWizardState, dispatch } = useProjectAdminAssignmentStepsContext();
    
    const confirmationStep = projectAdminAssignmentWizardState[ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation];

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError        
    } = useForm<ConfirmationStepInputs>({
        resolver: yupResolver(confirmationStepSchema),
        defaultValues: {
            confirmation: confirmationStep.answer.confirmation
        }
    });

    const {
        isLoading,
        isSuccess: isSaveProjectAssignmentSuccess,
        data: fetchedDataAfterCreate,
        mutateAsync: addProjectAdminAssignment
    } = useCreateProjectAdminAssignment(ProjectSlug);

    const cancelWizard = (): void => {
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.CANCEL_PAGE_LEVEL_WIZARDS
        });
    };

    const onConfirm = async (data: ConfirmationStepInputs): Promise<void> => {
        const { confirmation } = data;

        dispatch({
            type: ProjectAdminAssignmentWizardStepsActionTypesEnum.SET_CONFIRMATION,
            payload: {
                ...confirmationStep,
                answer: {
                    confirmation: confirmation as boolean
                }
            }
        });

        if (tempProjectAdminAssignmentStepsData) {
            await addProjectAdminAssignment({
                FirstName: tempProjectAdminAssignmentStepsData.FirstName,
                LastName: tempProjectAdminAssignmentStepsData.LastName,
                ContactEmailAddress: tempProjectAdminAssignmentStepsData.ContactEmailAddress,
                MemberType: MemberTypeEnum.DB,
                UserPrincipalName: tempProjectAdminAssignmentStepsData.UserPrincipalName
            }).catch((error) => {

                const result = error.response.data as IProjectAdminAssignmentErrorResponse;
                const bitwise: number = result.ValidationResult;

                setError("confirmation", { 
                    message: projectAdminBlockerReason(bitwise)
                });
            });
        }
    };

    const name = useMemo(() => `${tempProjectAdminAssignmentStepsData?.FirstName} ${tempProjectAdminAssignmentStepsData?.LastName}`, []);

    React.useEffect(() => {
        if (isSaveProjectAssignmentSuccess) {
            // Save the newly fetched single team assignment data to the page-level state.
            projectPageDispatch({
                type: ProjectPageActionTypesEnum.SET_PROJECT_ADMIN_ASSIGNMENT_WIZARD,
                payload: {
                    isActive: false,
                    isCompleted: true,
                    tempProjectAdminAssignmentStepsData: undefined,
                    lastSavedAdminAssignmentUPN: fetchedDataAfterCreate?.UserPrincipalName
                }
            });
        }
    }, [isSaveProjectAssignmentSuccess]);

    return (
        <>
            <S.QuestionWrapper style={{ marginBottom: 0 }}>
                <Text size={14} weight={500}>
                    You are about to make {name}{" "}
                    an admin of the {DisplayName} project.
                </Text>
                <Text size={12} color="white.50">
                    This will take immedate effect
                </Text>                
            </S.QuestionWrapper>
            <S.AnswerWrapper style={{ marginBottom: 10 }}>
                <FormControl as="fieldset">
                    <Checkbox {...register("confirmation")} disabled={isLoading} error={!!errors.confirmation}>
                        I confirm that I am happy to proceed
                    </Checkbox>
                    {errors.confirmation && (
                        <FormErrorText style={{ marginTop: 4 }}>{errors.confirmation.message}</FormErrorText>
                    )}
                </FormControl>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                
                <Button variant="secondary" disabled={isLoading || isSaveProjectAssignmentSuccess} onClick={cancelWizard}>
                    Cancel
                </Button>
                <Button 
                    iconSvg={<Tick />} 
                    iconSize={9} 
                    isLoading={isLoading} 
                    disabled={isSaveProjectAssignmentSuccess}
                    onClick={handleSubmit(onConfirm)}>
                    Submit
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default Confirmation;
