import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Select, Text } from "@breakingwave/react-ui-components";
import Layout from "../../../components/layout";
import Spinner from "../../../components/spinner";
import Container from "../../../components/grid/Container";
import Row from "../../../components/grid/Row";
import Column from "../../../components/grid/Column";
import { IHeaderProps } from "../../../components/header";
import { IHtmlHeadProps } from "../../../components/html-head";
import { IFooterProps } from "../../../components/footer";
import { IOption } from "../../../types/selectOption";
import useGetProjectCatalogue from "../../../api/getProjectCatalogue";
import { IProject } from "../../../types/projectCatalogue";
import projectTeamMemberCSVExport from "../../../api/projectTeamMemberCSVExport";
import { useProjectPageContext } from "./_state/context";
import { ProjectPageActionTypesEnum } from "./_state/actions";
import TeamMembersTab from "./team-members-tab";
import SupportTab from "./support-tab";
import * as S from "./index.styles";

const ProjectPage: React.FC = () => {
    const htmlHeadProps: IHtmlHeadProps = {
        pageTitle: "Breaking Wave - Projects",
        indexPage: false
    };

    const headerProps: IHeaderProps = {
        showHideOnScroll: false,
        hasLoginButtonAndAvatar: true,
        isHeaderResponsive: false
    };

    const footerProps: IFooterProps = {
        hasFooterLinks: false,
        isFooterResponsive: false
    };

    const { 
        projectSlug: projectSlugFromURLPath, 
        tabSlug: tabSlugFromURLPath
    } = useParams();

    const navigate = useNavigate();

    const {
        state: projectPageState,
        state: {
            teamAssignmentWizard: { isActive: isTeamAssignmentWizardActive },
            leaveProjectWizard: { isActive: isLeaveProjectWizardActive }
        },
        dispatch: projectPageDispatch
    } = useProjectPageContext();

    const { 
        data: projectCatalogue, 
        isLoading: isLoadingProjects,
        refetch: loadProjects,
        isSuccess: isLoadProjectsSuccess 
    } = useGetProjectCatalogue({
        enabled: false
    });

    const { 
        downloadCsv, 
        isLoading: isDownloadingCsv
    } = projectTeamMemberCSVExport();

    const allProjects = useMemo<IProject[] | undefined>(() => {
        if (isLoadProjectsSuccess && projectCatalogue) {
            const allProjects = [ 
                ...projectCatalogue.HomeProjects, 
                ...projectCatalogue.WorkingProjects
            ];

            return allProjects.sort((a, b) => (a?.DisplayName.toLowerCase() > b?.DisplayName.toLowerCase()) ? 1 : (b?.DisplayName.toLowerCase() > a?.DisplayName.toLowerCase()) ? -1 : 0);
        }

        return undefined;
    }, [isLoadProjectsSuccess, projectCatalogue]);

    const [activeTab, setActiveTab] = useState(tabSlugFromURLPath ?? "team");
    const [selectOptions, setSelectOptions] = useState<IOption[]>();
    const [defaultSelectedOption, setDefaultSelectedOption] = useState<IOption>();

    const tabs = useMemo(() => {
        const localTabs = [
            { name: "Support", slug: "support", component: <SupportTab /> }
        ];

        if (defaultSelectedOption) {
            localTabs.unshift( { name: "Team members", slug: "team", component: <TeamMembersTab /> });
        } else {
            // Default to the support tab only if projects has finished loading and
            // none were returned from Wavecore
            if (activeTab !== "support" && isLoadProjectsSuccess && allProjects?.length === 0) {
                setActiveTab("support");
            }            
        }

        return localTabs;
    }, [defaultSelectedOption, isLoadProjectsSuccess]);

    useEffect(() => {
        loadProjects();
    }, []);

    useEffect(() => {
        if (activeTab !== "support" && isLoadProjectsSuccess && allProjects && allProjects.length > 0) {
            navigate(`/project/${projectSlugFromURLPath ?? allProjects[0].ProjectSlug}/team`);
        }
    }, [isLoadProjectsSuccess]);    

    useEffect(() => {
        if (allProjects && !selectOptions) {
            const dropdownOptions = allProjects
                .filter((p) => p.DisplayName)
                .map((p) => ({
                    value: p.ProjectSlug,
                    label: p.DisplayName
                }));
            setSelectOptions(dropdownOptions);
        }
    }, [allProjects]);

    const handleSelectedOptionChange = (optionValue: string): void => {
        if (allProjects && selectOptions) {
            const project = allProjects.find((p) => p.ProjectSlug === optionValue);

            if (project) {
                const selectListItem = selectOptions.find((p) => p.label === project.DisplayName);

                if (selectListItem) {
                    setDefaultSelectedOption(selectListItem);
                }

                projectPageDispatch({
                    type: ProjectPageActionTypesEnum.SET_PROJECT,
                    payload: project
                });

                navigate(`/project/${project.ProjectSlug}/${tabSlugFromURLPath ?? "team"}`);
            } else {
                // BACKLOG: It might be better to navigate user to a custom error page than to the homepage.
                navigate("/");
            }
        }
    };

    // If the projectSlug is changed from the URL, make sure to update page and the select dropdown:
    useEffect(() => {
        if (projectSlugFromURLPath) {
            handleSelectedOptionChange(projectSlugFromURLPath);
        }
    }, [projectSlugFromURLPath, selectOptions]);

    // On page refresh; if there is a match, make sure to update the activeTab:
    useEffect(() => {
        if (tabSlugFromURLPath) {
            if (tabs.find((tab) => tab.slug === tabSlugFromURLPath)) {
                setActiveTab(tabSlugFromURLPath);
            } else {
                navigate(`/project/${projectSlugFromURLPath}/team`);
            }
        }
    }, [tabSlugFromURLPath]);    

    React.useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === "local") {
            // ---- console log project details page state only in dev env ---- //
            console.log("Project page state: ", projectPageState);
        }
    }, [projectPageState]);

    return (
        <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>            
            <S.ProjectNavigationWrapper>
                <Container isResponsive={false}>
                    <Row>
                        <Column>
                            <S.ProjectSelectWrapper>
                                {!(isLoadProjectsSuccess && allProjects && allProjects.length === 0) && (                                
                                    <Text size={14} style={{ marginBottom: 12 }}>
                                    Project
                                    </Text>
                                )}
                                {isLoadingProjects ? (
                                    <Spinner size={3} padding={"0px"} />
                                ) : (
                                    selectOptions &&
                                    allProjects &&
                                    allProjects.length > 0 &&
                                    (!projectSlugFromURLPath || (projectSlugFromURLPath && defaultSelectedOption)) && (
                                        <Select
                                            placeholder="Select project"
                                            options={selectOptions}
                                            height={38}
                                            fontSize={18}
                                            defaultValue={defaultSelectedOption}
                                            onValueChange={(option: IOption) =>
                                                handleSelectedOptionChange(option.value)
                                            }
                                        />
                                    )
                                )}    
                            </S.ProjectSelectWrapper>
                        </Column>
                        <Column>
                            <S.DownloadCsvWrapper>
                                {projectSlugFromURLPath && (
                                    <Button 
                                        variant="secondary"
                                        iconSize={9}
                                        isLoading={isDownloadingCsv}
                                        loadingIconSize={11}                                   
                                        disabled={isDownloadingCsv}
                                        onClick={() => downloadCsv(projectSlugFromURLPath)}>
                                            Download CSV
                                    </Button>)}
                            </S.DownloadCsvWrapper>
                        </Column>
                    </Row>
                    {isLoadProjectsSuccess && (
                        <Row>
                            <Column>
                                <S.Tabs>
                                    {tabs.map((tab, index) => (
                                        <S.TabItem
                                            key={index}
                                            activeTab={activeTab === tab.slug}
                                            isDisabled={isTeamAssignmentWizardActive || isLeaveProjectWizardActive}
                                        >
                                            <Link
                                                to={projectPageState?.selectedProject?.ProjectSlug ? `/project/${projectPageState.selectedProject.ProjectSlug}/${tab.slug}` : "#"}
                                                title={tab.slug}
                                                onClick={(e) => setActiveTab(e.currentTarget.dataset.tabslug as string)}
                                                data-tabslug={tab.slug}
                                                data-testid={tab.slug}
                                            >
                                                {tab.name}
                                            </Link>
                                        </S.TabItem>
                                    ))}
                                </S.Tabs>
                            </Column>
                        </Row>)}
                </Container>
            </S.ProjectNavigationWrapper>
            {isLoadProjectsSuccess && tabs.find((tab) => tab.slug === activeTab)?.component}
        </Layout>
    );
};

export default ProjectPage;
