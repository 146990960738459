/* eslint-disable @typescript-eslint/naming-convention */

import { TaskGroupStatusesEnum } from "../../../../../types/shared";
import { InferredProjectTeamMemberStatusEnum } from "../../../../../types/teamAssignment";

export enum TeamMemberGroupStatusColourModeEnum {
    TEAM_MEMBER_DISPLAY_ITEMS_GROUP_STATUSES_MODE = "TEAM_MEMBER_DISPLAY_ITEMS_GROUP_STATUSES_MODE",
    PROJECT_ADMIN_ASSIGNMENT_GROUP_STATUSES_MODE = "PROJECT_ADMIN_ASSIGNMENT_GROUP_STATUSES_MODE",
    JOIN_PROJECT_GROUP_STATUSES_MODE = "JOIN_PROJECT_GROUP_STATUSES_MODE",
    LEAVE_PROJECT_GROUP_STATUSES_MODE = "LEAVE_PROJECT_GROUP_STATUSES_MODE",
    REVOKE_PROJECT_ADMIN_RIGHTS_GROUP_STATUSES_MODE = "REVOKE_PROJECT_ADMIN_RIGHTS_GROUP_STATUSES_MODE",
    OFFBOARD_TEAM_MEMBER_GROUP_STATUSES_MODE = "OFFBOARD_TEAM_MEMBER_GROUP_STATUSES_MODE"
}

export interface ITeamMemberGroupStatusColours {
    mode: {
        [TeamMemberGroupStatusColourModeEnum.TEAM_MEMBER_DISPLAY_ITEMS_GROUP_STATUSES_MODE]: {
            memberIcon: {
                [InferredProjectTeamMemberStatusEnum.Completed]: string;
                [InferredProjectTeamMemberStatusEnum.Failed]: string;
                [InferredProjectTeamMemberStatusEnum.Indeterminate]: string;
                [InferredProjectTeamMemberStatusEnum.Processing]: string;
            };
            status: {
                [TaskGroupStatusesEnum.Pending]: string;
                [TaskGroupStatusesEnum.InProgress]: string;
                [TaskGroupStatusesEnum.Complete]: string;
                [TaskGroupStatusesEnum.Failed]: string;
            };
        };
        [TeamMemberGroupStatusColourModeEnum.PROJECT_ADMIN_ASSIGNMENT_GROUP_STATUSES_MODE]: {
            memberIcon: {
                [InferredProjectTeamMemberStatusEnum.Completed]: string;
                [InferredProjectTeamMemberStatusEnum.Failed]: string;
                [InferredProjectTeamMemberStatusEnum.Indeterminate]: string;
                [InferredProjectTeamMemberStatusEnum.Processing]: string;
            };
            status: {
                [TaskGroupStatusesEnum.Pending]: string;
                [TaskGroupStatusesEnum.InProgress]: string;
                [TaskGroupStatusesEnum.Complete]: string;
                [TaskGroupStatusesEnum.Failed]: string;
            };
        };                
        [TeamMemberGroupStatusColourModeEnum.JOIN_PROJECT_GROUP_STATUSES_MODE]: {
            memberIcon: {
                [InferredProjectTeamMemberStatusEnum.Completed]: string;
                [InferredProjectTeamMemberStatusEnum.Failed]: string;
                [InferredProjectTeamMemberStatusEnum.Indeterminate]: string;
                [InferredProjectTeamMemberStatusEnum.Processing]: string;
            };
            status: {
                [TaskGroupStatusesEnum.Pending]: string;
                [TaskGroupStatusesEnum.InProgress]: string;
                [TaskGroupStatusesEnum.Complete]: string;
                [TaskGroupStatusesEnum.Failed]: string;
            };
        };  
        [TeamMemberGroupStatusColourModeEnum.LEAVE_PROJECT_GROUP_STATUSES_MODE]: {
            memberIcon: {
                [InferredProjectTeamMemberStatusEnum.Completed]: string;
                [InferredProjectTeamMemberStatusEnum.Failed]: string;
                [InferredProjectTeamMemberStatusEnum.Indeterminate]: string;
                [InferredProjectTeamMemberStatusEnum.Processing]: string;
            };
            status: {
                [TaskGroupStatusesEnum.Pending]: string;
                [TaskGroupStatusesEnum.InProgress]: string;
                [TaskGroupStatusesEnum.Complete]: string;
                [TaskGroupStatusesEnum.Failed]: string;
            };
        };
        [TeamMemberGroupStatusColourModeEnum.REVOKE_PROJECT_ADMIN_RIGHTS_GROUP_STATUSES_MODE]: {
            memberIcon: {
                [InferredProjectTeamMemberStatusEnum.Completed]: string;
                [InferredProjectTeamMemberStatusEnum.Failed]: string;
                [InferredProjectTeamMemberStatusEnum.Indeterminate]: string;
                [InferredProjectTeamMemberStatusEnum.Processing]: string;
            };
            status: {
                [TaskGroupStatusesEnum.Pending]: string;
                [TaskGroupStatusesEnum.InProgress]: string;
                [TaskGroupStatusesEnum.Complete]: string;
                [TaskGroupStatusesEnum.Failed]: string;
            };
        };      
        [TeamMemberGroupStatusColourModeEnum.OFFBOARD_TEAM_MEMBER_GROUP_STATUSES_MODE]: {
            memberIcon: {
                [InferredProjectTeamMemberStatusEnum.Completed]: string;
                [InferredProjectTeamMemberStatusEnum.Failed]: string;
                [InferredProjectTeamMemberStatusEnum.Indeterminate]: string;
                [InferredProjectTeamMemberStatusEnum.Processing]: string;
            };
            status: {
                [TaskGroupStatusesEnum.Pending]: string;
                [TaskGroupStatusesEnum.InProgress]: string;
                [TaskGroupStatusesEnum.Complete]: string;
                [TaskGroupStatusesEnum.Failed]: string;
            };
        };    
    };
}

export const teamMemberGroupStatusColours: ITeamMemberGroupStatusColours = {
    mode: {
        TEAM_MEMBER_DISPLAY_ITEMS_GROUP_STATUSES_MODE: {
            memberIcon: {
                Processing: "#00ff9d",
                Completed: "#00ff9d",
                Failed: "#ff406e",
                Indeterminate: "#cacaca"  
            },
            status: {
                Pending: "#bebebe",
                InProgress: "#00ff9d",
                Complete: "#00ff9d",
                Failed: "#ff406e"
            }
        },
        PROJECT_ADMIN_ASSIGNMENT_GROUP_STATUSES_MODE: {
            memberIcon: {
                Processing: "#00ff9d",
                Completed: "#00ff9d",
                Failed: "#ff406e",
                Indeterminate: "#cacaca"  
            },
            status: {
                Pending: "#bebebe",
                InProgress: "#00ff9d",
                Complete: "#00ff9d",
                Failed: "#ff406e"
            }
        },        
        JOIN_PROJECT_GROUP_STATUSES_MODE: {
            memberIcon: {
                Processing: "#e65aff",
                Completed: "#e65aff",
                Failed: "#ff406e",
                Indeterminate: "#cacaca"
            },
            status: {
                Pending: "#bebebe",
                InProgress: "#e65aff",
                Complete: "#e65aff",
                Failed: "#ff406e"
            }      
        },
        LEAVE_PROJECT_GROUP_STATUSES_MODE: {
            memberIcon: {
                Processing: "#e65aff",
                Completed: "#e65aff",
                Failed: "#ff406e",
                Indeterminate: "#cacaca"              
            },
            status: {
                Pending: "#bebebe",
                InProgress: "#e65aff",
                Complete: "#e65aff",
                Failed: "#ff406e"
            }
        },
        REVOKE_PROJECT_ADMIN_RIGHTS_GROUP_STATUSES_MODE: {
            memberIcon: {
                Processing: "#e65aff",
                Completed: "#e65aff",
                Failed: "#ff406e",
                Indeterminate: "#cacaca"              
            },
            status: {
                Pending: "#bebebe",
                InProgress: "#e65aff",
                Complete: "#e65aff",
                Failed: "#ff406e"
            }
        },
        OFFBOARD_TEAM_MEMBER_GROUP_STATUSES_MODE: {
            memberIcon: {
                Processing: "#e65aff",
                Completed: "#e65aff",
                Failed: "#ff406e",
                Indeterminate: "#cacaca"   
            },
            status: {
                Pending: "#bebebe",
                InProgress: "#e65aff",
                Complete: "#e65aff",
                Failed: "#ff406e"
            }
        }
    }
};
