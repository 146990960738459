import React from "react";

export interface IGridContextType {
    isResposive: boolean;
}

export const GridContext = React.createContext<IGridContextType>({
    isResposive: true
});

export function useGridContext (): IGridContextType {
    return React.useContext(GridContext);
}
