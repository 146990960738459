import styled from "styled-components";

const LoadingSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    div:first-child {
        height: auto;
    }
`;

const PageTitleSection = styled.section`
    background-color: #000;
    margin-bottom: 24px;
`;

const ConfirmationSection = styled.section`
    background-color: #000000;
    padding: 32px 0;
`;

const InputWrapper = styled.div`
    margin-bottom: 10px;

    input[type="text"] {
        height: 2.8em;
    }
`;

const ErrorMessage = styled.div`
    background-color: #ff5980;
    border-radius: 4px;
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
    margin-bottom: 0.5em;
`;

// TODO: Make this one a component on its own
const Hr = styled.hr<{ type?: string; color?: string; hidden?: boolean }>`
    border-top: 1px ${({ type }) => type ?? "solid"} ${({ color }) => color ?? "#000"};
    background-color: transparent;
    visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
`;

export { LoadingSection, PageTitleSection, ConfirmationSection, InputWrapper, ErrorMessage, Hr };
