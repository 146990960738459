import styled from "styled-components";
import { ReactComponent as RingIcon } from "./Ring_icon.svg";
import { ReactComponent as RingWithUserIcon } from "./Ring_with_user_icon.svg";
import { ReactComponent as ProjectAdminIcon } from "./Ring_with_Admin_icon.svg";
import { ReactComponent as ProjectAdminAndUserIcon } from "./Ring_with_Existing_user_admin.svg";

const ProfileProgressCircleWrapper = styled.div<{ size: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: ${({ size }) => size && `${size}px`};
    height: ${({ size }) => size && `${size}px`};
`;

const StyledRingIcon = styled(RingIcon)`
    position: absolute;
    width: 104%;
    height: auto;
`;

const StyledRingWithUserIcon = styled(RingWithUserIcon)`
    position: absolute;
    width: 104%;
    height: auto;
`;

const StyledProjectAdminIcon = styled(ProjectAdminIcon)`
    position: absolute;
    width: 104%;
    height: auto;
`;

const StyledProjectAdminAndUserIcon = styled(ProjectAdminAndUserIcon)`
    position: absolute;
    width: 104%;
    height: auto;
`;

export { 
    ProfileProgressCircleWrapper, 
    StyledRingIcon, 
    StyledRingWithUserIcon,
    StyledProjectAdminIcon,
    StyledProjectAdminAndUserIcon
};
