import { LeaveProjectWizardStepIdentifierEnum, ILeaveProjectWizardStepsState } from "./types";

const initialLeaveProjectWizardStepsState: ILeaveProjectWizardStepsState = {
    [LeaveProjectWizardStepIdentifierEnum.Confirmation]: {
        order: 1,
        isStepAnswered: false,
        isStepActive: true
    }
};

export default initialLeaveProjectWizardStepsState;
