import React, { useMemo } from "react";
import { Text } from "@breakingwave/react-ui-components";
import ProfileProgressCircle from "../profile-progress-circle";
import { AccordionToggleIconTypeEnum, ITeamAccordionProps, TeamAccordionMenuItemsEnum } from "../../types/accordions";
import { IContextMenuItem } from "../context-menu";
import { UserTypeEnum } from "../../types/shared";
import getFriendlyDeviceName from "../../utils/getFriendlyDeviceTypeName";
import ellipsisTextOverflow from "../../utils/ellipsisTextOverflow";
import useUserRoles from "../../hooks/useUserRoles";
import { UserRolesEnum } from "../../types/userRoles";
import * as S from "./Accordion.styles";

const TeamAccordion: React.FC<ITeamAccordionProps> = ({
    profileProgressCircleProps,
    children,
    isToggleable,
    toggleIconType,
    hasToggleIcon,
    defaultOpenState,
    teamMemberDisplayName,
    country,
    deviceType,
    startDate,
    team,
    rate,
    homeProjectName,
    onToggle,
    onContextMenuClick
}) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(defaultOpenState);
    const [showContextMenu, setShowContextMenu] = React.useState<boolean>(false);

    const { userType } = profileProgressCircleProps;

    const { userRole } = useUserRoles();

    const contextMenuItems = useMemo(() => {
        const items: IContextMenuItem[] = [];

        if (userType === UserTypeEnum.User || userType === UserTypeEnum.UserAndProjectAdmin) {
            items.push({
                displayText: TeamAccordionMenuItemsEnum.Projects,
                isLink: true,
                onClick: () => [setShowContextMenu(false), onContextMenuClick?.(TeamAccordionMenuItemsEnum.Projects)]
            });
        }

        if (userType === UserTypeEnum.UserAndProjectAdmin || userType === UserTypeEnum.User) {
            items.push({
                displayText: TeamAccordionMenuItemsEnum.RemoveTeamMember,
                isLink: true,
                onClick: () => [setShowContextMenu(false), onContextMenuClick?.(TeamAccordionMenuItemsEnum.RemoveTeamMember)]
            });  
        }

        if (userType === UserTypeEnum.User && userRole === UserRolesEnum.GlobalSuperAdmin) {        
            items.push({
                displayText: TeamAccordionMenuItemsEnum.AssignAsProjectAdmin,
                isLink: true,
                onClick: () => [setShowContextMenu(false), onContextMenuClick?.(TeamAccordionMenuItemsEnum.AssignAsProjectAdmin)]
            });        
        }

        if ((userType === UserTypeEnum.UserAndProjectAdmin || userType === UserTypeEnum.ProjectAdmin) && userRole === UserRolesEnum.GlobalSuperAdmin) {
            items.push({
                displayText: TeamAccordionMenuItemsEnum.RevokeProjectAdminRights,
                isLink: true,
                onClick: () => [setShowContextMenu(false), onContextMenuClick?.(TeamAccordionMenuItemsEnum.RevokeProjectAdminRights)]
            });        
        }

        return items;
    }, [userType, userRole]);

    const onToggleClick = (): void => {
        if (isToggleable) {
            setIsOpen(!isOpen);
            if (onToggle) {
                onToggle();
            }
        }
    };

    // JG 23/02/2024: 
    //      Removing this as it's causing accordions to collapse.
    //      Investigate what problem it was trying to solve
    // JG 16/06/2024: 
    //      Re-adding because we need it in order to open
    //      the panel via props.. observe
    React.useEffect(() => {
        if (defaultOpenState !== undefined) {
            setIsOpen(defaultOpenState);
        }
    }, [defaultOpenState]);

    return (
        <S.AccordionWrapper>
            <S.AccordionHeader isToggleable={isToggleable} onClick={onToggleClick} isOpen={isOpen}>
                <ProfileProgressCircle {...profileProgressCircleProps} />
                <Text variant="span" size={14} color="white.50">
                    {homeProjectName}
                </Text>
                <Text variant="span" size={14} color="white.50">
                    {ellipsisTextOverflow(teamMemberDisplayName, 22)}
                </Text>
                <Text variant="span" size={14} color="white.50">
                    {team ? team : "-"}
                </Text>                
                <Text variant="span" size={14} color="white.50">
                    {country}
                </Text>
                <Text variant="span" size={14} color="white.50">
                    {!deviceType ? "-" : getFriendlyDeviceName(deviceType)}
                </Text>                
                <Text variant="span" size={14} color="white.50">
                    {startDate}
                </Text>
                <Text variant="span" size={14} color="white.50">
                    {rate}
                </Text>
                {hasToggleIcon &&
                    (toggleIconType === AccordionToggleIconTypeEnum.Threedots ? (
                        <div>
                            <S.ToggleIcon
                                toggleIconType={AccordionToggleIconTypeEnum.Threedots}
                                onClick={() => setShowContextMenu(true)}
                            />
                            <S.ToggledContextMenu
                                isOpen={showContextMenu}
                                onClose={() => setShowContextMenu(false)}
                                menuItems={contextMenuItems}
                            />
                        </div>
                    ) : (
                        <S.ToggleIcon toggleIconType={AccordionToggleIconTypeEnum.Chevron} />
                    ))}
            </S.AccordionHeader>
            {isOpen && children}
        </S.AccordionWrapper>
    );
};

export default TeamAccordion;
