import styled, { css } from "styled-components";
import { IContainerProps } from "./Container";
import { IRowProps } from "./Row";
import { IColumnProps } from "./Column";
import { IMediaQueries, breakpoints } from "./mediaQueries";

const Container = styled.div<IContainerProps>`
    margin: 0 auto;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    ${({ isResponsive, fixedWidth }) => {
        if (isResponsive) {
            return css`
                @media ${breakpoints.sm} {
                    max-width: 540px;
                }

                @media ${breakpoints.md} {
                    max-width: 720px;
                }

                @media ${breakpoints.lg} {
                    max-width: 960px;
                }

                @media ${breakpoints.xl} {
                    max-width: 1140px;
                }

                @media ${breakpoints.xxl} {
                    max-width: 1320px;
                } ;
            `;
        } else {
            return css`
                width: ${fixedWidth}px;
            `;
        }
    }};
`;

const Row = styled.div<IRowProps & { isResponsive: boolean }>`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    & > * {
        box-sizing: border-box;
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
`;

// BACKLOG: Once this work is merged into bw-react-ui-components, these will become part of the DefaultTheme.
const columnWidths = [
    "8.33333333%",
    "16.66666667%",
    "25%",
    "33.33333333%",
    "41.66666667%",
    "50%",
    "58.33333333%",
    "66.66666667%",
    "75%",
    "83.33333333%",
    "91.66666667%",
    "100%"
];

const Column = styled.div<IColumnProps & { isResponsive: boolean }>`
    ${({ isResponsive }) =>
        /* If the container is not responsive, don't collapse child colums  */
        !isResponsive &&
        css`
            flex: 1 0 0%;
        `}

    ${({ sm, md, lg, xl, xxl }) =>
        // If neither of the responsive breakpoints is set, then do not collapse child columns
        !sm &&
        !md &&
        !lg &&
        !xl &&
        !xxl &&
        css`
            flex: 1 0 0%;
        `}

    ${({ sm }) => {
        if (sm) {
            if (typeof sm === "number") {
                return css`
                    @media ${breakpoints.sm as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${columnWidths[sm - 1]};
                    }
                `;
            } else {
                return css`
                    @media ${breakpoints.sm as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${sm?.size && columnWidths[sm.size - 1]};
                        margin-left: ${sm?.offset && columnWidths[sm.offset - 1]};
                    }
                `;
            }
        } else {
            return undefined;
        }
    }};

    ${({ md }) => {
        if (md) {
            if (typeof md === "number") {
                return css`
                    @media ${breakpoints.md as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${columnWidths[md - 1]};
                    }
                `;
            } else {
                return css`
                    @media ${breakpoints.md as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${md?.size && columnWidths[md.size - 1]};
                        margin-left: ${md?.offset && columnWidths[md.offset - 1]};
                    }
                `;
            }
        } else {
            return undefined;
        }
    }};

    ${({ lg }) => {
        if (lg) {
            if (typeof lg === "number") {
                return css`
                    @media ${breakpoints.lg as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${columnWidths[lg - 1]};
                    }
                `;
            } else {
                return css`
                    @media ${breakpoints.lg as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${lg?.size && columnWidths[lg.size - 1]};
                        margin-left: ${lg?.offset && columnWidths[lg.offset - 1]};
                    }
                `;
            }
        } else {
            return undefined;
        }
    }};

    ${({ xl }) => {
        if (xl) {
            if (typeof xl === "number") {
                return css`
                    @media ${breakpoints.xl as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${columnWidths[xl - 1]};
                    }
                `;
            } else {
                return css`
                    @media ${breakpoints.xl as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${xl?.size && columnWidths[xl.size - 1]};
                        margin-left: ${xl?.offset && columnWidths[xl.offset - 1]};
                    }
                `;
            }
        } else {
            return undefined;
        }
    }};

    ${({ xxl }) => {
        if (xxl) {
            if (typeof xxl === "number") {
                return css`
                    @media ${breakpoints.xxl as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${columnWidths[xxl - 1]};
                    }
                `;
            } else {
                return css`
                    @media ${breakpoints.xxl as keyof IMediaQueries} {
                        flex: 0 0 auto;
                        width: ${xxl?.size && columnWidths[xxl.size - 1]};
                        margin-left: ${xxl?.offset && columnWidths[xxl.offset - 1]};
                    }
                `;
            }
        } else {
            return undefined;
        }
    }};
`;

export { Container, Row, Column };
