import styled from "styled-components";
import { ReactComponent as StatusRing } from "./Status_ring_icon.svg";
import { ReactComponent as StatusInProgress } from "./Status_in_progress.svg";
import { ReactComponent as StatusCompleteTick } from "./Status_complete_tick.svg";
import { IGroupStatusIconProps } from ".";

const GroupStatusIconContainer = styled.div<IGroupStatusIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: ${({ size }) => `${size ? size : 30}px`};
    height: ${({ size }) => `${size ? size : 30}px`};
    svg {
        stroke: ${({ color }) => color};
        color: ${({ color }) => color};
    }
`;

const StyledStatusRing = styled(StatusRing)`
    position: absolute;
    width: 100%;
    height: auto;
`;

const StyledStatusInProgress = styled(StatusInProgress)`
    position: absolute;
    width: 45%;
    height: auto;
`;

const StyledStatusCompleteTick = styled(StatusCompleteTick)`
    position: absolute;
    width: 100%;
    height: auto;
`;

export { GroupStatusIconContainer, StyledStatusRing, StyledStatusInProgress, StyledStatusCompleteTick };
