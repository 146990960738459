import { IProjectAdminAssignmentWizardStepsState, ProjectAdminAssignmentWizardStepIdentifierEnum } from "./types";

const initialProjectAdminAssignmentStepsState: IProjectAdminAssignmentWizardStepsState = {
    [ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation]: {
        order: 1,
        isStepAnswered: false,
        isStepActive: true,
        answer: {
            confirmation: false
        }
    }     
};

export default initialProjectAdminAssignmentStepsState;
