import styled from "styled-components";

const Avatar = styled.div`
    border-radius: 50%;
    background-color: #58e7ff;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const AvatarRing = styled.div`
    border-radius: 50%;
    background-color: #050400;
    width: calc(42px - 4px);
    height: calc(42px - 4px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AvatarContent = styled.div`
    border-radius: 50%;
    background-color: #58e7ff;
    width: calc(42px - 8px);
    height: calc(42px - 8px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #050400;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    user-select: none;
`;

export { Avatar, AvatarRing, AvatarContent };
