import React from "react";
import PropTypes from "prop-types";
import Headroom from "react-headroom";
import { Link, useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "@breakingwave/react-ui-components";
import Avatar from "../avatar/index";
import ContextMenu, { IContextMenuItem } from "../context-menu";
import Container from "../grid/Container";
import Row from "../grid/Row";
import Column from "../grid/Column";
import { useProjectPageContext } from "../../pages/private/project/_state/context";
import * as S from "./Header.styles";

export interface IHeaderProps {
    showHideOnScroll: boolean;
    hasLoginButtonAndAvatar: boolean;
    isHeaderResponsive: boolean;
}

/**
 * A standalone header component that features show/hide behavior on scroll.
 * @param {boolean} showHideOnScroll - A boolean prop which is passed down via <Layout /> that makes <Header /> to render with or without show/hide behavior on scroll.
 * @param {boolean} hasLoginButtonAndAvatar - A boolean prop which is passed down via <Layout /> that makes <Header /> to render with login button and user avatar (if logged in).
 * @param {boolean} isResponsive - A boolean prop which is passed down via <Layout /> that makes <Header /> container responsive.
 */
const Header: React.FC<IHeaderProps> = ({ showHideOnScroll, hasLoginButtonAndAvatar, isHeaderResponsive }) => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const { state: projectPageState } = useProjectPageContext();

    const [showHeaderBackgroundOnScroll, setShowHeaderBackgroundOnScroll] = React.useState<boolean>(false);
    const [showAvatarMenu, setShowAvatarMenu] = React.useState<boolean>(false);

    const handleHeaderBackgroundOnScroll = (): void => {
        window.pageYOffset > 90 && setShowHeaderBackgroundOnScroll(true);
        window.pageYOffset < 30 && setShowHeaderBackgroundOnScroll(false);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleHeaderBackgroundOnScroll);
        return (): void => {
            window.removeEventListener("scroll", handleHeaderBackgroundOnScroll);
        };
    }, []);

    const handleLogin = (): void => {
        if (isAuthenticated) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/"
            });
        } else {
            navigate("/login");
        }
    };

    // To avoid unnecessary re-renders, we fire the navigate hook only if the user isn't already viewing the /project route.
    const menuOnClick = (menuid: string): void => {
        setShowAvatarMenu(false);
        projectPageState.selectedProject.ProjectSlug ? navigate("#") : navigate(menuid);
    };

    const avatarMenuItems: IContextMenuItem[] = [
        {
            displayText: "Projects",
            isLink: true,
            onClick: () => menuOnClick("/project/")
        },
        { displayText: "Sign out", isLink: false, onClick: () => handleLogin() }
    ];

    const header = (
        <S.Header hasBackgroundTransparent={!showHeaderBackgroundOnScroll}>
            <Container isResponsive={isHeaderResponsive}>
                <Row>
                    <Column>
                        <Link to="/">
                            <S.HeaderBreakingWaveLogo
                                src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/logo/horz/BreakingWave_horiz_logo_rgb_neg.png`}
                                alt="Breaking Wave Horizontal Logo"
                            />
                        </Link>
                    </Column>
                    <Column>
                        {hasLoginButtonAndAvatar && (
                            <S.HeaderRightColumnContent>
                                {isAuthenticated ? (
                                    <>
                                        <S.AvatarWrapper>
                                            <Avatar onClick={() => setShowAvatarMenu(true)} />
                                            <S.AvatarContextMenuWrapper>
                                                <ContextMenu
                                                    isOpen={showAvatarMenu}
                                                    onClose={() => setShowAvatarMenu(false)}
                                                    topElement={
                                                        <S.AvatarContextMenuHeader>
                                                            <S.AvatarContextMenuText>
                                                                {accounts[0]?.name ?? "-"}
                                                            </S.AvatarContextMenuText>
                                                            <S.AvatarContextMenuSubText>
                                                                {accounts[0] ? accounts[0].username.toLowerCase() : "-"}
                                                            </S.AvatarContextMenuSubText>
                                                        </S.AvatarContextMenuHeader>
                                                    }
                                                    menuItems={avatarMenuItems}
                                                />
                                            </S.AvatarContextMenuWrapper>
                                        </S.AvatarWrapper>
                                    </>
                                ) : (
                                    <>
                                        <Button variant="secondary" onClick={handleLogin}>
                                            {isAuthenticated ? "Sign out" : "Login"}
                                        </Button>
                                    </>
                                )}
                            </S.HeaderRightColumnContent>
                        )}
                    </Column>
                </Row>
            </Container>
        </S.Header>
    );

    return showHideOnScroll ? <Headroom>{header}</Headroom> : header;
};

Header.propTypes = {
    showHideOnScroll: PropTypes.bool.isRequired,
    hasLoginButtonAndAvatar: PropTypes.bool.isRequired,
    isHeaderResponsive: PropTypes.bool.isRequired
};

export default Header;
