import React from "react";
import { ProjectAdminAssignmentWizardStepsActionTypesEnum } from "./actions";
import { IProjectAdminAssignmentWizardStepsState, ProjectAdminAssignmentWizardStepIdentifierEnum } from "./types";
import initialProjectAdminAssignmentStepsState from "./initialState";

export interface ISetConfirmationAction {
    type: ProjectAdminAssignmentWizardStepsActionTypesEnum.SET_CONFIRMATION;
    payload: IProjectAdminAssignmentWizardStepsState[ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation];
}

export type ProjectAdminWizardStepsActions = 
    | ISetConfirmationAction;

export const reducer: React.Reducer<IProjectAdminAssignmentWizardStepsState, ProjectAdminWizardStepsActions> = (state, action) => {
    switch (action.type) {
        case ProjectAdminAssignmentWizardStepsActionTypesEnum.SET_CONFIRMATION: {
            return {
                ...state,
                [ProjectAdminAssignmentWizardStepIdentifierEnum.Confirmation]: {
                    ...action.payload
                }
            };
        }
        default:
            return state;
    }
};

interface IProjectAdminAssignmentStepsContextProps {
    state: IProjectAdminAssignmentWizardStepsState;
    dispatch: React.Dispatch<ProjectAdminWizardStepsActions>;
}

export const ProjectAdminAssignmentStepsContext = React.createContext<IProjectAdminAssignmentStepsContextProps>({
    state: initialProjectAdminAssignmentStepsState,
    dispatch: () => null
});

export const useProjectAdminAssignmentStepsContext = (): IProjectAdminAssignmentStepsContextProps => 
    React.useContext(ProjectAdminAssignmentStepsContext);
