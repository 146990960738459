import { IProfileProgressCircleProps } from "../components/profile-progress-circle";
import { InferredGenericRequestStatusEnum } from "./genericRequestDisplayItems";
import { DeviceTypeEnum } from "./shared";

/* eslint-disable @typescript-eslint/naming-convention */
export enum AccordionToggleIconTypeEnum {
    Chevron,
    Threedots
}

export enum TeamAccordionMenuItemsEnum {
    Projects = "Projects",
    RemoveTeamMember = "Remove team member",
    AssignAsProjectAdmin = "Assign as project admin",
    RevokeProjectAdminRights = "Revoke project admin rights",
}

export interface ITeamAccordionProps {
    profileProgressCircleProps: IProfileProgressCircleProps;
    children?: React.ReactNode;
    isToggleable: boolean;
    toggleIconType?: AccordionToggleIconTypeEnum;
    hasToggleIcon: boolean;
    defaultOpenState: boolean;
    homeProjectName: string;
    teamMemberDisplayName: string;
    country: string;
    deviceType: DeviceTypeEnum | undefined;
    startDate: string;
    team: string | undefined;
    rate: string;
    onToggle?: () => void;
    onContextMenuClick?: (menuOption: TeamAccordionMenuItemsEnum) => void;
}

export interface IGenericRequestAccordionProps {
    children?: React.ReactNode;
    profileProgressCircleProps: IProfileProgressCircleProps;
    displayName: string;
    requestId: number;
    mondayboardId: string;
    applicant: string;
    inferredGenericRequestStatus: InferredGenericRequestStatusEnum;
    dateCreatedUtc: Date;
    defaultOpenState: boolean;
    isToggleable: boolean;
    hasToggleIcon: boolean;
    enableLinkToMondayboard: boolean;
}
