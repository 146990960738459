import React from "react";
import { JoinProjectWizardStepsActionTypesEnum } from "./actions";
import {
    JoinProjectWizardStepIdentifierEnum,
    IJoinProjectWizardStepsState,
    IJoinProjectWizardStepState__UndefinedFiltered
} from "./types";
import initialJoinProjectWizardStepsState from "./initialState";

export interface ILoadNextStepAction {
    type: JoinProjectWizardStepsActionTypesEnum.LOAD_NEXT_STEP;
}

export interface ILoadPrevStepAction {
    type: JoinProjectWizardStepsActionTypesEnum.LOAD_PREV_STEP;
}

export interface ISetMemberDataAction {
    type: JoinProjectWizardStepsActionTypesEnum.SET_SEARCHED_MEMBER_DATA;
    payload: IJoinProjectWizardStepsState[JoinProjectWizardStepIdentifierEnum.SearchedMemberData];
}

export interface ISetConfirmationAction {
    type: JoinProjectWizardStepsActionTypesEnum.SET_CONFIRMATION;
    payload: IJoinProjectWizardStepsState[JoinProjectWizardStepIdentifierEnum.Confirmation];
}

export type IJoinProjectWizardStepsActions =
    | ILoadNextStepAction
    | ILoadPrevStepAction
    | ISetMemberDataAction
    | ISetConfirmationAction;

export const reducer: React.Reducer<IJoinProjectWizardStepsState, IJoinProjectWizardStepsActions> = (state, action) => {
    const activeStep = Object.keys(state)
        .filter((f) => state[f as keyof IJoinProjectWizardStepsState].isStepActive)
        .reduce((r) => r.toString());
    const activeStepOrder = state[activeStep as keyof IJoinProjectWizardStepState__UndefinedFiltered].order;

    switch (action.type) {
        case JoinProjectWizardStepsActionTypesEnum.LOAD_NEXT_STEP: {
            if (activeStepOrder >= 1 && activeStepOrder < Object.keys(state).length) {
                const nextStepFromActiveStepOrder = Object.keys(state).find(
                    (f) => state[f as keyof IJoinProjectWizardStepsState].order === activeStepOrder + 1
                );
                return {
                    ...state,
                    [activeStep as keyof IJoinProjectWizardStepsState]: {
                        ...state[activeStep as keyof IJoinProjectWizardStepsState],
                        isStepActive: false,
                        isStepAnswered: true
                    },
                    [nextStepFromActiveStepOrder as keyof IJoinProjectWizardStepsState]: {
                        ...state[nextStepFromActiveStepOrder as keyof IJoinProjectWizardStepsState],
                        isStepActive: true
                    }
                };
            } else {
                // Error: Next step not found.
                return state;
            }
        }
        case JoinProjectWizardStepsActionTypesEnum.LOAD_PREV_STEP: {
            const prevStepFromActiveStepOrder = Object.keys(state).find(
                (f) => state[f as keyof IJoinProjectWizardStepState__UndefinedFiltered].order === activeStepOrder - 1
            );
            if (activeStepOrder > 1 && activeStepOrder <= Object.keys(state).length) {
                return {
                    ...state,
                    [activeStep as keyof IJoinProjectWizardStepsState]: {
                        ...state[activeStep as keyof IJoinProjectWizardStepsState],
                        isStepActive: false
                    },
                    [prevStepFromActiveStepOrder as keyof IJoinProjectWizardStepsState]: {
                        ...state[prevStepFromActiveStepOrder as keyof IJoinProjectWizardStepsState],
                        isStepActive: true,
                        isStepAnswered: false
                    }
                };
            } else {
                // Error: Previous step not found.
                return state;
            }
        }
        case JoinProjectWizardStepsActionTypesEnum.SET_SEARCHED_MEMBER_DATA: {
            return {
                ...state,
                [JoinProjectWizardStepIdentifierEnum.SearchedMemberData]: {
                    ...action.payload
                }
            };
        }
        case JoinProjectWizardStepsActionTypesEnum.SET_CONFIRMATION: {
            return {
                ...state,
                [JoinProjectWizardStepIdentifierEnum.Confirmation]: {
                    ...action.payload
                }
            };
        }
        default:
            return state;
    }
};

interface IJoinProjectWizardStepsContextProps {
    state: IJoinProjectWizardStepsState;
    dispatch: React.Dispatch<IJoinProjectWizardStepsActions>;
}

export const JoinProjectWizardStepsContext = React.createContext<IJoinProjectWizardStepsContextProps>({
    state: initialJoinProjectWizardStepsState,
    dispatch: () => null
});

export const useJoinProjectWizardStepsContext = (): IJoinProjectWizardStepsContextProps =>
    React.useContext(JoinProjectWizardStepsContext);
