import React, { useEffect } from "react";
import { useRevokeProjectAdminRightsWizardStepsContext } from "../_state/context";
import { RevokeProjectAdminRightsWizardStepIdentifierEnum, IRevokeProjectAdminRightsWizardStepsState } from "../_state/types";
import Confirmation from "./Confirmation";
import * as S from "../../wizard.styles";

const RevokeProjectAdminRightsWizardSteps: React.FC = () => {
    const { state } = useRevokeProjectAdminRightsWizardStepsContext();

    const [activeStep, setActiveStep] = React.useState(Object.values(state).find((f) => f.order === 1).identifier);

    useEffect(() => {
        const activeStepFromRevokeProjectAdminRightsWizard = Object.keys(state).find(
            (f) => state[f as keyof IRevokeProjectAdminRightsWizardStepsState].isStepActive
        );
        setActiveStep(activeStepFromRevokeProjectAdminRightsWizard);
    }, [state]);

    return (
        <S.Form autoComplete="off">
            {activeStep === RevokeProjectAdminRightsWizardStepIdentifierEnum.Confirmation && <Confirmation />}
        </S.Form>
    );
};

export default RevokeProjectAdminRightsWizardSteps;
