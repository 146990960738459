import styled from "styled-components";

const StyledCentredDiv = styled.div`
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;    
    justify-items: center;
`;

const StyledNotFoundHeader = styled.h1`
    font-size: 2em;
    font-weight: bold;
`;

const NotFoundPageWrapper = styled.div`
    .mobile-font-size-16 {
        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    }

    .mobile-font-size-20 {
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
    }

    .mobile-font-size-22 {
        @media screen and (max-width: 768px) {
            font-size: 22px;
        }
    }
`;


export { StyledCentredDiv, StyledNotFoundHeader, NotFoundPageWrapper };
