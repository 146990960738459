import { ITaskItem } from "./shared";

export interface ILeaveProjectRequest {
    UserPrincipalName: string;
}

export interface ILeaveProjectResponse {
    ProjectSlug: string;
    Id: string;
    DateCreatedUtc: Date;
    UserPrincipalName: string;
    BoardId: string;
    Status: string; // TODO: Change it to enum types. Find statuses from the state machine doc
    StatusDateTimeUtc: Date;
    ApprovedDateTimeUtc: Date;
    TaskItems: ITaskItem[];
}

export enum CreateLeaveProjectDomainValidationFailureReasonEnum {
    Success = 0,
    ProjectNotFound = 1 << 0, // 1
    NotAMemberOfProject = 1 << 1, // 2
    AuthenticatedUserDoesNotHavePermission = 1 << 2, // 4
    UserBelongsToSingleProject = 1 << 3, // 8
    InFlightManagedProcesses = 1 << 4, // 16
    ProjectAdminCannotRemoveThemselfFromProject = 1 << 5 // 32
}

export interface ILeaveProjectErrorResponse {
    ValidationResult: CreateLeaveProjectDomainValidationFailureReasonEnum;
}
