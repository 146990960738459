import styled from "styled-components";
import { breakpoints } from "../../../../components/grid/mediaQueries";

const SupportTabBody = styled.div`
    background-color: #1d1d1d;
    padding: 56px 0 48px;
`;

const SupportCard = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    border: 1px solid #595959;
    height: 279px;
    padding: 40px 24px 24px;
    margin-bottom: 40px;

    @media ${breakpoints.xl} {
        margin-bottom: 0;
    };
`;

const SupportCardTitle = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    margin-bottom: 24px;

    svg {
        margin-right: 8px;
    }
`;

const SupportCardAction = styled.div`
    margin-top: auto;

`;

export { SupportTabBody, SupportCard, SupportCardTitle, SupportCardAction };
