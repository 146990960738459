import styled from "styled-components";

// TODO: This is the part 1 of homepage refactoring.
// As of 24 feb '22, we still haven't implemented responsive typography sizes in bw-react-ui-components. Since the homepage is our only responsive page, we need responsive font sizes. Therefore, they are implemented as follows and needs to be removed once the component library has them.
const HomepageWrapper = styled.div`
    .mobile-font-size-16 {
        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    }

    .mobile-font-size-20 {
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
    }

    .mobile-font-size-22 {
        @media screen and (max-width: 768px) {
            font-size: 22px;
        }
    }
`;

export { HomepageWrapper };
