import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, Tick, Checkbox, FormErrorText, FormControl } from "@breakingwave/react-ui-components";
import { useProjectPageContext } from "../../../../_state/context";
import { ProjectPageActionTypesEnum } from "../../../../_state/actions";
import useCreateOffboardTeamMember from "../../../../../../../api/createOffboardTeamMember";
import useGetProjectTeamMemberDisplayItems from "../../../../../../../api/getProjectTeamMemberDisplayItems";
import { ConfirmationStepInputs, confirmationStepSchema } from "../index.schema";
import { IOffboardTeamMemberErrorResponse } from "../../../../../../../types/offboardTeamMember";
import { offboardTeamMemberDomainValidationFailureReason } from "../../../../../../../utils/offboardTeamMemberDomainValidationFailureReason";
import * as S from "../../wizard.styles";

const Confirmation: React.FC = () => {

    const {
        state: projectPageState,
        state: {
            selectedProject: {
                ProjectSlug 
            }
        },
        dispatch: projectPageDispatch
    } = useProjectPageContext();

    // TODO: Investigate this refetch. I am not sure if it is necessary. If we really need it, the alternative approach could be invalidating this useGetTeamMemberDisplayItems hook by calling queryClient.invalidateQueries in react-query onSuccess func. In useOffboardTeamMember hook.
    const { 
        refetch: refetchTeamMemberDisplayItems 
    } = useGetProjectTeamMemberDisplayItems(ProjectSlug);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,        
        watch
    } = useForm<ConfirmationStepInputs>({
        resolver: yupResolver(confirmationStepSchema)
    });

    const isConfirmed: boolean = watch("confirmation") ?? false;

    useEffect(() => {
        clearErrors();
    },[isConfirmed]);

    const { 
        mutateAsync: offboardTeamMember, 
        isSuccess: isSubmitOffboardingSuccess,
        isLoading,
        data: offboardTeamMemberResponse
    } = useCreateOffboardTeamMember(
        ProjectSlug
    );

    const closeOffboardTeamMemberWizard = (): void => {
        // Save the newly fetched single join data to the page-level state.
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.SET_OFFBOARD_TEAM_MEMBER_WIZARD,
            payload: {
                isActive: false,
                tempOffboardingMemberData: undefined,
                lastSavedOffboardUPN: offboardTeamMemberResponse?.UserPrincipalName
            }
        });
    };

    React.useEffect(() => {
        if (isSubmitOffboardingSuccess) {
            refetchTeamMemberDisplayItems();
            closeOffboardTeamMemberWizard();
        }
    }, [isSubmitOffboardingSuccess]);

    const onConfirm = async (data: ConfirmationStepInputs): Promise<void> => {
        if (data.confirmation) {        
            await offboardTeamMember({
                UserPrincipalName: projectPageState.offboardTeamMemberWizard.tempOffboardingMemberData?.UserPrincipalName ?? ""
            }).catch((error) => {
                
                const result = error.response.data as IOffboardTeamMemberErrorResponse;
                const bitwise: number = result.ValidationResult;

                setError("confirmation", { 
                    message: offboardTeamMemberDomainValidationFailureReason(bitwise)
                });           
            });
        }
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={300} style={{ marginBottom: 12 }}>
                    You would like to remove this team member from the project.
                    <br />
                    This process will begin immediately.
                </Text>
                <FormControl as="fieldset">
                    <Checkbox {...register("confirmation")} error={!!errors.confirmation} disabled={isLoading || isSubmitOffboardingSuccess}>
                        Please check this box if you are happy to proceed
                    </Checkbox>
                    {(errors.confirmation) && (
                        <FormErrorText style={{ marginTop: 4 }}>{errors.confirmation.message}</FormErrorText>
                    )}
                </FormControl>
            </S.QuestionWrapper>            
            <S.OptionsWrapper>
                <Button variant="secondary" disabled={isLoading || isSubmitOffboardingSuccess} onClick={closeOffboardTeamMemberWizard}>
                    Cancel
                </Button>
                <Button
                    iconSvg={<Tick />}
                    iconSize={9}
                    isLoading={isLoading}
                    loadingIconSize={11}
                    onClick={handleSubmit(onConfirm)}
                    disabled={!isConfirmed || isSubmitOffboardingSuccess}
                >
                    Confirm
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default Confirmation;
