import React, { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Heading, Select, TextField, Text } from "@breakingwave/react-ui-components";
import Column from "../../../../components/grid/Column";
import Container from "../../../../components/grid/Container";
import Row from "../../../../components/grid/Row";
import Layout from "../../../../components/layout";
import { useOnboardingContext } from "../_state/context";
import { OnboardingActionTypesEnum } from "../_state/actions";
import countries from "../../../../utils/countries";
import { DeviceTypeEnum, TaskItemStatusEnum } from "../../../../types/shared";
import { IDeviceRequest, ProjectTeamAssignmentTaskAutomationKeyEnum } from "../../../../types/onboarding";
import Spinner from "../../../../components/spinner";
import useNotifyAutomationService from "../../../../api/notifyAutomationService";
import useGetManagedProcessTaskStatus from "../../../../api/getManagedProcessTaskStatus";
import { footerProps, headerProps, htmlHeadProps } from "./DeviceRequirement.config";
import * as S from "./DeviceRequirement.styles";

const DeviceRequirement: React.FC = () => {
    const [error, setError] = React.useState<string>();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { state, dispatch } = useOnboardingContext();

    const {
        ManagedContext,
        ManagedProcess: projectTeamAssignment,
        DeviceRequest,
        AccessToken: OnboardingAccessToken
    } = state;

    const projectSlug = useMemo(() => projectTeamAssignment?.ProjectSlug ?? "",[projectTeamAssignment]);       
    const deviceRequirementAccessToken = useMemo(() => projectTeamAssignment?.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.DEVICE_REQUIREMENT_COMPLETE.toString())?.AccessToken, []);

    const {
        isLoading: isSubmittingDeviceRequirement,
        mutateAsync: submitDeviceRequirement,
        isSuccess: isSubmittingDeviceRequirementSuccess
    } = useNotifyAutomationService(ManagedContext, projectSlug, deviceRequirementAccessToken, ProjectTeamAssignmentTaskAutomationKeyEnum.DEVICE_REQUIREMENT_COMPLETE);

    const {
        isFetching: isGetDeviceRequirementTaskStatusFetching,
        data: deviceRequirementTaskStatus,
        isSuccess: isGetDeviceRequirementTaskStatusSuccess
    } = useGetManagedProcessTaskStatus(
        ManagedContext,
        projectSlug,
        ProjectTeamAssignmentTaskAutomationKeyEnum.DEVICE_REQUIREMENT_COMPLETE,
        deviceRequirementAccessToken,
        {
            enabled: !!ManagedContext.Id && 
                     !!projectSlug && 
                     !!ManagedContext.ResourceType &&
                     isSubmittingDeviceRequirementSuccess,
            refetchInterval: 2500
        }
    );

    const isDeviceRequirementComplete = useMemo(() => 
        !isGetDeviceRequirementTaskStatusFetching &&
        isGetDeviceRequirementTaskStatusSuccess && 
        deviceRequirementTaskStatus?.TaskStatus === TaskItemStatusEnum.Complete
    , [isGetDeviceRequirementTaskStatusFetching, deviceRequirementTaskStatus]);

    useEffect(() => {        
        const { id, p, t } = Object.fromEntries([...searchParams]); 

        // If the user hit F5 then the managed process will not be
        // in state and so we need to go back to the landing page.
        if (!projectTeamAssignment) {
            if (id && p && t) {
                console.log("Navigating from 'Device Requirement' to 'Onboarding Landing'.  User probably hit F5");
                navigate(`/onboarding?id=${id}&p=${p}&t=${t}`);
            } else {
                console.log("Parameters error. Redirecting to root.");
                navigate("/");
            }
        }
    }, []);    

    useEffect(() => {
        if (projectTeamAssignment && isDeviceRequirementComplete) {
            // Go back to landing page to keep user on screen whilst other tasks complete
            navigate(`/onboarding?id=${ManagedContext.Id}&p=${ManagedContext.ProjectSlugOrProjectId}&t=${OnboardingAccessToken}`);
        }
    }, [isDeviceRequirementComplete]);

    useEffect( () => {
        if (projectTeamAssignment && projectTeamAssignment.DeviceType !== DeviceTypeEnum.Laptop) {
            console.log("Auto submitting device type");
            submitDeviceRequirement(undefined);
        }
    }, []);

    const validate = (): boolean => {
        // TODO: Implement Formik or react useForm and discuss bw lib implementation with FE team.
        // The logic below simply replicates the validation from the existing system

        if (!DeviceRequest.ContactNumber || !DeviceRequest.ContactNumber.trim()) {
            setError("Please input a contact number");
            return false;
        }   

        if (!DeviceRequest.PostalAddress.City || !DeviceRequest.PostalAddress.City.trim()) {
            setError("Please input a city");
            return false;
        }

        if (!DeviceRequest.PostalAddress.PostalCode || !DeviceRequest.PostalAddress.PostalCode.trim()) {
            setError("Please input a postcode");
            return false;
        }

        if (!DeviceRequest.PostalAddress.StreetAddress || !DeviceRequest.PostalAddress.StreetAddress.trim()) {
            setError("Please input a street address");
            return false;
        }

        if (!DeviceRequest.PostalAddress.State || !DeviceRequest.PostalAddress.State.trim()) {
            setError("Please input a state");
            return false;
        }
        
        return true;
    };

    const confirmDeviceSelection = async (): Promise<void> => {
        if (validate()) {
            const payload: IDeviceRequest = {
                ContactNumber: DeviceRequest.ContactNumber,
                PostalAddress: {
                    ...DeviceRequest.PostalAddress
                }
            };
            await submitDeviceRequirement(payload);
        }
    };

    const clearError = (): void => {
        setError("");
    };

    const setDeviceRequestField = (e: React.ChangeEvent<HTMLInputElement>): void => {
        clearError();

        dispatch({
            type: OnboardingActionTypesEnum.SET_DEVICE_REQUEST,
            payload: {
                ...DeviceRequest,
                [e.target.name]: e.target.value
            }
        });
    };

    const setDeviceRequestAddressField = (e: React.ChangeEvent<HTMLInputElement>): void => {
        clearError();

        dispatch({
            type: OnboardingActionTypesEnum.SET_DEVICE_REQUEST,
            payload: {
                ...DeviceRequest,
                PostalAddress: {
                    ...DeviceRequest.PostalAddress,
                    [e.target.name]: e.target.value
                }
            }
        });
    };

    return (
        <>
            {projectTeamAssignment?.DeviceType !== DeviceTypeEnum.Laptop && (isSubmittingDeviceRequirement || isSubmittingDeviceRequirementSuccess) && (
                <S.LoadingSection>
                    <Spinner padding="20px" />
                    <h1>Please wait while we save your device requirements</h1>
                </S.LoadingSection>
            )}

            {projectTeamAssignment && projectTeamAssignment.DeviceType === DeviceTypeEnum.Laptop && !isDeviceRequirementComplete && (
                <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
                    <S.PageTitleSection>
                        <Container isResponsive={true}>
                            <Row>
                                <Column>
                                    <Heading variant="h5" weight={300}>
                                        Device type
                                    </Heading>
                                    <S.Hr color="#595959" style={{ margin: "16px 0 24px" }} />
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <Text size={20} weight={300}>
                                        Project
                                    </Text>
                                    <Text size={20} weight={500} color="white.50">
                                        {projectTeamAssignment?.ProjectName ?? searchParams.get("ProjectSlug")}
                                    </Text>
                                </Column>
                            </Row>
                        </Container>
                    </S.PageTitleSection>
                    <S.ConfirmationSection>
                        <Container isResponsive={true}>
                            <Row>
                                <Column md={{ size: 6, offset: 3 }}>
                                    <>
                                        <S.InputWrapper>
                                            <TextField
                                                name="ContactNumber"
                                                placeholder="Your contact number"
                                                onChange={setDeviceRequestField}
                                            />
                                        </S.InputWrapper>
                                        <S.InputWrapper>
                                            <TextField
                                                name="StreetAddress"
                                                placeholder="Street Address line 1"
                                                onChange={setDeviceRequestAddressField}
                                            />
                                        </S.InputWrapper>
                                        <S.InputWrapper>
                                            <TextField
                                                name="City"
                                                placeholder="City"
                                                onChange={setDeviceRequestAddressField}
                                            />
                                        </S.InputWrapper>
                                        <S.InputWrapper>
                                            <TextField
                                                name="State"
                                                placeholder="State"
                                                onChange={setDeviceRequestAddressField}
                                            />
                                        </S.InputWrapper>
                                        <S.InputWrapper>
                                            <TextField
                                                name="PostalCode"
                                                placeholder="Postcode / Zipcode"
                                                onChange={setDeviceRequestAddressField}
                                            />
                                        </S.InputWrapper>
                                        <S.InputWrapper>
                                            <Select
                                                fontSize={14}
                                                height={40}
                                                options={Object.entries(countries).map(([key, name]) => ({
                                                    value: key,
                                                    label: name
                                                }))}
                                                placeholder="Select country"
                                                defaultValue={{
                                                    value: projectTeamAssignment.CountryIso,
                                                    label: countries[projectTeamAssignment.CountryIso as keyof typeof countries]
                                                }}
                                                onValueChange={undefined}
                                                isDisabled
                                            />
                                        </S.InputWrapper>
                                    </>

                                    {error && <S.ErrorMessage>{error}</S.ErrorMessage>}

                                    <Button
                                        onClick={confirmDeviceSelection}
                                        disabled={isSubmittingDeviceRequirement || isGetDeviceRequirementTaskStatusFetching || isSubmittingDeviceRequirementSuccess}
                                        isLoading={isSubmittingDeviceRequirement || isGetDeviceRequirementTaskStatusFetching || isSubmittingDeviceRequirementSuccess}
                                    >
                                        Submit
                                    </Button>
                                </Column>
                            </Row>
                        </Container>
                    </S.ConfirmationSection>
                </Layout>
            )}
        </>
    );
};

export default DeviceRequirement;
