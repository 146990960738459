import styled from "styled-components";
import { ReactComponent as CopyIcon } from "../../../../../assets/icons/copy_default.svg";

const AccordionBody = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;    
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
`;

const ProjectMembershipTitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 10px;

    span {
        text-transform: capitalize;
    }

    button {
        height: 24px;
    }
`;

const ProjectMembershipCardCollection = styled.div`
    display: flex;
    gap: 10px;
    overflow-x: auto;
    overflow-y: hidden;
`;

const ProjectCardProjectName = styled.div`
    background-color: #82D9B7;
    text-transform: capitalize;
    border-radius: 4px;
    margin-top: 2px;
    padding: 2px 3px;
    flex-grow: 0;
    border: 1px solid #82D9B7;  
    align-self: flex-start;

    h5 {
        color: black;
        font-size: 12px;
    } 
`;

const ProjectAdminsLabel = styled.div`
    p {
        font-size: 12px;
        margin-top: 15px;
        margin-bottom: 4px;
    }
`;

const StyledCopyIcon = styled(CopyIcon)`
    background-color: transparent;
    cursor: pointer;

    path {
        fill: #5F6368;
    }
`;

const CopyEmailsLabel = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 25px;

    p {
        font-size: 12px;
        color: #9B9B9B;
    }
`;

const AvatarWrapper = styled.div`
    display: flex;
    gap: 7px;
    min-height: 35px;
`;

interface IProjectCardAvatarProps {
    colourOffet: number;
}

const ProjectCardAvatar = styled.div<IProjectCardAvatarProps>`
    background-color: ${(p) => {
        switch (p.colourOffet) {
            case 0: return "#F8F8F8";
            case 1: return "#BEE7D7";
            case 2: return "#E2A1DB";
            case 3: return "#A6B8F6";
            case 4: return "#50D2F0";
            case 5: return "#73C1FF";
            default: return "#F8F8F8";
        }
    }};

    border-radius: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    p {
        color: black;
        font-weight: bold;
        font-size: 12px;
    }    
`;

const NoProjectAdminsLabel = styled.div`
    p {
        font-size: 12px;
        font-weight: 200;
        color: #CCC;
    }    
`;

const DottedLine = styled.div`
    border-bottom: 1px dashed #5B5B5B;
    margin-top: 7px;
    margin-bottom: 5px;    
    width: 100%;
`;

const ProjectCard = styled.div`
    background-color: #323232;
    border-radius: 7px;
    padding: 10px;
    min-width: 280px;
    max-width: 280px;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5px;

    &:hover {
        background-color: #5A5A5A;

        ${ProjectCardProjectName} {            
            border: 1px solid white;
            background-color: #5A5A5A;            
            border-radius: 4px;

            h5 {
                color: white;
            }
        }        

        ${CopyEmailsLabel} {
            p {
                color: white !important;
            }        
        }

        ${DottedLine} {
            border-bottom: 1px dashed white;
        }

        ${StyledCopyIcon} {
            path {
                fill: white;
            }
        }
    }
`;

const NoOtherProjects = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 150px;
`;

const NoOtherProjectsTitle = styled.div`
    margin-left: auto;

    button {
        height: 24px;
    }    
`;

const NoOtherProjectsLabel = styled.div`
    align-content: center;    
    flex: 1;
    margin-bottom: 2em;

    p {
        color: #999;
        font-size: 14px;
        text-align: center;
    }
`;

export {
    AccordionBody,
    ProjectMembershipTitle,
    ProjectMembershipCardCollection,    
    ProjectCardProjectName,
    ProjectAdminsLabel,
    AvatarWrapper,
    ProjectCardAvatar,
    NoProjectAdminsLabel,
    DottedLine,
    CopyEmailsLabel,
    StyledCopyIcon,
    ProjectCard,
    NoOtherProjects,
    NoOtherProjectsTitle,
    NoOtherProjectsLabel
};
