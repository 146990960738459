/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from "axios";
import { useMutation, UseMutationResult } from "react-query";
import {
    IOffboardTeamMemberResponse,
    IOffboardTeamMemberRequest
} from "../types/offboardTeamMember";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { protectedResources } from "../auth/authConfig";

const createOffboardTeamMember = async (
    accessToken: string,
    request: IOffboardTeamMemberRequest,
    projectSlug: string
): Promise<IOffboardTeamMemberResponse> => {
    const response = await axios({
        method: "POST",
        url: `${protectedResources.wavecore.endpoint}/api/v2/OffboardTeamMembers/${projectSlug}`,
        data: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`
        }
    });

    return response.data;
};

const useCreateOffboardTeamMember = (
    projectSlug: string
): UseMutationResult<IOffboardTeamMemberResponse, unknown, IOffboardTeamMemberRequest, unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(
        protectedResources.wavecore.scopes
    );
    return useMutation((vars) =>
        getAccessToken().then((accessToken) => {
            if (accessToken && isAuthenticated) {
                return createOffboardTeamMember(accessToken, vars, projectSlug);
            }
            return Promise.reject();
        })
    );
};

export default useCreateOffboardTeamMember;
