import styled from "styled-components";
import { breakpoints } from "../grid/mediaQueries";

const Header = styled.header<{ hasBackgroundTransparent: boolean }>`
    font-weight: 500;
    padding: 24px 0;
    z-index: 30;
    transition: all 0.2s ease;
    background-color: ${({ hasBackgroundTransparent }) => (hasBackgroundTransparent ? "transparent" : "#000")};

    @media ${breakpoints.sm} {
        padding: 28px 0;
    } ;
`;

const HeaderRightColumnContent = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
`;

const HeaderBreakingWaveLogo = styled.img`
    height: 29px;
    margin-left: -13px;
`;

const AvatarWrapper = styled.div`
    position: relative;
`;

// Context menu styles that are only applicable to the header's avatar
const AvatarContextMenuWrapper = styled.div`
    left: -148px;
    margin-top: 10px;
    width: 195px;
    position: absolute;

    > div {
        width: 195px;

        /* this CS forms the triangles */
        &:after,
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 83%;
            width: 0;
            height: 0;
            border-style: solid;
        }

        /* triangle */
        &:after {
            top: -14px;
            border-color: transparent transparent #191718 transparent;
            border-width: 8px;
        }

        /* thin border */
        &:before {
            top: -16px;
            left: 160px;
            border-color: transparent transparent #989697 transparent;
            border-width: 8px;
        }
    }
`;

const AvatarContextMenuHeader = styled.div`
    padding: 16px 12px;
    color: white;
`;

const AvatarContextMenuText = styled.div`
    font-size: 16px;
`;

const AvatarContextMenuSubText = styled.div`
    font-size: 10px;
    color: rgba(255, 255, 255, 0.75);
    text-overflow: ellipsis;
`;

export {
    Header,
    HeaderRightColumnContent,
    HeaderBreakingWaveLogo,
    AvatarWrapper,
    AvatarContextMenuWrapper,
    AvatarContextMenuHeader,
    AvatarContextMenuText,
    AvatarContextMenuSubText
};
