import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
interface ISectionProps {
    children: React.ReactNode;
    classNames?: string[];
    style?: React.CSSProperties;
    omitDefaultColumns?: boolean;
}

/**
 * Props definitions of the Section component.
 * @param children - A React node that contains the content of a section.
 * @param [classNames] - An array of strings that are added to the sections class names
 * @param [style] - A prop that accepts inline styles to be passed in section component
 * @param [omitDefaultColumns] - A boolean to make children of a section component be rendered without default columns
 */
const Section: React.FC<ISectionProps> = ({
    children,
    classNames,
    style,
    omitDefaultColumns
}) => (
    <section className={cx("section", classNames)} style={style}>
        <div className="container">
            {!omitDefaultColumns ? (
                <div className="columns">
                    <div className="column is-10-tablet is-offset-1-tablet is-8-widescreen is-offset-2-widescreen">
                        {children}
                    </div>
                </div>
            ) : (
                children
            )}
        </div>
    </section>
);

Section.propTypes = {
    children: PropTypes.node.isRequired,
    classNames: PropTypes.array,
    style: PropTypes.object,
    omitDefaultColumns: PropTypes.bool
};

export default Section;
