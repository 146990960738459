import { useMemo } from "react";
import { ITaskGroupStatusResponse } from "../types/groupStatuses";
import { TaskGroupStatusesEnum } from "../types/shared";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useGroupStatusesCompletePercent = (taskGroupStatuses: ITaskGroupStatusResponse[]) => {

    const groupStatusPercentage = useMemo(() => {
        
        // The total percentage is the number of groups multiplied by 2
        // If a group is complete that's 2 points
        // If a group is inProgress OR failed it's 1 point
        const totalPercentage = taskGroupStatuses.length * 2;
        let points = 0;        

        points += taskGroupStatuses.filter((group) => group.Status === TaskGroupStatusesEnum.Complete).length * 2;
        points += taskGroupStatuses.filter((group) => group.Status === TaskGroupStatusesEnum.InProgress).length;
        points += taskGroupStatuses.filter((group) => group.Status === TaskGroupStatusesEnum.Failed).length;

        return (points / totalPercentage) * 100;
    }, [...taskGroupStatuses.map((status) => status.Status)]);

    return {
        groupStatusPercentage
    };
};

export default useGroupStatusesCompletePercent;
