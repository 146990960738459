export enum RevokeProjectAdminRightsWizardStepIdentifierEnum {
    Confirmation = "confirmation"
}

export interface IRevokeProjectAdminRightsWizardStepsState {
    [RevokeProjectAdminRightsWizardStepIdentifierEnum.Confirmation]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
    };
}
