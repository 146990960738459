import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { IFooterProps } from "../../../components/footer";
import { IHeaderProps } from "../../../components/header";
import { IHtmlHeadProps } from "../../../components/html-head";
import Layout from "../../../components/layout";
import Row from "../../../components/grid/Row";
import Column from "../../../components/grid/Column";
import dots from "../../../assets/images/mixed-space-dots-circular@3x.png";
import * as S from "./index.styles";

const ErrorPage: React.FC = () => {
    const htmlHeadProps: IHtmlHeadProps = {
        pageTitle: "Breaking Wave - An Error Occurred",
        indexPage: false
    };
    const headerProps: IHeaderProps = {
        showHideOnScroll: true,
        hasLoginButtonAndAvatar: true,
        isHeaderResponsive: true
    };

    const footerProps: IFooterProps = {
        hasFooterLinks: false,
        isFooterResponsive: false
    };

    const [searchParams] = useSearchParams();
    const [errorCode, setErrorCode] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        const { code, message } = Object.fromEntries([...searchParams]);

        setErrorCode(code);
        setErrorMessage(message);
    }, []);

    return (
        <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
            <S.StyledErrorContainer>
                <Row>
                    <Column md={{ size: 4, offset: 4 }}>
                        <img
                            src={dots}
                            alt="thank you page mixed space dots circular"
                            style={{ marginBottom: 16 }}
                        />
                    </Column>
                </Row>                   
                <S.StyledErrorTitle>An Error Occurred</S.StyledErrorTitle>
                {errorCode && <h3>Code: {errorCode}</h3>}
                {errorMessage && <p>{errorMessage}</p>}
                <Link to="/">&#8592; Go Home</Link>
            </S.StyledErrorContainer>
        </Layout>
    );
};

export default ErrorPage;
