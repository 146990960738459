import React from "react";
import TeamAccordion from "../../../../../../components/accordion/TeamAccordion";
import StepIndicatorCircle from "../../../../../../components/step-indicator-circle";
import { wizardColours } from "../wizard.colours";
import { UserTypeEnum } from "../../../../../../types/shared";
import JoinProjectWizardSteps from "./steps";
import { JoinProjectWizardStepsContext, reducer } from "./_state/context";
import initialJoinProjectWizardStepsState from "./_state/initialState";
import { JoinProjectWizardStepIdentifierEnum } from "./_state/types";
import * as S from "../wizard.styles";

/**
 * JoinProject mode of <TeamWizards />.
 */
const JoinProjectWizard: React.FC = () => {
    const [state, dispatch] = React.useReducer(reducer, initialJoinProjectWizardStepsState);

    const percentage = (): number => {
        const isAnsweredArray = Object.values(state)
            .filter((step) => step.order !== undefined)
            .map((step) => step.isStepAnswered);
        return (isAnsweredArray.filter((val) => val).length / isAnsweredArray.length) * 100;
    }; 

    React.useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === "local") {
            // ---- console log join project wizard steps state only in dev env ---- //
            console.log("Join projects wizard steps state: ", state);
        }
    }, [state]);

    const team = state[JoinProjectWizardStepIdentifierEnum.SearchedMemberData].answer.team;

    return (
        <JoinProjectWizardStepsContext.Provider value={{ state, dispatch }}>
            <TeamAccordion
                profileProgressCircleProps={{
                    size: 30,
                    percentage: percentage(),
                    userType: UserTypeEnum.User,
                    percentagePathColour: wizardColours.memberIcon
                }}
                isToggleable={false}
                hasToggleIcon={false}
                defaultOpenState={true}
                homeProjectName={state[JoinProjectWizardStepIdentifierEnum.SearchedMemberData].answer.homeProject.displayValue ?? "-"}
                teamMemberDisplayName={
                    state[JoinProjectWizardStepIdentifierEnum.SearchedMemberData].answer.personalDetails.displayValue
                }
                team={team}
                country={state[JoinProjectWizardStepIdentifierEnum.SearchedMemberData].answer.country.displayValue}
                deviceType={state[JoinProjectWizardStepIdentifierEnum.SearchedMemberData].answer.deviceType.deviceType}
                startDate={state[JoinProjectWizardStepIdentifierEnum.SearchedMemberData].answer.startDate.displayValue}
                rate={state[JoinProjectWizardStepIdentifierEnum.SearchedMemberData].answer.rate.displayValue}
            >
                <S.TeamAccordionBody>
                    <S.StepIndicatorCirclesWrapper>
                        {Object.values(state)
                            .filter((step) => step.order !== undefined)
                            .sort((a, b) => a.order - b.order)
                            .map((step, index) => (
                                <StepIndicatorCircle
                                    key={index}
                                    value={index + 1}
                                    active={step.isStepActive}
                                    completed={!step.isStepActive && step.isStepAnswered}
                                    disabled={!step.isStepActive && !step.isStepAnswered}
                                    style={{ marginRight: 8 }}
                                />
                            ))}
                    </S.StepIndicatorCirclesWrapper>
                    <JoinProjectWizardSteps />
                </S.TeamAccordionBody>
            </TeamAccordion>
        </JoinProjectWizardStepsContext.Provider>
    );
};

export default JoinProjectWizard;
