import { DeviceTypeEnum } from "../types/shared";

const getFriendlyDeviceName = (deviceType: DeviceTypeEnum): string => {
    switch (deviceType) {
        case DeviceTypeEnum.Laptop: return "Laptop";
        case DeviceTypeEnum.VM: return "Virtual Machine";
        case DeviceTypeEnum.NoDeviceRequired: return "No Device Required";
        default: 
            return "Unknown";
    }
};

export default getFriendlyDeviceName;
