import { breakpointKeys } from "./breakpoints";

export interface IMediaQueries {
    [key: string]: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
}

export const breakpoints: IMediaQueries = {
    sm: `(min-width: ${breakpointKeys.sm}px)`,
    md: `(min-width: ${breakpointKeys.md}px)`,
    lg: `(min-width: ${breakpointKeys.lg}px)`,
    xl: `(min-width: ${breakpointKeys.xl}px)`,
    xxl: `(min-width: ${breakpointKeys.xxl}px)`
};
