import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useMsal } from "@azure/msal-react";
import * as S from "./Avatar.styles";

export interface IAvatarProps {
    onClick?(): void;
}

/**
 * Avatar component.
 * @param [onClick] - callback that is fired when the user clicks the avatar.
 */
const Avatar: React.FC<IAvatarProps> = ({ onClick }) => {
    const { accounts } = useMsal();

    const userInitials = useMemo(() => {
        if (accounts) {
            const localUserName = accounts[0]?.name?.trim();

            if (!localUserName) {
                return undefined;
            }

            const parts = localUserName.split(" ");
            if (parts.length > 1) {
                return `${parts[0][0]}${parts[parts.length - 1][0]}`;
            }

            return parts[0][0];
        }
        return "";
    }, [accounts]);

    return (
        <S.Avatar onClick={onClick}>
            <S.AvatarRing>
                <S.AvatarContent>
                    {userInitials ?? (
                        <img
                            src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/icons/member_icon_white.svg`}
                            style={{ maxHeight: 45 }}
                            alt="user logo"
                        />
                    )}
                </S.AvatarContent>
            </S.AvatarRing>
        </S.Avatar>
    );
};

Avatar.propTypes = {
    onClick: PropTypes.func
};

export default Avatar;
