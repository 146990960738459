import axios from "axios";
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query";
import { protectedResources } from "../auth/authConfig";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { ICreateJoinProject, IJoinProjectResponse } from "../types/joinProject";

const createJoinProject = async (
    accessToken: string,
    payload: ICreateJoinProject,
    slug: string
): Promise<IJoinProjectResponse> => {
    const url = `${protectedResources.wavecore.endpoint}/api/v2/JoinProjects/${slug}`;
    const response = await axios({
        method: "POST",
        url: url,
        data: payload,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }     
    });

    return response.data;
};

const useCreateJoinProject = (
    slug: string,
    options?: UseMutationOptions<IJoinProjectResponse, unknown, ICreateJoinProject, unknown>
): UseMutationResult<IJoinProjectResponse, unknown, ICreateJoinProject, unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);
    return useMutation((vars) => 
        getAccessToken().then((accessToken) => {
            if (accessToken && isAuthenticated) {
                return createJoinProject(accessToken, vars, slug);
            }
            return Promise.reject();
        }),
    {
        ...options
    }
    );
};

export default useCreateJoinProject;
