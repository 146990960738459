/* eslint-disable @typescript-eslint/naming-convention */
export enum TeamAssignmentWizardStepsActionTypesEnum {
    LOAD_NEXT_STEP = "LOAD_NEXT_STEP",
    LOAD_PREV_STEP = "LOAD_PREV_STEP",
    SET_TEAM_MEMBER_NAME = "SET_TEAM_MEMBER_NAME",
    SET_SUBSCRIPTION_TYPE = "SET_SUBSCRIPTION_TYPE",
    SET_DAILY_RATE = "SET_DAILY_RATE",
    SET_COUNTRY = "SET_COUNTRY",
    SET_START_DATE = "SET_START_DATE",
    SET_END_DATE = "SET_END_DATE",
    SET_DEVICE_SELECTION = "SET_DEVICE_SELECTION",
    SET_TEAM_SELECTION = "SET_TEAM_SELECTION",
    SET_CONFIRMATION = "SET_CONFIRMATION"
}
