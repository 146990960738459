import { IHtmlHeadProps } from "../../../components/html-head";
import { IHeaderProps } from "../../../components/header";
import { IFooterProps } from "../../../components/footer";

const htmlHeadProps: IHtmlHeadProps = {
    pageTitle: "Onboarding Approval | Breaking Wave",
    description:
        "We are a newly created Deutsche Bank company building a digital future for the bank and its customers",
    indexPage: false
};

const headerProps: IHeaderProps = {
    showHideOnScroll: false,
    hasLoginButtonAndAvatar: false,
    isHeaderResponsive: true
};

const footerProps: IFooterProps = {
    hasFooterLinks: false,
    isFooterResponsive: true
};

export { htmlHeadProps, headerProps, footerProps };
