import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { Button, DatePicker, FormControl, FormErrorText, Text, Tick } from "@breakingwave/react-ui-components";
import { useTeamAssignmentStepsContext } from "../_state/context";
import { TeamAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import { TeamAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { StartDateStepInputs, startDateStepSchema } from "../index.schema";
import * as S from "../../wizard.styles";

const StartDate: React.FC = () => {
    const { state, dispatch } = useTeamAssignmentStepsContext();

    // Fully typed, shorter spelling of the step name for easy access
    const step = state[TeamAssignmentWizardStepIdentifierEnum.StartDate];

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<StartDateStepInputs>({
        resolver: yupResolver(startDateStepSchema),
        defaultValues: {
            startDate: new Date(step.answer.isoString)
        }
    });

    const processAndGoToNext = (data: FieldValues): void => {
        const date: Dayjs = data.startDate;

        // Converts the input to an ISO 8601 formatted date and time string
        const endDateIsoDateTimeString = `${dayjs(date).format("YYYY-MM-DDTHH:mm:ss")}Z`;

        const displayValue = dayjs(date).format("DD MMM YYYY");

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.SET_START_DATE,
            payload: {
                ...step,
                answer: {
                    startDate: date,
                    isoString: endDateIsoDateTimeString,
                    displayValue
                }
            }
        });

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP
        });
    };

    const goBack = (): void => {
        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP
        });
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={500}>
                    When would you like them to start?
                </Text>
            </S.QuestionWrapper>
            <S.AnswerWrapper>
                <FormControl as="fieldset" style={{ width: 320 }}>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { onChange, value, ...restField } }) => (
                            <DatePicker
                                {...restField}
                                labelText="Date"
                                value={((value as unknown) as Dayjs) ?? null}
                                onChange={(event) => {
                                    onChange(event);
                                }}
                                disablePast
                                error={!!errors.startDate}
                            />
                        )}
                    />
                    {errors.startDate && <FormErrorText>{errors.startDate.message}</FormErrorText>}
                </FormControl>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button variant="secondary" onClick={goBack}>
                    Back
                </Button>
                <Button iconSvg={<Tick />} iconSize={9} onClick={handleSubmit(processAndGoToNext)}>
                    Next
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default StartDate;
