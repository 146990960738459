import React from "react";
import PropTypes from "prop-types";
import HtmlHead, { IHtmlHeadProps } from "../html-head";
import Header, { IHeaderProps } from "../header";
import Footer, { IFooterProps } from "../footer";
import CookieBanner from "../cookie-banner";

interface ILayoutProps {
    children: React.ReactNode;
    hasBodyBackgroundGradient?: boolean; // TODO: Ideally, turn this prop into an object i.e: pageProps
    hasBodyBackgroundGray?: boolean; // TODO: Ideally, turn this prop into an object i.e: pageProps
    htmlHeadProps: IHtmlHeadProps;
    headerProps: IHeaderProps;
    footerProps: IFooterProps;
}

/**
 * This component constructs the shared HTML layout of every page. Use below props to customize this initialization.
 * @param children - A React node that contains the main content of an HTML layout. It is rendered after the <Header /> and before the Footer
 * @param [hasBodyBackgroundGradient] - A boolean prop that makes the page background gradient.
 * @param [hasBodyBackgroundGray] - A boolean prop that makes the page background dark gray.
 * @param [htmlHeadProps] - An object used to configure the page-level HTML metadata. The properties (props) in it are passed down to the HtmlHead component.
 * @param [headerProps] - An object used to configure the page header. The properties (props) in it are passed down to the Header component.
 * @param [footerProps] - An object used to configure the page footer. The properties (props) in it are passed down to the Footer component.
 */
const Layout: React.FC<ILayoutProps> = ({
    children,
    hasBodyBackgroundGradient,
    hasBodyBackgroundGray,
    htmlHeadProps,
    headerProps,
    footerProps,
    ...props
}) => {
    React.useEffect(() => {
        if (hasBodyBackgroundGradient) document.body.className = "has-background-gradient";
        else if (hasBodyBackgroundGray) document.body.className = "has-background-gray";

        return (): void => {
            document.body.className = "";
        };
    }, [hasBodyBackgroundGradient]);

    return (
        <>
            <HtmlHead {...htmlHeadProps} />
            <Header {...headerProps} />
            <div role="main" id="main" {...props}>
                {children}
            </div>
            <Footer {...footerProps} />
            <CookieBanner />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    hasBodyBackgroundGradient: PropTypes.bool,
    hasBodyBackgroundGray: PropTypes.bool,
    htmlHeadProps: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        description: PropTypes.string,
        indexPage: PropTypes.bool.isRequired
    }).isRequired,
    headerProps: PropTypes.shape({
        showHideOnScroll: PropTypes.bool.isRequired,
        hasLoginButtonAndAvatar: PropTypes.bool.isRequired,
        isHeaderResponsive: PropTypes.bool.isRequired
    }).isRequired,
    footerProps: PropTypes.shape({
        hasFooterLinks: PropTypes.bool.isRequired,
        isFooterResponsive: PropTypes.bool.isRequired
    }).isRequired
};

export default Layout;
