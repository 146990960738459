import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { protectedResources } from "../../../auth/authConfig";
import Spinner from "../../../components/spinner";
import * as S from "./Callback.styles";

const Login: React.FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    const navigate = useNavigate();

    useEffect(() => {
        instance.handleRedirectPromise().then(() => {
            if (isAuthenticated) {
                console.log("Navigate to dashboard.");
                navigate("/");
            } else {
                console.log("loginRedirect.");
                instance.loginRedirect(protectedResources.graphMe).catch((e) => {
                    console.log(e);
                });
            }
        });
    }, []);

    return (
        <S.LoadingContainer>
            <Spinner size={4} padding={"20px"} />
            <h1>Please wait...</h1>
        </S.LoadingContainer>
    );
};

export default Login;
