import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { protectedResources } from "../auth/authConfig";
import { IOnboardingStatusResponse } from "../types/shared";
import { QueryKeysEnum } from "../types/queryKeys";
import useAadAccessToken from "../hooks/useAadAccessToken";

const getOnboardingStatus = async (accessToken: string): Promise<IOnboardingStatusResponse> => {
    const url = `${protectedResources.wavecore.endpoint}/api/OnboardingStatus`;
    const response = await axios({
        method: "GET",
        url: url,
        headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
            authorization: `Bearer ${accessToken}`
        }        
    });

    return response.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useGetOnboardingStatus = (
    options?: UseQueryOptions<IOnboardingStatusResponse, unknown, IOnboardingStatusResponse, QueryKeysEnum[]>
) => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(
        protectedResources.wavecore.scopes
    );
    return useQuery(
        [QueryKeysEnum.onboardingStatus],
        () => getAccessToken().then((accessToken) => 
            accessToken && isAuthenticated ?
                getOnboardingStatus(accessToken).then((onboardingStatusResponse) => onboardingStatusResponse) : Promise.reject()
        ), {
            ...options
        }
    );
};

export default useGetOnboardingStatus;
