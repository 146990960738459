import React, { useEffect } from "react";
import { useLeaveProjectWizardStepsContext } from "../_state/context";
import { LeaveProjectWizardStepIdentifierEnum, ILeaveProjectWizardStepsState } from "../_state/types";
import Confirmation from "./Confirmation";
import * as S from "../../wizard.styles";

const LeaveProjectWizardSteps: React.FC = () => {
    const { state } = useLeaveProjectWizardStepsContext();

    const [activeStep, setActiveStep] = React.useState(Object.values(state).find((f) => f.order === 1).identifier);

    useEffect(() => {
        const activeStepFromLeaveProjectWizard = Object.keys(state).find(
            (f) => state[f as keyof ILeaveProjectWizardStepsState].isStepActive
        );
        setActiveStep(activeStepFromLeaveProjectWizard);
    }, [state]);

    return (
        <S.Form autoComplete="off">
            {activeStep === LeaveProjectWizardStepIdentifierEnum.Confirmation && <Confirmation />}
        </S.Form>
    );
};

export default LeaveProjectWizardSteps;
