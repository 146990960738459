import React, { useMemo } from "react";
import { Button, Cancel, FormControl, Select } from "@breakingwave/react-ui-components";
import { useProjectPageContext } from "../../_state/context";
import { ProjectPageActionTypesEnum } from "../../_state/actions";
import { IOption } from "../../../../../types/selectOption";
import useUserRoles from "../../../../../hooks/useUserRoles";
import { ProjectTypeEnum } from "../../../../../types/shared";

enum TeamMemberDropDownOptions {
    NewTeamMember = "new-team-member",
    JoinExistingTeamMember = "join-existing-team-member",
}

const AddOrCreateTeamWizardsDropdown: React.FC = () => {
    const userRole = useUserRoles();

    const {
        state: {
            selectedProject,
            teamAssignmentWizard: { isActive: isTeamAssignmentWizardActive },
            joinProjectWizard: { isActive: isJoinProjectWizardActive },
            projectAdminAssignmentWizard: { isActive: isProjectAdminWizardActive }
        },
        dispatch: projectPageDispatch
    } = useProjectPageContext();

    const options = useMemo(() => {        
        const options = [];

        if (selectedProject.ProjectType === ProjectTypeEnum.Home) {
            options.push({ 
                label: "Someone new",
                value: TeamMemberDropDownOptions.NewTeamMember
            });
        }
                
        if (selectedProject.ProjectType === ProjectTypeEnum.Working) {
            options.push({ 
                label: "Someone already in Wave",
                value: TeamMemberDropDownOptions.JoinExistingTeamMember
            });
        }

        return options;
    }, [selectedProject, userRole]);

    const activateTeamAssignmentWizard = (): void => {
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.SET_TEAM_ASSIGNMENT_WIZARD,
            payload: { 
                isActive: true,
                isCompleted: false,
                tempTeamAssignmentStepsData: undefined
            }
        });
    };

    const activateJoinProjectWizard = (): void => {
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.SET_JOIN_PROJECT_WIZARD,
            payload: {
                isActive: true,
                isCompleted: false,
                tempJoinProjectStepsData: undefined,
                lastSavedJoinUPN: undefined
            }
        });
    };

    const cancelWizards = (): void => {
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.CANCEL_PAGE_LEVEL_WIZARDS
        });
    };

    return !isTeamAssignmentWizardActive && !isJoinProjectWizardActive && !isProjectAdminWizardActive ? (
        <FormControl as="fieldset" style={{ width: 208, marginLeft: "auto" }}>
            <Select
                name="setSubscription"
                variant="button"
                options={options}
                placeholder="Add new team member"
                onValueChange={(option: IOption) => {
                    switch (option.value) {
                        case TeamMemberDropDownOptions.NewTeamMember: {
                            activateTeamAssignmentWizard();
                            break;
                        }

                        case TeamMemberDropDownOptions.JoinExistingTeamMember: {
                            activateJoinProjectWizard();
                            break;
                        }                    
                    }                    
                }}
            />
        </FormControl>
    ) : (
        <Button variant="secondary" iconSize={8.5} iconSvg={<Cancel />} onClick={cancelWizards}>
            Cancel request
        </Button>
    );
};

export default AddOrCreateTeamWizardsDropdown;
