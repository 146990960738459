import { CreateProjectAdminAssignmentDomainValidationFailureReasonEnum } from "../types/projectAdminAssignment";

const projectAdminBlockerReason = (bitwise: number): string => {

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.ProjectNotFound) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.ProjectNotFound) {
        return "Project not found";
    }

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) {
        return "You do not have permission to add this user";
    }

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) {
        return "Invalid email domain, does not match project domain";
    }

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.AlreadyProjectAdminOfThisProject) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.AlreadyProjectAdminOfThisProject) {
        return "User is already an admin of this project";
    }

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.ProvidedUpnDidNotReturnUserButContactEmailDid) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.ProvidedUpnDidNotReturnUserButContactEmailDid) {
        return "New Wave user, contact email address already exists";
    }

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.ContactEmailsDontMatch) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.ContactEmailsDontMatch) {
        return "Contact email address does not match existing team member";
    }

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightProjectTeamAssignments) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightProjectTeamAssignments) {
        return "User is already onboarding";
    }

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightProjectAdminAssignments) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightProjectAdminAssignments) {
        return "Admin assignment already in progress";
    }    

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightJoinProjects) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightJoinProjects) {
        return "User is already joining a project";
    }

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightLeaveProjects) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightLeaveProjects) {
        return "User is already leaving a project";
    }

    if ((bitwise & CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightOffboardings) === 
            CreateProjectAdminAssignmentDomainValidationFailureReasonEnum.InFlightOffboardings) {
        return "Team member is currently being offboarded";
    }        

    return "Unknown error occurred";
};

export {
    projectAdminBlockerReason
};
