import styled from "styled-components";

const BodySection = styled.section`
    text-align: center;
`;

const LoadingSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    div:first-child {
        height: auto;
    }
`;

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    h1 {
        font-size: 1.5em;
    }
`;

export { BodySection, LoadingSection, ErrorWrapper };
