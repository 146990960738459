/**
 * Query keys for all react-query queries.
 * Those should be used for invalidating queries, too.
 */
export enum QueryKeysEnum {
    projectCatalogue = "PROJECT_CATALOGUE",
    teamMemberDisplayItems = "TEAM_MEMBER_DISPLAY_ITEMS",
    onboardingContextValidation = "ONBOARDING_CONTEXT_VALIDATION",
    projectForApproval = "PROJECT_FOR_APPROVAL",
    projectTeamAssignmentApproval = "PROJECT_TEAM_ASSIGNMENT_APPROVAL",
    joinProjectMemberEligibility = "JOIN_PROJECT_MEMBER_ELIGIBILITY",
    joinProjectMemberInfo = "JOIN_PROJECT_MEMBER_INFO",
    projectMembership = "PROJECT_MEMBERSHIP",
    genericRequestDisplayItems = "GENERIC_REQUEST_DISPLAY_ITEMS",
    genericRequestTypes = "GENERIC_REQUEST_TYPES",
    onboardingStatus = "ONBOARDING_STATUS",
}
