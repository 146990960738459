export enum LeaveProjectWizardStepIdentifierEnum {
    Confirmation = "confirmation"
}

export interface ILeaveProjectWizardStepsState {
    [LeaveProjectWizardStepIdentifierEnum.Confirmation]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
    };
}
