import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Text, Heading } from "@breakingwave/react-ui-components";
import Layout from "../../../components/layout";
import { IMFAState, ManagedProcessResourceTypeEnum, TaskItemStatusEnum } from "../../../types/shared";
import { ProjectTeamAssignmentTaskAutomationKeyEnum } from "../../../types/onboarding";
import useNotifyAutomationService from "../../../api/notifyAutomationService";
import Spinner from "../../../components/spinner";
import Container from "../../../components/grid/Container";
import Row from "../../../components/grid/Row";
import dots from "../../../assets/images/mixed-space-dots-circular@3x.png";
import Column from "../../../components/grid/Column";
import { ITeamAssignmentResponse } from "../../../types/teamAssignment";
import useGetManagedProcessTaskStatus from "../../../api/getManagedProcessTaskStatus";
import useGetManagedProcessByAccessToken from "../../../api/getManagedProcessByAccessToken";
import { footerProps, headerProps, htmlHeadProps } from "./AccountSetupComplete.config";
import * as S from "./AccountSetupComplete.styles";

const AccountSetupCompletePage: React.FC = () => {
    const [searchParams] = useSearchParams();

    const [mfaState, setMfaState] = useState<IMFAState>();
    const [taskComplete, setTaskComplete] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        const { id, p, t } = Object.fromEntries([...searchParams]); 

        if (id && p && t) {
            const state: IMFAState = {
                Id: id,
                ProjectId: p,
                AccessToken: t
            };
            
            setMfaState(state);
        }
    }, []);

    const {
        data: projectTeamAssignment,
        isFetching: isLoadingProjectTeamAssignment,
        isSuccess: loadProjectTeamAssignmentSuccess
    } = useGetManagedProcessByAccessToken<ITeamAssignmentResponse>(
        {
            Id: mfaState?.Id,
            ProjectSlugOrProjectId: mfaState?.ProjectId,
            ResourceType: ManagedProcessResourceTypeEnum.ProjectTeamAssignment
        },
        ProjectTeamAssignmentTaskAutomationKeyEnum.USER_HAS_CREATED_PASSWORD_AND_SETUP_MFA, 
        mfaState?.AccessToken,
        {
            enabled: 
                !!mfaState?.AccessToken && 
                !!mfaState?.Id && 
                !!mfaState?.ProjectId && 
                !errorMessage && 
                !taskComplete,
            cacheTime: 0,
            refetchInterval: 5000
        }
    );    

    const projectSlug = useMemo(() => projectTeamAssignment?.ProjectSlug ?? "",[projectTeamAssignment]);      

    const {
        isLoading: isSubmitting,
        mutateAsync: submit,
        isSuccess: isSubmitSuccess
    } = useNotifyAutomationService(
        {
            Id: mfaState?.Id,
            ProjectSlugOrProjectId: mfaState?.ProjectId,
            ResourceType: ManagedProcessResourceTypeEnum.ProjectTeamAssignment
        },
        projectSlug,
        mfaState?.AccessToken, 
        ProjectTeamAssignmentTaskAutomationKeyEnum.USER_HAS_CREATED_PASSWORD_AND_SETUP_MFA
    );

    const {
        isFetching: isGetTaskStatusFetching,
        data: taskStatusResult
    } = useGetManagedProcessTaskStatus(
        {
            Id: mfaState?.Id,
            ProjectSlugOrProjectId: projectSlug,
            ResourceType: ManagedProcessResourceTypeEnum.ProjectTeamAssignment
        },
        projectSlug,
        ProjectTeamAssignmentTaskAutomationKeyEnum.USER_HAS_CREATED_PASSWORD_AND_SETUP_MFA,
        mfaState?.AccessToken, 
        {
            enabled: !!mfaState?.Id && 
                     !!projectSlug && 
                     !!mfaState?.AccessToken && 
                     loadProjectTeamAssignmentSuccess &&
                     !taskComplete &&
                     !errorMessage,
            refetchInterval: 2500
        }
    );

    useEffect(() => {
        // Spin until the USER_HAS_CREATED_PASSWORD_AND_SETUP_MFA task is complete
        if (taskStatusResult?.TaskStatus === TaskItemStatusEnum.Complete) {
            setTaskComplete(true);
            return;
        }

        if (taskStatusResult?.TaskStatus !== TaskItemStatusEnum.Pending) {
            console.log(`USER_HAS_CREATED_PASSWORD_AND_SETUP_MFA is not Pending.  Task Status: ${taskStatusResult?.TaskStatus}`);
            return;
        }

        if (isSubmitSuccess) {
            console.log("Successfully submitted task USER_HAS_CREATED_PASSWORD_AND_SETUP_MFA");
            return;
        }

        console.log("Setting USER_HAS_CREATED_PASSWORD_AND_SETUP_MFA to complete...");
        submit(null);

    }, [isSubmitSuccess, taskStatusResult]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (!taskComplete) {
                setErrorMessage("This link may have expired.");
            }
        }, 60000);

        return (() => {
            clearTimeout(timeOut);
        });
    }, [taskComplete]);

    return <>
        {(isLoadingProjectTeamAssignment || isGetTaskStatusFetching || !taskComplete) && !isSubmitting && !errorMessage &&
            <S.LoadingSection>
                <Spinner padding="20px" />
                <h1>
                    Please wait.
                </h1>
            </S.LoadingSection>
        }   

        {isSubmitting && 
            <S.LoadingSection>
                <Spinner padding="20px" />
                <h1>
                    We&apos;re just finishing up
                </h1>                
            </S.LoadingSection>
        }

        {errorMessage &&
            <S.ErrorWrapper>
                <h1>
                    An error has occurred whilst determining your onboarding status
                </h1>
                <Text variant="p">
                    {errorMessage}
                </Text>
            </S.ErrorWrapper>
        }        

        {taskComplete && !errorMessage &&
        <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>    
            <S.BodySection>
                <Container isResponsive={true}>
                    <Row>
                        <Column md={{ size: 4, offset: 4 }}>
                            <img
                                src={dots}
                                alt="thank you page mixed space dots circular"
                                style={{ marginBottom: 16 }}
                            />
                        </Column>
                    </Row>                
                    <Heading variant="h5" style={{ marginBottom: 16 }}>
                        Thank you
                    </Heading>                 
                    <Text variant="p">
                        You have now created your password and confirmed your Multi Factor Authentication method. 
                    </Text>    
                    <Text variant="p">
                        You will receive a welcome email once we have finished setting you up.
                    </Text>
                </Container>            
            </S.BodySection>
        </Layout>       
        }
    </>;
};

export default AccountSetupCompletePage;
