import axios from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { protectedResources } from "../auth/authConfig";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { QueryKeysEnum } from "../types/queryKeys";
import { IProjectTeamMemberDisplayItemsResponse } from "../types/teamMemberDisplayItems";

const getProjectTeamMemberByUpn = async (
    accessToken: string,
    upn: string
): Promise<IProjectTeamMemberDisplayItemsResponse> => {
    const url = `${protectedResources.wavecore.endpoint}/api/ProjectTeamMember/${upn}`;
    const response = await axios({
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    return response.data;
};

const useGetProjectTeamMemberByUpn = (
    upn: string,
    options?: UseQueryOptions<IProjectTeamMemberDisplayItemsResponse, unknown, IProjectTeamMemberDisplayItemsResponse, string[]>
): UseQueryResult<IProjectTeamMemberDisplayItemsResponse, unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);
    return useQuery(
        [QueryKeysEnum.joinProjectMemberInfo, upn],
        () => 
            getAccessToken().then((accessToken) => {
                if (accessToken && isAuthenticated) {
                    return getProjectTeamMemberByUpn(accessToken, upn);
                }
                return Promise.reject();
            }),
        {
            ...options
        }
    );
};

export default useGetProjectTeamMemberByUpn;
