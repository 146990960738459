import React from "react";
import PropTypes from "prop-types";
import { TaskGroupStatusesEnum } from "../../types/shared";
import * as S from "./GroupStatusIcon.styles";

export interface IGroupStatusIconProps {
    size?: number;
    status: TaskGroupStatusesEnum;
    color: string;
}

/**
 * Group status icon component with multiple statuses.
 * @param {number} [size] - Sets the size of the component. This numeric prop is applied to the width and height of the component wrapper. Default is 30px.
 * @param {string} status - Enum type TaskGroupStatusesEnum
 * @param {string} color - Expects a HEX string
 */
const GroupStatusIcon: React.FC<IGroupStatusIconProps> = ({ size, status, color }) => (
    <S.GroupStatusIconContainer size={size} status={status} color={color}>
        <S.StyledStatusRing />
        {status === TaskGroupStatusesEnum.InProgress && <S.StyledStatusInProgress />}
        {status === TaskGroupStatusesEnum.Complete && <S.StyledStatusCompleteTick />}
        {/* TODO: "failed" case has no special icons for now. Therefore, we are rendering the ring as red. */}
    </S.GroupStatusIconContainer>
);

GroupStatusIcon.propTypes = {
    size: PropTypes.number,
    status: PropTypes.oneOf(Object.values(TaskGroupStatusesEnum)).isRequired,
    color: PropTypes.string.isRequired
};

export default GroupStatusIcon;
