/* eslint-disable @typescript-eslint/naming-convention */
export enum ProjectPageActionTypesEnum {
    SET_PROJECT = "SET_PROJECT",
    SET_TEAM_ASSIGNMENT_WIZARD = "SET_TEAM_ASSIGNMENT_WIZARD",
    SET_JOIN_PROJECT_WIZARD = "SET_JOIN_PROJECT_WIZARD",
    SET_LEAVE_PROJECT_WIZARD = "SET_LEAVE_PROJECT_WIZARD",
    SET_OFFBOARD_TEAM_MEMBER_WIZARD = "SET_OFFBOARD_TEAM_MEMBER_WIZARD",
    SET_PROJECT_ADMIN_ASSIGNMENT_WIZARD = "SET_PROJECT_ADMIN_ASSIGNMENT_WIZARD",
    SET_REVOKE_ADMIN_RIGHTS_WIZARD = "SET_REVOKE_ADMIN_RIGHTS_WIZARD",
    CANCEL_PAGE_LEVEL_WIZARDS = "CANCEL_PAGE_LEVEL_WIZARDS"
}
