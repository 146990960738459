import axios from "axios";
import { IProjectTeamAssignmentErrorResponse, IValidateProjectTeamAssignmentRequest } from "../types/teamAssignment";
import { protectedResources } from "../auth/authConfig";

const validateProjectTeamAssignment = async (
    projectSlug: string, 
    payload: IValidateProjectTeamAssignmentRequest,
    accessToken: string): Promise<IProjectTeamAssignmentErrorResponse> => {
    const url = `${protectedResources.wavecore.endpoint}/api/ValidateProjectTeamAssignment/${projectSlug}`;

    const response = await axios({
        method: "POST",
        url: url,
        data: JSON.stringify(payload),
        headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
            authorization: `Bearer ${accessToken}`
        }
    });

    return response.data;
};

export default validateProjectTeamAssignment;
