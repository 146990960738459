import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { Text, Button, Select, Tick, FormControl, FormErrorText } from "@breakingwave/react-ui-components";
import { useJoinProjectWizardStepsContext } from "../_state/context";
import { JoinProjectWizardStepsActionTypesEnum } from "../_state/actions";
import { JoinProjectWizardStepIdentifierEnum } from "../_state/types";
import { searchMemberStepSchema, SearchMemberStepInputs } from "../index.schema";
import { RateTypeEnum } from "../../../../../../../types/shared";
import { useProjectPageContext } from "../../../../_state/context";
import Spinner from "../../../../../../../components/spinner";
import { IOption } from "../../../../../../../types/selectOption";
import { joinProjectMemberBlockerReason } from "../../../../../../../utils/joinProjectMemberBlockerReason";
import formatRateDisplay from "../../../../../../../utils/formatRateDisplay";
import useGetJoinProjectMemberEligibility from "../../../../../../../api/getJoinProjectMemberEligibility";
import useGetProjectTeamMemberByUpn from "../../../../../../../api/getProjectTeamMemberByUpn";
import * as S from "../../wizard.styles";

const SearchMember: React.FC = () => {    

    const { 
        state: {
            selectedProject: { 
                ProjectSlug
            }
        }
    } = useProjectPageContext();

    const { state, dispatch } = useJoinProjectWizardStepsContext();

    // Fully typed, shorter spelling of the step name for easy access
    const step = state[JoinProjectWizardStepIdentifierEnum.SearchedMemberData];

    const [memberOptions, setMemberOptions] = useState<IOption[]>([]);
    const [upn, setUpn] = useState<string>("");

    const {
        isFetching: isMemberEligibilityLoading,        
        refetch: loadJoinProjectMemberEligibility,
        data: members
    } = useGetJoinProjectMemberEligibility(ProjectSlug, {
        enabled: false
    });

    const {
        isLoading: isGetProjectTeamMemberByUpnLoading,
        refetch: getProjectTeamMemberByUpn,
        data: projectTeamMemberResponse
    } = useGetProjectTeamMemberByUpn(upn, {
        enabled: !!upn
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues
    } = useForm<SearchMemberStepInputs>({
        resolver: yupResolver(searchMemberStepSchema),
        defaultValues: {
            searchMember: {
                label: step.answer.searchMemberLabel,
                value: step.answer.searchMemberValue
            }
        }
    });
    
    useEffect(() => {
        loadJoinProjectMemberEligibility();
    }, []);    

    useEffect(() => {
        if (members) {
            const options = members
                .map((member) => {
                    let label = `${member.FirstName} ${member.LastName} (${member.ContactEmailAddress})`;
                    if (!member.IsEligible) {
                        label += ` (${joinProjectMemberBlockerReason(member.Blocker)})`;
                    }
                    // TODO: Fix bw-ui-components build and add 'isDisabled' to the IOptions interface
                    const option: IOption & any = {
                        value: `${member.UserPrincipalName} ${member.ContactEmailAddress}`,
                        label: label,
                        isDisabled: !member.IsEligible
                    };
                    return option;
                })
                .sort((a, b) => (a.label?.toLowerCase() > b.label?.toLowerCase()) ? 1 : ((b.label?.toLowerCase() > a.label?.toLowerCase()) ? -1 : 0));

            setMemberOptions(options);
        }
    }, [members]);

    useEffect(() => {
        if (upn) {
            getProjectTeamMemberByUpn();            
        }
    }, [upn]);

    useEffect(() => {
        if (projectTeamMemberResponse && members) {
            const { searchMember } = getValues();            
            const upnFromOption = (searchMember as IOption).value.split(" ")[0];
            const memberFromCache = members.find((member) => member.UserPrincipalName === upnFromOption);

            if (memberFromCache) {
                dispatch({
                    type: JoinProjectWizardStepsActionTypesEnum.SET_SEARCHED_MEMBER_DATA,
                    payload: {
                        ...step,
                        answer: {
                            upn: upn,
                            searchMemberLabel: searchMember.label,
                            searchMemberValue: searchMember.value,
                            team: projectTeamMemberResponse.Team,
                            personalDetails: {
                                firstName: projectTeamMemberResponse.FirstName,
                                lastName: projectTeamMemberResponse.LastName,
                                email: projectTeamMemberResponse.ContactEmailAddress,
                                displayValue: `${projectTeamMemberResponse.FirstName} ${projectTeamMemberResponse.LastName}`
                            },
                            country: {
                                country: projectTeamMemberResponse.Country,
                                isoString: projectTeamMemberResponse.CountryIso,
                                displayValue: projectTeamMemberResponse.Country
                            },
                            deviceType: {
                                deviceType: projectTeamMemberResponse.DeviceType
                            },
                            startDate: {
                                isoString: projectTeamMemberResponse.StartDate,
                                displayValue: dayjs(projectTeamMemberResponse.StartDate).format("DD MMM YYYY")
                            },
                            subscriptionType: {
                                subscriptionType: projectTeamMemberResponse.SubscriptionType,
                                displayValue: `${projectTeamMemberResponse.SubscriptionType} subscription`
                            },
                            rate: {
                                rate: projectTeamMemberResponse.Rate,
                                rateType: RateTypeEnum.Monthly,
                                rateCurrency: projectTeamMemberResponse.RateCurrency,
                                displayValue: formatRateDisplay(projectTeamMemberResponse.Rate, projectTeamMemberResponse.RateCurrency)
                            },
                            endDate: {
                                endDateMode: undefined,
                                isoString: undefined,
                                displayValue: undefined
                            },
                            homeProject: {
                                displayValue: projectTeamMemberResponse.HomeProjectName
                            }
                        }
                    }
                });

                dispatch({
                    type: JoinProjectWizardStepsActionTypesEnum.LOAD_NEXT_STEP
                });
            }
        }
    }, [projectTeamMemberResponse]);

    const processAndGoToNext = (data: SearchMemberStepInputs): void => {
        if (members) {        
            const { searchMember } = data;
            const upnFromSelectOption = (searchMember as IOption).value.split(" ")[0];
            const selectedMemberMatch = members.find((member) => member.UserPrincipalName === upnFromSelectOption);

            if (selectedMemberMatch) {
                setUpn(upnFromSelectOption);
            }
        }
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={500}>
                    Name
                </Text>
            </S.QuestionWrapper>
            <S.AnswerWrapper>
                <FormControl as="fieldset" style={{ width: 400 }}>
                    {isMemberEligibilityLoading ? <Spinner size={2} padding="0">Loading...</Spinner> : 
                        <Controller
                            name="searchMember"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    ref={field.ref}
                                    defaultValue={
                                        step.answer.searchMemberLabel
                                            ? { label: step.answer.searchMemberLabel, value: step.answer.searchMemberValue }
                                            : undefined
                                    }
                                    onValueChange={field.onChange}
                                    placeholder="Select..."
                                    isSearchable
                                    options={memberOptions}
                                    error={!!errors.searchMember}
                                />
                            )}
                        />
                    }                    
                    {errors.searchMember && (
                        <FormErrorText>{(errors.searchMember as any).label.message}</FormErrorText>
                    )}
                </FormControl>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button iconSvg={<Tick />} 
                    isLoading={isGetProjectTeamMemberByUpnLoading}
                    iconSize={9} onClick={handleSubmit(processAndGoToNext)}>
                    Next
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default SearchMember;
