/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { IProjectAdminAssignmentResponse } from "../../../../types/projectAdminAssignment";
import { IOnboardingProjectAdminState } from "./types";
import { initialOnboardingProjectAdminState } from "./initialState";

export enum OnboardingProjectAdminActionTypesEnum {
    SET_ONBOARDING_CONTEXT = "SET_ONBOARDING_CONTEXT",
    SET_ONBOARDING_ACCESSTOKEN = "SET_ONBOARDING_ACCESSTOKEN",
    SET_ONBOARDING_MANAGED_PROCESS = "SET_ONBOARDING_MANAGED_PROCESS",
}

export interface ISetOnboardingProjectAdminContextAction {
    type: OnboardingProjectAdminActionTypesEnum.SET_ONBOARDING_CONTEXT;
    payload: IOnboardingProjectAdminState["ManagedContext"];
}

export interface ISetOnboardingProjectAdminAccessToken {
    type: OnboardingProjectAdminActionTypesEnum.SET_ONBOARDING_ACCESSTOKEN;
    payload: string;
}

export interface ISetOnboardingProjectAdminAManagedProcess {
    type: OnboardingProjectAdminActionTypesEnum.SET_ONBOARDING_MANAGED_PROCESS;
    payload: IProjectAdminAssignmentResponse | undefined;
}

export type OnboardingProjectAdminActions = 
    | ISetOnboardingProjectAdminContextAction 
    | ISetOnboardingProjectAdminAccessToken
    | ISetOnboardingProjectAdminAManagedProcess;

export const reducer: React.Reducer<IOnboardingProjectAdminState, OnboardingProjectAdminActions> = (state, action) => {
    switch (action.type) {
        case OnboardingProjectAdminActionTypesEnum.SET_ONBOARDING_CONTEXT: {
            return {
                ...state,
                ManagedContext: action.payload
            };
        }
        case OnboardingProjectAdminActionTypesEnum.SET_ONBOARDING_ACCESSTOKEN: {
            return {
                ...state,
                AccessToken: action.payload
            };
        }
        case OnboardingProjectAdminActionTypesEnum.SET_ONBOARDING_MANAGED_PROCESS: {
            return {
                ...state,
                ManagedProcess: action.payload
            };
        }
        default:
            return state;
    }
};

export interface IOnboardingProjectAdminContextProps {
    state: IOnboardingProjectAdminState;
    dispatch: React.Dispatch<OnboardingProjectAdminActions>;
}

export const OnboardingProjectAdminContext = React.createContext<IOnboardingProjectAdminContextProps>({
    state: initialOnboardingProjectAdminState,
    dispatch: () => null
});

export function useOnboardingProjectAdminContext (): IOnboardingProjectAdminContextProps {
    return React.useContext(OnboardingProjectAdminContext);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const OnboardingProjectAdminProvider = ({ children }: any) => {
    const [state, dispatch] = React.useReducer(reducer, initialOnboardingProjectAdminState);
    const value = { state, dispatch };
    return <OnboardingProjectAdminContext.Provider value={value}>{children}</OnboardingProjectAdminContext.Provider>;
};
