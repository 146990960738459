import React from "react";
import PropTypes from "prop-types";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { UserTypeEnum } from "../../types/shared";
import * as S from "./ProfileProgressCircle.styles";

export interface IProfileProgressCircleProps {
    size: number;
    percentage?: number | null;
    percentagePathColour?: string | null;
    trailColour?: string | null;
    hasUserIcon?: boolean | null;
    userType: UserTypeEnum;
}

/**
 * Profile progress circle component.
 * @param {number} size - Sets the size of the component. This numeric prop is applied to the width and height of the component wrapper. It is a required prop.
 * @param {number | null} [percentage] - Completion value of the progress circle, from 0 to 100.
 * @param {string | null} [percentagePathColour] - The colour of the progress path.
 * @param {string | null} [trailColour] - Changes the trail colour of the circle. Only hex colours must be passed. If not provided, defaults to "transparent".
 * @param {boolean | null} [hasUserIcon] - Makes the component render with or without the user icon in its center. Default is true.
 * @param {UserTypeEnum} [userType] - Determines which icon to show.
 */
const ProfileProgressCircle: React.FC<IProfileProgressCircleProps> = ({
    size,
    percentage,
    percentagePathColour,
    trailColour,
    hasUserIcon,
    userType
}) => (
    <S.ProfileProgressCircleWrapper size={size}>

        {!hasUserIcon && <S.StyledRingIcon />}
        {hasUserIcon && userType === UserTypeEnum.User && <S.StyledRingWithUserIcon />}
        {hasUserIcon && userType === UserTypeEnum.ProjectAdmin && <S.StyledProjectAdminIcon />}
        {hasUserIcon && userType === UserTypeEnum.UserAndProjectAdmin && <S.StyledProjectAdminAndUserIcon />}

        <CircularProgressbar
            value={percentage as number}
            strokeWidth={8}
            styles={{
                root: {
                    zIndex: 0
                },
                path: {
                    stroke: `${percentagePathColour}`,
                    strokeLinecap: "butt"
                },
                trail: {
                    stroke: `${trailColour}`
                }
            }}
        />
    </S.ProfileProgressCircleWrapper>
);

ProfileProgressCircle.defaultProps = {
    percentage: 0,
    percentagePathColour: "#BFCCFF",
    trailColour: "transparent",
    hasUserIcon: true
};

ProfileProgressCircle.propTypes = {
    size: PropTypes.number.isRequired,
    percentage: PropTypes.number,
    percentagePathColour: PropTypes.string,
    trailColour: PropTypes.string,
    hasUserIcon: PropTypes.bool
};

export default ProfileProgressCircle;
