import { CurrencySymbolsEnum, RateCurrencyEnum } from "../types/shared";

const formatRateDisplay = (rate: number | undefined, rateCurrency: RateCurrencyEnum | undefined): string => {
    if (!rate || rate === 0 || !rateCurrency) {
        return "-";
    }

    return `${CurrencySymbolsEnum[rateCurrency] + parseFloat(rate.toFixed(2)) }pcm`;
};    

export default formatRateDisplay;
