import styled from "styled-components";

const Form = styled.form`
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    // TODO: Fix bw ui components
    .bw-react-select__option--is-disabled {
        color: gray !important;
    }    
`;

const TeamAccordionBody = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 224px;
    padding-left: 85px;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const StepLabel = styled.div`
    margin-bottom: 12px;

    // TODO: Make all colours available to text and heading components in components library, and remove the below style afterwards
    span {
        color: #bfccff;
    }
`;

const StepIndicatorCirclesWrapper = styled.div`
    display: flex;
    margin-bottom: 21px;
`;

const QuestionWrapper = styled.div`
    max-width: 75%;
    margin-bottom: 20px;
`;

const AnswerWrapper = styled.div`
    display: flex;
`;

const TeamMemberNameAndEmailContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    width: 70%;
    gap: 12px;
`;

const SubscriptionTypeContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
`;

const OptionsWrapper = styled.div`
    display: flex;
    margin-top: auto;

    button {
        margin-right: 12px;
    }
`;

// TODO: Try and fix this within bw-ui-components lib
const RadioContainer = styled.div`
    & > :not(label:last-child) {
        margin-right: 12px;
    }
`;

export {
    Form,
    TeamAccordionBody,
    StepLabel,
    StepIndicatorCirclesWrapper,
    QuestionWrapper,
    AnswerWrapper,
    TeamMemberNameAndEmailContainer,
    SubscriptionTypeContainer,
    OptionsWrapper,
    RadioContainer
};
