import React from "react";
import cx from "classnames";
import ReactPlayer from "react-player/file";
import { Heading, Text } from "@breakingwave/react-ui-components";
import Layout from "../../../components/layout";
import Section from "../../../components/section";
import { IHtmlHeadProps } from "../../../components/html-head";
import { IHeaderProps } from "../../../components/header";
import { IFooterProps } from "../../../components/footer";
import * as S from "./index.styles";

const Homepage: React.FC = () => {
    const [showPoster, setShowPoster] = React.useState<boolean>(false);

    const htmlHeadProps: IHtmlHeadProps = {
        pageTitle: "Homepage | Breaking Wave",
        description:
            "We are a newly created Deutsche Bank company building a digital future for the bank and its customers",
        indexPage: process.env.REACT_APP_ENV_TYPE === "production"
    };

    const headerProps: IHeaderProps = {
        showHideOnScroll: true,
        hasLoginButtonAndAvatar: true,
        isHeaderResponsive: true
    };

    const footerProps: IFooterProps = {
        hasFooterLinks: true,
        isFooterResponsive: true
    };

    return (
        <S.HomepageWrapper>
            <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
                <Section classNames={["homepage-video"]} style={{ padding: 0 }} omitDefaultColumns>
                    <Heading variant="h1" weight={500} className="is-invisible">
                        Breaking Wave
                    </Heading>
                    <ReactPlayer
                        style={{ pointerEvents: "none" }}
                        width="100%"
                        height="100%"
                        playing
                        muted
                        controls={false}
                        url={`${process.env.REACT_APP_VIDEO_ASSETS_CDN}/expression.mp4`}
                        onEnded={(): void => setShowPoster(true)}
                        playsinline
                        config={{
                            attributes: {
                                title:
                                    "There is a place where ideas come to life. The future won't wait. Let's build it. Breaking Wave."
                            }
                        }}
                    />
                    {showPoster && (
                        <img
                            src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/other/homepage_static_panel.png`}
                            className="homepage-video__poster"
                        />
                    )}
                </Section>
                <Section classNames={["who-we-are", "has-background-white", "has-text-black"]}>
                    <div className="columns mb-custom-15 mb-custom-8-mobile">
                        <div className="column is-10">
                            <Heading
                                variant="h6"
                                weight={300}
                                color="black.100"
                                style={{ lineHeight: 1.18 }}
                                className={cx("mb-custom-13", "mb-custom-8-mobile", "mobile-font-size-16")}
                            >
                                Who we are
                            </Heading>
                            <Text
                                size={28}
                                color="black.100"
                                weight={600}
                                style={{ lineHeight: 1.28 }}
                                className={cx("mb-custom-10", "mb-custom-8-mobile", "mobile-font-size-22")}
                            >
                                Breaking Wave is a fintech inside Deutsche Bank.
                            </Text>
                            <Text
                                className="mobile-font-size-22"
                                size={28}
                                color="black.100"
                                weight={300}
                                style={{ lineHeight: 1.28 }}
                            >
                                An initiative created to test ideas and build digital products which drive growth,
                                improve margins and create fresh opportunities for Deutsche Bank and its clients.
                            </Text>
                        </div>
                    </div>
                    <hr className="who-we-are__divider my-0 mb-custom-11 is-hidden-mobile" />
                    <div className="columns mb-custom-9 mb-custom-13-mobile">
                        <div className="column is-9">
                            <Text
                                className="mobile-font-size-16"
                                size={22}
                                weight={300}
                                color="black.100"
                                style={{ lineHeight: 1.18 }}
                            >
                                It all happens in an open area of exploration, at the same speed and with the same
                                creativity as a technology startup.
                            </Text>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-4 who-we-are__card">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mb-custom-6 mb-custom-7-mobile"
                                width="64"
                                height="64"
                            >
                                <title>Governed icon</title>
                                <image
                                    width="64"
                                    height="64"
                                    href={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/icons/inside_DB_icon_black.svg`}
                                />
                            </svg>
                            <Text color="black.100" style={{ lineHeight: 1.4 }} className="mobile-font-size-16">
                                All from inside Deutsche Bank, drawing on the power of the bank, our people, network and
                                knowledge.
                            </Text>
                        </div>
                        <div className="column is-4 who-we-are__card">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mb-custom-6 mb-custom-7-mobile"
                                width="64"
                                height="64"
                            >
                                <title>Governed icon</title>
                                <image
                                    width="64"
                                    height="64"
                                    href={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/icons/governed_icon_black.svg`}
                                />
                            </svg>
                            <Text color="black.100" style={{ lineHeight: 1.4 }} className="mobile-font-size-16">
                                Governed as a software company, which allows engineers to build with the same freedom as
                                a start up.
                            </Text>
                        </div>
                        <div className="column is-4 who-we-are__card">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mb-custom-6 mb-custom-7-mobile"
                                width="64"
                                height="64"
                            >
                                <title>Governed icon</title>
                                <image
                                    width="64"
                                    height="64"
                                    href={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/icons/environment_icon_black.svg`}
                                />
                            </svg>
                            <Text color="black.100" style={{ lineHeight: 1.4 }} className="mobile-font-size-16">
                                We provide the environment, talent and resources to turn ideas into real products and
                                services for our clients.
                            </Text>
                        </div>
                    </div>
                </Section>
                <Section classNames={["has-background-black", "has-text-white"]} omitDefaultColumns>
                    <div className="columns the-community">
                        <div className="column is-11-tablet is-offset-1-tablet is-10-widescreen is-offset-2-widescreen">
                            <div className="columns the-community__content">
                                <div className="column is-5-fullhd is-6-tablet">
                                    <Heading
                                        variant="h6"
                                        weight={300}
                                        style={{ lineHeight: 1.18 }}
                                        className={cx("mb-custom-13", "mb-custom-8-mobile", "mobile-font-size-16")}
                                    >
                                        The Community
                                    </Heading>
                                    <Text
                                        size={26}
                                        style={{ lineHeight: 1.3 }}
                                        className={cx("mb-custom-10", "mb-custom-10-mobile", "mobile-font-size-20")}
                                    >
                                        The Breaking Wave environment enables engineering teams from Deutsche Bank,
                                        vendors, freelancers and Breaking Wave to collaborate in one space.
                                    </Text>
                                    <Text
                                        size={26}
                                        style={{ lineHeight: 1.3 }}
                                        className={cx("mb-custom-10", "mb-custom-10-mobile", "mobile-font-size-20")}
                                    >
                                        We call this the Breaking Wave Community.
                                    </Text>
                                    <Text
                                        style={{ lineHeight: 1.4 }}
                                        className={cx("mb-custom-7", "mb-custom-7-mobile", "mobile-font-size-16")}
                                    >
                                        As part of the community, access is granted to an environment which includes
                                        engineering tools, vendors solutions and technologies, and usually a mix of all
                                        three.
                                    </Text>
                                    <Text style={{ lineHeight: 1.4 }} className="mobile-font-size-16">
                                        Using open source software and best in class technology, the community builds
                                        future facing digital products for Deutsche Bank and our clients.
                                    </Text>
                                </div>
                                <div className="column is-6-fullhd is-offset-1-fullhd is-6-tablet">
                                    <div className="the-community__imagery">
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/other/community_members2.png`}
                                            alt="Photographs of Breaking Wave community members"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
                <Section classNames={["tech-stack", "has-text-white"]}>
                    <div className="columns">
                        <div className="column is-10">
                            <Heading
                                variant="h6"
                                weight={300}
                                style={{ lineHeight: 1.18 }}
                                className={cx("mb-custom-13", "mb-custom-8-mobile", "mobile-font-size-16")}
                            >
                                Our work
                            </Heading>
                            <Text
                                size={26}
                                style={{ lineHeight: 1.3 }}
                                className={cx("mb-custom-10", "mb-custom-10-mobile", "mobile-font-size-20")}
                            >
                                As an engineering-led company, we won&apos;t compromise when it comes to technology.
                            </Text>
                        </div>
                    </div>
                    <div className="columns is-flex is-relative is-multiline">
                        <div className="tech-stack__border-offset" />
                        <div className="tech-stack__logo is-flex is-justify-content-center is-align-items-center">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/technology/azure_logo.svg`}
                                alt="Microsoft Azure logo"
                            />
                        </div>
                        <div className="tech-stack__logo is-flex is-justify-content-center is-align-items-center">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/technology/react_logo.svg`}
                                style={{ maxHeight: 45 }}
                                alt="React logo"
                            />
                        </div>
                        <div className="tech-stack__logo is-flex is-justify-content-center is-align-items-center">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/technology/spring_logo.svg`}
                                alt="Spring logo"
                            />
                        </div>
                        <div className="tech-stack__logo is-flex is-justify-content-center is-align-items-center">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/technology/snyk_logo.svg`}
                                style={{ maxHeight: 52 }}
                                alt="Snyk logo"
                            />
                        </div>
                        <div className="tech-stack__logo is-flex is-justify-content-center is-align-items-center">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/technology/jsts_logo.svg`}
                                alt="JavaScript TypeScript logo"
                            />
                        </div>
                        <div className="tech-stack__logo is-flex is-justify-content-center is-align-items-center">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/technology/k8s_logo.svg`}
                                style={{ maxHeight: 25 }}
                                alt="Kubernetes logo"
                            />
                        </div>
                        <div className="tech-stack__logo is-flex is-justify-content-center is-align-items-center">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/technology/google_cloud_logo.svg`}
                                alt="Google Cloud Platform logo"
                            />
                        </div>
                        <div className="tech-stack__logo is-flex is-justify-content-center is-align-items-center">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/technology/terraform_logo.svg`}
                                alt="Terraform logo"
                            />
                        </div>
                    </div>
                </Section>
            </Layout>
        </S.HomepageWrapper>
    );
};

export default Homepage;
