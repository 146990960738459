import React from "react";
import { useMsal } from "@azure/msal-react";
import { UserRolesEnum } from "../types/userRoles";
import { useProjectPageContext } from "../pages/private/project/_state/context";
import { groupIds } from "../utils/constants";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useUserRoles = () => {
    const { accounts } = useMsal();

    const [userRole, setUserRole] = React.useState<UserRolesEnum | undefined>(undefined);    

    const groups = accounts[0]?.idTokenClaims?.groups;

    const { 
        state: {
            selectedProject: {
                ProjectSuperAdminEmail
            }
        }
    } = useProjectPageContext();

    React.useEffect(() => {
        if (groups) {
            if ((groups as string[]).includes(groupIds.superAdmin)) {
                setUserRole(UserRolesEnum.GlobalSuperAdmin);
            } else if (accounts[0]?.username === ProjectSuperAdminEmail) {
                setUserRole(UserRolesEnum.ProjectSuperAdmin);
            } else {
                setUserRole(UserRolesEnum.Other);
            }
        }
    }, [groups]);

    return {
        userRole
    };
};

export default useUserRoles;
