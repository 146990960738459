import styled, { css } from "styled-components";

const ProjectNavigationWrapper = styled.div`
    background-color: black;
    border-bottom: 1px solid #707070;
`;

const ProjectSelectWrapper = styled.div`
    margin-bottom: 72px;
    width: 500px;
`;

const DownloadCsvWrapper = styled.div`
    padding-top: 2.6em;
    display: flex;
    justify-content: flex-end;
`;

const Tabs = styled.ul`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 20px;
`;

const TabItem = styled.li<{ activeTab: boolean; isDisabled: boolean }>`
    position: relative;

    &:not(:last-child) {
        margin-right: 25px;
    }

    ${({ activeTab }) =>
        activeTab &&
        css`
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 4px;
                background-color: white;
                bottom: -20px;
                left: 0;
            }
        `};

    a {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        color: ${({ activeTab }) => `${activeTab ? "#FFFFFF" : "rgba(255, 255, 255, 0.5)"}`};

        ${({ isDisabled }) =>
        isDisabled &&
        css`
                pointer-events: none;
                user-select: none;
            `};
    }
`;

const TabHeader = styled.div<{ isSticky: boolean }>`
    background-color: #191818;
    padding-top: 56px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2); // #707070 with 0.2 opacity;

    ${({ isSticky }) =>
        isSticky &&
        css`
            position: sticky;
            top: 0;
            z-index: 99;
        `};
`;

const ButtonGroupWrapper = styled.div`
    width: fit-content;
    margin-left: auto;
`;

const SearchFieldWrapper = styled.div`
    margin-bottom: 40px;
`;

const TabBodyTeamAssignmentsAndMembers = styled.div`
    background-color: #1d1d1d;
    padding: 28px 0 80px;
`;

const TabBodySubscription = styled.div`
    background-color: #1d1d1d;
    padding: 28px 0 80px;
`;

const TabSubscriptionsHeader = styled.ul`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 12px;
`;

const TabSubscriptionHeaderItem = styled.li``;

const TabHeaderTeamAssignmentsAndMembers = styled.div`
    margin-left: 14px;
    display: grid;
    grid-template-columns: 205px 206px 190px 190px 190px 155px 90px;
    margin-bottom: 12px;
`;

export {
    ProjectNavigationWrapper,
    ProjectSelectWrapper,
    DownloadCsvWrapper,
    Tabs,
    TabItem,
    TabHeader,
    TabBodyTeamAssignmentsAndMembers,
    TabBodySubscription,
    TabSubscriptionsHeader,
    TabSubscriptionHeaderItem,
    TabHeaderTeamAssignmentsAndMembers,
    ButtonGroupWrapper,
    SearchFieldWrapper
};
