import React, { useMemo } from "react";
import { Button, Text } from "@breakingwave/react-ui-components";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../../types/teamMemberDisplayItems";
import { useProjectPageContext } from "../../_state/context";
import { IProjectMembershipDto, ProjectTypeEnum } from "../../../../../types/shared";
import toTitleCase from "../../../../../utils/toTitleCase";
import ProjectMembershipCard from "./ProjectMembershipCard";
import * as S from "./index.styles";

interface IProjectMembershipProps {
    teamMember: IProjectTeamMemberDisplayItemsResponse;
    projectMembershipDtos: IProjectMembershipDto[];
    errorLoadingMembership: boolean;
    onClose(): void;
}

const ProjectMembership: React.FC<IProjectMembershipProps> = ({
    teamMember,
    projectMembershipDtos,
    errorLoadingMembership,
    onClose
}) => {
    
    const { 
        state: {
            selectedProject            
        }
    } = useProjectPageContext();
    
    const filteredProjectMembershipDtos = useMemo(() => {

        // Only show working projects on the cards
        if (selectedProject.ProjectType === ProjectTypeEnum.Working) {
            return projectMembershipDtos
                .filter((dto) => dto.Project.ProjectSlug !== selectedProject.ProjectSlug && 
                                 dto.Project.ProjectType === ProjectTypeEnum.Working);
        }

        return projectMembershipDtos.filter((dto) => dto.Project.ProjectType === ProjectTypeEnum.Working);
    }, [projectMembershipDtos]);

    const homeProject = useMemo(() => {
        if (projectMembershipDtos) {
            return projectMembershipDtos.find((dto) => dto.Project.ProjectSlug === teamMember.HomeProjectSlug);
        }
        return undefined;
    }, [projectMembershipDtos]);

    return (
        <>       
            {errorLoadingMembership && 
            <S.NoOtherProjects>
                <S.NoOtherProjectsTitle>
                    <Button variant="secondary" onClick={() => onClose()}>
                    Close
                    </Button>
                </S.NoOtherProjectsTitle>                
                <S.NoOtherProjectsLabel>
                    <Text variant="p" >
                        An error occurred loading the project membership data
                    </Text>
                </S.NoOtherProjectsLabel>
            </S.NoOtherProjects>            
            }

            {!errorLoadingMembership && filteredProjectMembershipDtos && filteredProjectMembershipDtos.length === 0 && 
            <S.NoOtherProjects>
                <S.NoOtherProjectsTitle>
                    <Button variant="secondary" onClick={() => onClose()}>
                        Close
                    </Button>
                </S.NoOtherProjectsTitle>                
                <S.NoOtherProjectsLabel>
                    {selectedProject.ProjectType === ProjectTypeEnum.Working ? 
                        <Text variant="p" >
                            {toTitleCase(teamMember.FirstName)} is not currently working on any other projects
                        </Text>
                        :
                        <Text variant="p" >
                            {toTitleCase(teamMember.FirstName)} is not currently working on any projects
                        </Text>                    
                    }
                </S.NoOtherProjectsLabel>
            </S.NoOtherProjects>
            }

            {filteredProjectMembershipDtos && 
             filteredProjectMembershipDtos.length > 0 && 
             homeProject &&
            <>
                <S.ProjectMembershipTitle>
                    {selectedProject.ProjectType === ProjectTypeEnum.Working ? 
                        <Text variant="p">
                            <span>{toTitleCase(teamMember.FirstName)}</span> is also working on the following {homeProject.Project.DisplayName} project(s):
                        </Text>                    
                        :
                        <Text variant="p">
                            <span>{toTitleCase(teamMember.FirstName)}</span> is working on the following {homeProject.Project.DisplayName} project(s):
                        </Text>
                    }
                    <Button variant="secondary" onClick={() => onClose()}>
                        Close
                    </Button>
                </S.ProjectMembershipTitle>

                <S.ProjectMembershipCardCollection>
                    {filteredProjectMembershipDtos.map((dto) => 
                        <ProjectMembershipCard 
                            key={`${selectedProject.ProjectSlug}_${teamMember.UserPrincipalName}_${dto.Project.ProjectSlug}`} 
                            projectMembershipDto={dto}
                            teamMember={teamMember} />)}
                </S.ProjectMembershipCardCollection>
            </>
            }
        </>
    );
};

export default ProjectMembership;
