import React, { useCallback, useMemo } from "react";
import { Tooltip } from "react-tooltip";
import { Heading, Text } from "@breakingwave/react-ui-components";
import { IProjectMembershipDto } from "../../../../../types/shared";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../../types/teamMemberDisplayItems";
import { useProjectPageContext } from "../../_state/context";
import * as S from "./index.styles";

interface IProjectMembershipCardProps {
    teamMember: IProjectTeamMemberDisplayItemsResponse;
    projectMembershipDto: IProjectMembershipDto;
}

const ProjectMembershipCard: React.FC<IProjectMembershipCardProps> = ({
    teamMember,
    projectMembershipDto
}) => {

    const { 
        state: {
            selectedProject            
        }
    } = useProjectPageContext();

    const { 
        Project,
        ProjectAdmins
    } = projectMembershipDto;

    const orderedProjectAdmins = useMemo(() => ProjectAdmins.sort((a, b) => {
        if (a.FirstName < b.FirstName) {
            return -1;
        }

        if (a.FirstName > b.FirstName) {
            return 1;
        }

        return 0;
    }), [ProjectAdmins]);

    const [copied, setCopied] = React.useState(false);

    const getInitials = useCallback((projectAdmin: IProjectTeamMemberDisplayItemsResponse) => 
        projectAdmin.FirstName[0].toUpperCase() + projectAdmin.LastName[0].toUpperCase()
    , []);

    const getColourOffset = useCallback((projectAdmin: IProjectTeamMemberDisplayItemsResponse) => {
        const thisProjectAdminInitials = getInitials(projectAdmin);
        const projectAdminsWithSameInitials = ProjectAdmins.filter((x) => getInitials(x) === thisProjectAdminInitials);
        return projectAdminsWithSameInitials.indexOf(projectAdmin);
    }, []);    

    const copyEmailsToClipboard = useCallback(() => {
        const emailAddressesToCopy = projectMembershipDto.ProjectAdmins.map((p) => p.ContactEmailAddress);
        navigator.clipboard.writeText(emailAddressesToCopy.join(";"));        

        setCopied(true);

        const timeout: NodeJS.Timeout = setTimeout(() => {
            setCopied(false);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (    
        <S.ProjectCard>
            <S.ProjectCardProjectName>
                <Heading variant="h5">
                    {Project.DisplayName}
                </Heading> 
            </S.ProjectCardProjectName>
            <S.ProjectAdminsLabel>
                <Text variant="p">
                       Project admins:
                </Text>
            </S.ProjectAdminsLabel>
        
            <S.AvatarWrapper>        
                {orderedProjectAdmins.map((projectAdmin) =>
                    <S.ProjectCardAvatar 
                        key={`${selectedProject.ProjectSlug}_${teamMember.UserPrincipalName}_${selectedProject.ProjectSlug}_${projectAdmin.UserPrincipalName}`}                        
                        colourOffet={getColourOffset(projectAdmin)} >
                        <Text variant="p">
                            {getInitials(projectAdmin)}
                        </Text>                           
                    </S.ProjectCardAvatar>
                )}     

                {projectMembershipDto?.ProjectAdmins?.length === 0 && 
                <S.NoProjectAdminsLabel>
                    <Text variant="p">
                        There are currently no project administrators on this project
                    </Text>
                </S.NoProjectAdminsLabel>}                
            </S.AvatarWrapper>

            <S.DottedLine />
      
            <S.CopyEmailsLabel>
                {projectMembershipDto?.ProjectAdmins?.length > 0 && <>
                    <Text variant="p">
                        Copy email addresses
                    </Text>
                    <S.StyledCopyIcon 
                        data-tooltip-id={`tooltip-copy-emails-${Project.ProjectSlug}`}
                        data-tooltip-content={copied ? "Copied!" : "Copy"}
                        onClick={() => copyEmailsToClipboard()}>

                    </S.StyledCopyIcon>
                    <Tooltip id={`tooltip-copy-emails-${Project.ProjectSlug}`} className={copied ? "tooltip--mousedown" : "tooltip"} />
                </>}
            </S.CopyEmailsLabel>
            
        </S.ProjectCard>);
};

export default ProjectMembershipCard;
