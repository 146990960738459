import React, { useState } from "react";
import dayjs from "dayjs";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../../types/teamMemberDisplayItems";
import { AccordionToggleIconTypeEnum, TeamAccordionMenuItemsEnum } from "../../../../../types/accordions";
import { useProjectPageContext } from "../../_state/context";
import { ProjectPageActionTypesEnum } from "../../_state/actions";
import formatRateDisplay from "../../../../../utils/formatRateDisplay";
import { ProjectTypeEnum } from "../../../../../types/shared";
import useGetProjectMembership from "../../../../../api/getProjectMembership";
import Spinner from "../../../../../components/spinner";
import TeamAccordion from "../../../../../components/accordion/TeamAccordion";
import useUserType from "../../../../../hooks/useUserType";
import ProjectMembership from "./ProjectMembership";
import { teamMemberWithContextMenuColours } from "./index.colours";
import * as S from "./index.styles";

interface ITeamMemberWithContextMenuProps {
    data: IProjectTeamMemberDisplayItemsResponse;
    defaultOpenState: boolean;
}

/**
 * TeamMemberWithContextMenu mode of <TeamWizards />.
 * @param {IProjectTeamMemberDisplayItemsResponse} data - Single team member data.
 * @param {boolean} [defaultOpenState] - An optional boolean prop which is passed down via <TeamWizards /> to render wizard accordion body open. Defaults to false.
 */
const TeamMemberWithContextMenuWizard: React.FC<ITeamMemberWithContextMenuProps> = ({ 
    data: teamMember,
    defaultOpenState
}) => {
    if (!teamMember) return null;

    const [selectedMenuItem, setSelectedMenuItem] = useState<TeamAccordionMenuItemsEnum | undefined>();

    const { 
        state: {
            selectedProject            
        }, 
        dispatch: projectPageDispatch 
    } = useProjectPageContext();

    const { 
        data: projectMembershipDtos,
        isFetching: isLoadingProjectMembership,
        refetch: loadProjectMembership,
        isError: errorLoadingProjectMembership      
    } = useGetProjectMembership(
        selectedProject.ProjectSlug, 
        teamMember.UserPrincipalName, 
        {
            enabled: false,
            cacheTime: 0
        });

    const initialiseOffboardWizard = (): void => {
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.SET_OFFBOARD_TEAM_MEMBER_WIZARD,
            payload: {
                isActive: true,
                tempOffboardingMemberData: teamMember,
                lastSavedOffboardUPN: undefined
            }
        });  
    };

    const initialiseLeaveProjectWizard = (): void => {
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.SET_LEAVE_PROJECT_WIZARD,
            payload: {
                isActive: true,
                tempLeavingMemberData: teamMember,
                lastSavedLeaveUPN: undefined
            }
        });
    };

    const initialiseProjectAdminAssignWizard = (): void => {
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.SET_PROJECT_ADMIN_ASSIGNMENT_WIZARD,
            payload: {
                isCompleted: false,
                isActive: true,
                tempProjectAdminAssignmentStepsData: teamMember,
                lastSavedAdminAssignmentUPN: undefined
            }
        });
    };

    const initialiseRevokeProjectAdminRightsWizard = (): void => {
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.SET_REVOKE_ADMIN_RIGHTS_WIZARD,
            payload: {
                isCompleted: false,
                isActive: true,
                tempRevokeProjectAdminRightsStepsData: teamMember,
                lastSavedRevokeUPN: undefined
            }
        });
    };    

    const onContextMenuClick = (menuOption: TeamAccordionMenuItemsEnum): void => {
        setSelectedMenuItem(menuOption);

        if (menuOption === TeamAccordionMenuItemsEnum.Projects) {
            if (!projectMembershipDtos && !isLoadingProjectMembership) {
                loadProjectMembership();
            }            
        } else if (menuOption === TeamAccordionMenuItemsEnum.RemoveTeamMember) {
            if (selectedProject.ProjectType === ProjectTypeEnum.Home) {
                initialiseOffboardWizard();
            }

            if (selectedProject.ProjectType === ProjectTypeEnum.Working) {
                initialiseLeaveProjectWizard();
            }
        } else if (menuOption === TeamAccordionMenuItemsEnum.AssignAsProjectAdmin) {
            initialiseProjectAdminAssignWizard();
        } else if (menuOption === TeamAccordionMenuItemsEnum.RevokeProjectAdminRights) {
            initialiseRevokeProjectAdminRightsWizard();
        }
    };

    const { userType } = useUserType(teamMember);

    const isOpen = React.useMemo(() => {
        if (defaultOpenState) {
            return true;
        }

        if (isLoadingProjectMembership) {
            return true;
        }

        if ((projectMembershipDtos || errorLoadingProjectMembership) && selectedMenuItem === TeamAccordionMenuItemsEnum.Projects) {
            return true;
        }

        return false;
    }, [isLoadingProjectMembership, defaultOpenState, selectedMenuItem]);

    return (
        <TeamAccordion
            profileProgressCircleProps={{
                size: 30,
                percentage: 100,
                percentagePathColour: teamMemberWithContextMenuColours.memberIcon,
                userType
            }}            
            hasToggleIcon={true}
            toggleIconType={AccordionToggleIconTypeEnum.Threedots}
            isToggleable={false}
            onContextMenuClick={onContextMenuClick}
            homeProjectName={teamMember.HomeProjectName}
            teamMemberDisplayName={`${teamMember.FirstName} ${teamMember.LastName}`}
            team={teamMember.Team}
            country={teamMember.Country ? teamMember.Country : "-"}
            deviceType={teamMember.DeviceType}
            startDate={dayjs(teamMember.StartDate).format("DD MMM YYYY")}
            rate={teamMember === undefined ? "-" : formatRateDisplay(teamMember.Rate, teamMember.RateCurrency)}
            defaultOpenState={isOpen}
        >
            <S.AccordionBody>
                {isLoadingProjectMembership && 
                    <Spinner padding="20px" />}

                {!isLoadingProjectMembership && 
                 (projectMembershipDtos || errorLoadingProjectMembership) && 
                 selectedMenuItem === TeamAccordionMenuItemsEnum.Projects && 
                    <ProjectMembership 
                        teamMember={teamMember} 
                        projectMembershipDtos={projectMembershipDtos ?? []} 
                        errorLoadingMembership={errorLoadingProjectMembership}
                        onClose={() => setSelectedMenuItem(undefined)} />}  

            </S.AccordionBody>
        </TeamAccordion>
    );
};

export default TeamMemberWithContextMenuWizard;
