import styled from "styled-components";

const StyledErrorTitle = styled.h1`
    font-weight: bold;
    font-size:2em;
`;

const StyledErrorContainer = styled.div`
    display: flex;    
    flex-flow: column;
    justify-items: center;
    align-items: center;
`;

export { StyledErrorContainer, StyledErrorTitle };
