import React from "react";
import PropTypes from "prop-types";
import * as S from "./StepIndicatorCircle.styles";

export interface IStepIndicatorCircleProps {
    value: number;
    active?: boolean;
    completed?: boolean;
    success?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
}

/**
 * Circular button-like action indicator.
 * @param {number} value - A numeric value that is displayed in the center of the indicator ring
 * @param {boolean} [active] - A boolean prop that renders active styles
 * @param {boolean} [completed] - A boolean prop that renders completed styles
 * @param {boolean} [success] - A boolean prop that renders success styles
 * @param {boolean} [disabled] - A boolean prop that renders disabled styles
 * @param {object} [style] - A prop that accepts inline styles to be passed in section component
 */
const StepIndicatorCircle: React.FC<IStepIndicatorCircleProps> = (props) => {
    const { completed, success, value } = props;
    return (
        <S.Circle {...props}>
            {completed || success ? (
                //TODO: We should enable icons from component lib in place of static imports (i.e: <Tick />)
                <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
                    <image
                        width="12"
                        height="13"
                        href={`${process.env.REACT_APP_IMAGE_ASSETS_CDN}/icons/tick_icon_black_borderless.svg`}
                    />
                </svg>
            ) : (
                value
            )}
        </S.Circle>
    );
};

StepIndicatorCircle.propTypes = {
    value: PropTypes.number.isRequired,
    active: PropTypes.bool,
    completed: PropTypes.bool,
    success: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object
};

export default StepIndicatorCircle;
