import styled from "styled-components";

const GroupStatusesAccordionBody = styled.div`
    padding-top: 54px;
    padding-left: 85px;
    padding-bottom: 44px;
`;

const AllGroupStatusesWrapper = styled.div`
    display: flex;
`;

const EachGroupWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    min-width: 108px;
    border-bottom: 1px solid #404040;
    padding-bottom: 10px;
    margin-right: 70px;
`;

const GroupIcon = styled.div`
    margin-bottom: 12px;
`;

const GroupName = styled.span`
    font-size: 12px;
    font-weight: normal;
    letter-spacing: normal;
    color: #fff;
    margin-bottom: 8px;
`;

const GroupStatus = styled.span<{ color: string }>`
    font-size: 10px;
    font-weight: normal;
    letter-spacing: normal;
    color: ${({ color }) => color};
`;

export { GroupStatusesAccordionBody, AllGroupStatusesWrapper, EachGroupWrapper, GroupIcon, GroupName, GroupStatus };
