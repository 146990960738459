/**
 * This is a map of AAD group IDs.
 * All IDs are guaranteed to be defined.
 */
const groupIds = {
    superAdmin: process.env.REACT_APP_SA_GROUP_ID ?? ""
};

if (Object.values(groupIds).some((id) => !id)) {
    throw new Error("Not all group IDs are defined");
}

const mondayWorkspaceId = Number.parseInt(process.env.REACT_APP_MONDAY_WORKSPACE_ID ?? "", 10);
const mondayProjectTemplateId = Number.parseInt(process.env.REACT_APP_MONDAY_PROJECT_TEMPLATE_ID ?? "", 10);

if ([mondayWorkspaceId, mondayProjectTemplateId].some((id) => Number.isNaN(id))) {
    throw new Error("Not all Monday IDs are defined and valid");
}

export {
    groupIds,
    mondayWorkspaceId,
    mondayProjectTemplateId
};
