import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { protectedResources } from "../auth/authConfig";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAadAccessToken = (scopes: string[]) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    const getAccessToken = (): Promise<string> => {
        if (isAuthenticated) {
            // Silently acquires an access token which is then attached to a request for MS Graph data
            return instance
                .acquireTokenSilent({
                    scopes: scopes,
                    account: accounts[0]
                })
                .then((response) => response.accessToken);
        } else {
            instance.loginRedirect(protectedResources.graphMe).catch((e) => {
                console.log(e);
            });
            return Promise.reject();
        }
    };

    return {
        isAuthenticated,
        accounts,
        getAccessToken
    };
};

export default useAadAccessToken;
