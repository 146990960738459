import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const CookieBanner: React.FC = () => (
    <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="breakingWaveCookieConsent"
        disableStyles={true}
        expires={90}
        debug={false}
        containerClasses="cookie__container has-background-blue-medium has-text-normal"
        contentClasses="cookie__textWrapper"
        buttonWrapperClasses="cookie__buttonWrapper"
        buttonClasses="cookie__buttonWrapper--button"
        // We initially thought of revisiting button classes after updating bw-css, but it is not ideal to adapt this button to our default styles (both for desktop and mobile). Hence, I am leaving it as a custom button work for the cookie consent component.
    >
        This website uses cookies to ensure you get the best experience by showing you content most relevant to you. You
        can find out more about it{" "}
        <Link
            to={{
                pathname:
                    "https://www.db.com/legal-resources/privacy-notice?language_id=1&kid=company-privacy-notice.redirect-en.shortcut#show-content-of-cookies"
            }}
            target="_blank"
        >
            here
        </Link>
        .
    </CookieConsent>
);

export default CookieBanner;
