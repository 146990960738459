import axios from "axios";
import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";
import { protectedResources } from "../auth/authConfig";
import { QueryKeysEnum } from "../types/queryKeys";
import { IManagedContext, ITaskItemStatusResponse, TaskAutomationKeyUnion } from "../types/shared";

const getManagedProcessTaskStatus = async (
    context: IManagedContext,
    projectSlug: string,
    taskId: TaskAutomationKeyUnion,
    accessToken: string | undefined
): Promise<ITaskItemStatusResponse> => {
    const url = `${protectedResources.wavecore.endpoint}/api/ManagedProcess/TaskStatus/${context.ResourceType}/${projectSlug}/${context.Id}/${taskId}/${accessToken}`;

    const response = await axios({
        method: "GET",
        url: url,
        headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0"
        }
    });

    return response.data;
};

const useGetManagedProcessTaskStatus = <
    SelectData = ITaskItemStatusResponse
>(
    context: IManagedContext,
    projectSlug: string,
    taskId: TaskAutomationKeyUnion,
    accessToken: string | undefined,
    options?: UseQueryOptions<
    ITaskItemStatusResponse,
    unknown,
    SelectData,
    (QueryKeysEnum | IManagedContext | TaskAutomationKeyUnion)[]
    >
): UseQueryResult<SelectData> =>
    useQuery(
        [QueryKeysEnum.onboardingContextValidation, context, taskId],
        () => getManagedProcessTaskStatus(context, projectSlug, taskId, accessToken),
        {
            ...options,
            cacheTime: 0
        }
    );

export default useGetManagedProcessTaskStatus;
