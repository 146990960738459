import { CreateProjectTeamAssignmentDomainValidationFailureReasonEnum } from "../types/teamAssignment";

const projectTeamAssignmentBlockerReason = (bitwise: number): string => {
    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ProjectNotFound) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ProjectNotFound) {
        return "Project not found";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) {
        return "You do not have permission to add this user";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) {
        return "Invalid email domain, does not match project domain";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.DomainNotFound) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.DomainNotFound) {
        return "Domain not found";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InvalidDeviceType) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InvalidDeviceType) {
        return "Invalid Device Type";
    }    

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse) {
        return "Email is already in use";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.TeamNotFound) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.TeamNotFound) {
        return "Team not found";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse) {
        return "User principal name in use";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.FirstNameMissing) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.FirstNameMissing) {
        return "First name is required";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.LastNameMissing) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.LastNameMissing) {
        return "Last name is required";
    }

    if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailIsNotValidForUpnCandidate) === 
            CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailIsNotValidForUpnCandidate) {
        return "Contact email address contains invalid UPN characters";
    }    

    return "Unknown error occurred";
};

export {
    projectTeamAssignmentBlockerReason
};
