import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Text, Button, Tick, Checkbox, FormErrorText, FormControl } from "@breakingwave/react-ui-components";
import { useProjectPageContext } from "../../../../_state/context";
import { ProjectPageActionTypesEnum } from "../../../../_state/actions";
import useGetProjectTeamMemberDisplayItems from "../../../../../../../api/getProjectTeamMemberDisplayItems";
import { ConfirmationStepInputs, confirmationStepSchema } from "../index.schema";
import { ILeaveProjectErrorResponse } from "../../../../../../../types/leaveProject";
import { leaveProjectDomainValidationFailureReason } from "../../../../../../../utils/leaveProjectDomainValidationFailureReason";
import useCreateLeaveProject from "../../../../../../../api/createLeaveProject";
import * as S from "../../wizard.styles";

const Confirmation: React.FC = () => {

    const {
        state: projectPageState,
        state: {
            selectedProject: { 
                ProjectSlug 
            }
        },
        dispatch: projectPageDispatch
    } = useProjectPageContext();

    // TODO: Further thoughts: We are doing the above mentioned invalidating in useCreateTeamAssignment hook and it is working as expected.
    const { 
        refetch: refetchTeamMemberDisplayItems
    } = useGetProjectTeamMemberDisplayItems(ProjectSlug);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        watch
    } = useForm<ConfirmationStepInputs>({
        resolver: yupResolver(confirmationStepSchema)
    });

    const isConfirmed: boolean = watch("confirmation") ?? false;

    useEffect(() => {
        clearErrors();
    },[isConfirmed]);    

    const { 
        mutateAsync: leaveProject,
        isSuccess: isSubmitLeavingSuccess, 
        isLoading,
        data: leaveProjectData
    } = useCreateLeaveProject(ProjectSlug);

    const closeLeaveProjectWizard = (): void => {

        projectPageDispatch({
            type: ProjectPageActionTypesEnum.SET_LEAVE_PROJECT_WIZARD,
            payload: {
                isActive: false,
                tempLeavingMemberData: undefined,
                lastSavedLeaveUPN: leaveProjectData?.UserPrincipalName
            }
        });
    };

    React.useEffect(() => {
        if (isSubmitLeavingSuccess) {
            refetchTeamMemberDisplayItems();
            closeLeaveProjectWizard();            
        }
    }, [isSubmitLeavingSuccess]);

    const onConfirm = async (data: ConfirmationStepInputs): Promise<void> => {
        if (data.confirmation) {
            await leaveProject({
                UserPrincipalName: projectPageState.leaveProjectWizard.tempLeavingMemberData?.UserPrincipalName ?? ""
            }).catch((error) => {

                const result = error.response.data as ILeaveProjectErrorResponse;
                const bitwise: number = result.ValidationResult;                        

                setError("confirmation", { 
                    message: leaveProjectDomainValidationFailureReason(bitwise)
                });
            });
        }
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={300} style={{ marginBottom: 12 }}>
                    You would like to remove this team member from the project.
                    <br />
                    This process will begin immediately.
                </Text>
                <FormControl as="fieldset">
                    <Checkbox {...register("confirmation")} error={!!errors.confirmation} disabled={isLoading || isSubmitLeavingSuccess}>
                        Please check this box if you are happy to proceed
                    </Checkbox>
                    {errors.confirmation && (
                        <FormErrorText style={{ marginTop: 4 }}>{errors.confirmation.message}</FormErrorText>
                    )}
                </FormControl>
            </S.QuestionWrapper>
            <S.OptionsWrapper>
                <Button variant="secondary" disabled={isLoading || isSubmitLeavingSuccess} onClick={closeLeaveProjectWizard}>
                    Cancel
                </Button>
                <Button
                    iconSvg={<Tick />}
                    iconSize={9}
                    isLoading={isLoading}
                    loadingIconSize={11}
                    onClick={handleSubmit(onConfirm)}
                    disabled={!isConfirmed || isSubmitLeavingSuccess}
                >
                    Confirm
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default Confirmation;
