import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, Tick, Checkbox, FormControl, FormErrorText } from "@breakingwave/react-ui-components";
import { useTeamAssignmentStepsContext } from "../_state/context";
import { TeamAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import { useProjectPageContext } from "../../../../_state/context";
import { ProjectPageActionTypesEnum } from "../../../../_state/actions";
import { TeamAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { confirmationStepSchema, ConfirmationStepInputs } from "../index.schema";
import { DeviceTypeEnum, EndDateModeEnum } from "../../../../../../../types/shared";
import countries from "../../../../../../../utils/countries";
import formatRateDisplay from "../../../../../../../utils/formatRateDisplay";
import { IProjectTeamAssignmentErrorResponse } from "../../../../../../../types/teamAssignment";
import { projectTeamAssignmentBlockerReason } from "../../../../../../../utils/projectTeamAssignmentBlockerReason";
import useCreateProjectTeamAssignment from "../../../../../../../api/createProjectTeamAssignment";
import * as S from "../../wizard.styles";

const Confirmation: React.FC = () => {
    const { state: projectPageState, dispatch: projectPageDispatch } = useProjectPageContext();

    const { state: teamAssignmentWizardState, dispatch } = useTeamAssignmentStepsContext();

    // Fully typed, shorter spelling of the step name for easy access
    const step = teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.Confirmation];

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError
    } = useForm<ConfirmationStepInputs>({
        resolver: yupResolver(confirmationStepSchema),
        defaultValues: {
            confirmation: step.answer.confirmation
        }
    });

    const {
        isLoading,
        isSuccess,
        data: fetchedDataAfterCreate,
        mutateAsync: createProjectTeamAssignment
    } = useCreateProjectTeamAssignment(projectPageState.selectedProject.ProjectSlug);

    const goBack = (): void => {
        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP
        });
    };

    const onConfirm = async (data: ConfirmationStepInputs): Promise<void> => {
        const { confirmation } = data;

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.SET_CONFIRMATION,
            payload: {
                ...step,
                answer: {
                    confirmation: confirmation as boolean
                }
            }
        });

        await createProjectTeamAssignment({
            ProjectName: projectPageState.selectedProject.DisplayName,
            MemberType: teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.MemberType].answer,
            FirstName:
                teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.TeamMemberName].answer.firstName,
            LastName: teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.TeamMemberName].answer.lastName,
            ContactEmailAddress: teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.TeamMemberName].answer.contactEmailAddress,
            Country: teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.Country].answer
                .country as typeof countries[keyof typeof countries],
            CountryIso: teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.Country].answer
                .isoString as keyof typeof countries,
            StartDate: teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.StartDate].answer.isoString,
            EndDate: teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.EndDate].answer.isoString,
            EndDateMode: teamAssignmentWizardState.endDate.answer.endDateMode as EndDateModeEnum,
            DeviceType: teamAssignmentWizardState.deviceSelection.answer.deviceType as DeviceTypeEnum,
            Team: teamAssignmentWizardState[TeamAssignmentWizardStepIdentifierEnum.TeamSelection].answer.team,
            ProjectUpnDomain: projectPageState.selectedProject.BaseUpnDomain
        }).catch((error) => {
            
            const result = error.response.data as IProjectTeamAssignmentErrorResponse;
            const bitwise: number = result.ValidationResult;

            setError("confirmation", { 
                message: projectTeamAssignmentBlockerReason(bitwise)
            });
        });
    };

    React.useEffect(() => {
        if (isSuccess) {
            // Save the newly fetched single team assignment data to the page-level state.
            projectPageDispatch({
                type: ProjectPageActionTypesEnum.SET_TEAM_ASSIGNMENT_WIZARD,
                payload: {
                    isActive: false,
                    isCompleted: true,
                    tempTeamAssignmentStepsData: fetchedDataAfterCreate
                }
            });
        }
    }, [isSuccess]);

    return (
        <>
            <S.QuestionWrapper style={{ marginBottom: 0 }}>
                <Text size={14} weight={500}>
                    You are about to request approval for {teamAssignmentWizardState.teamMemberName.answer.displayValue}{" "}
                    to join the project team.
                    <br />
                    The charge for this subscription will be{" "}
                    {formatRateDisplay(
                        projectPageState.selectedProject.Rate,
                        projectPageState.selectedProject.RateCurrency
                    )}{" "}
                    per calendar month.
                </Text>
                <Text size={12} color="white.50">
                    Billing will commence once your team member has received their credentials.
                </Text>
            </S.QuestionWrapper>
            <S.AnswerWrapper style={{ marginBottom: 10 }}>
                <FormControl as="fieldset">
                    <Checkbox {...register("confirmation")} disabled={isLoading} error={!!errors.confirmation}>
                        I confirm that I am happy to adhere to the above
                    </Checkbox>
                    {errors.confirmation && (
                        <FormErrorText style={{ marginTop: 4 }}>{errors.confirmation.message}</FormErrorText>
                    )}
                </FormControl>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button variant="secondary" disabled={isLoading || isSuccess} onClick={goBack}>
                    Back
                </Button>
                <Button 
                    iconSvg={<Tick />} 
                    iconSize={9} 
                    isLoading={isLoading} 
                    disabled={isSuccess}
                    onClick={handleSubmit(onConfirm)}>
                    Submit
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default Confirmation;
