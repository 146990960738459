import React from "react";
import { TeamAssignmentWizardStepsActionTypesEnum } from "./actions";
import {
    TeamAssignmentWizardStepIdentifierEnum,
    ITeamAssignmentWizardStepsState,
    ITeamAssignmentWizardStepsState__UndefinedFiltered
} from "./types";
import initialTeamAssignmentStepsState from "./initialState";

export interface ILoadNextStepAction {
    type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP;
}

export interface ILoadPrevStepAction {
    type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP;
}

export interface ISetTeamMemberNameAction {
    type: TeamAssignmentWizardStepsActionTypesEnum.SET_TEAM_MEMBER_NAME;
    payload: ITeamAssignmentWizardStepsState[TeamAssignmentWizardStepIdentifierEnum.TeamMemberName];
}

export interface ISetSubscriptionTypeAction {
    type: TeamAssignmentWizardStepsActionTypesEnum.SET_SUBSCRIPTION_TYPE;
    payload: ITeamAssignmentWizardStepsState[TeamAssignmentWizardStepIdentifierEnum.SubscriptionType];
}

export interface ISetRateAction {
    type: TeamAssignmentWizardStepsActionTypesEnum.SET_DAILY_RATE;
    payload: ITeamAssignmentWizardStepsState[TeamAssignmentWizardStepIdentifierEnum.Rate];
}

export interface ISetCountryAction {
    type: TeamAssignmentWizardStepsActionTypesEnum.SET_COUNTRY;
    payload: ITeamAssignmentWizardStepsState[TeamAssignmentWizardStepIdentifierEnum.Country];
}

export interface ISetStartDateAction {
    type: TeamAssignmentWizardStepsActionTypesEnum.SET_START_DATE;
    payload: ITeamAssignmentWizardStepsState[TeamAssignmentWizardStepIdentifierEnum.StartDate];
}

export interface ISetEndDateAction {
    type: TeamAssignmentWizardStepsActionTypesEnum.SET_END_DATE;
    payload: ITeamAssignmentWizardStepsState[TeamAssignmentWizardStepIdentifierEnum.EndDate];
}

export interface ISetDeviceSelection {
    type: TeamAssignmentWizardStepsActionTypesEnum.SET_DEVICE_SELECTION;
    payload: ITeamAssignmentWizardStepsState[TeamAssignmentWizardStepIdentifierEnum.DeviceSelection];
}

export interface ISetTeamSelection {
    type: TeamAssignmentWizardStepsActionTypesEnum.SET_TEAM_SELECTION;
    payload: ITeamAssignmentWizardStepsState[TeamAssignmentWizardStepIdentifierEnum.TeamSelection];
}

export interface ISetConfirmationAction {
    type: TeamAssignmentWizardStepsActionTypesEnum.SET_CONFIRMATION;
    payload: ITeamAssignmentWizardStepsState[TeamAssignmentWizardStepIdentifierEnum.Confirmation];
}

export type TeamAssignmentWizardStepsActions =
    | ILoadNextStepAction
    | ILoadPrevStepAction
    | ISetTeamMemberNameAction
    | ISetSubscriptionTypeAction
    | ISetRateAction
    | ISetCountryAction
    | ISetStartDateAction
    | ISetEndDateAction
    | ISetDeviceSelection
    | ISetTeamSelection
    | ISetConfirmationAction;

export const reducer: React.Reducer<ITeamAssignmentWizardStepsState, TeamAssignmentWizardStepsActions> = (state, action) => {
    const activeStep = Object.keys(state)
        .filter((f) => state[f as keyof ITeamAssignmentWizardStepsState].isStepActive)
        .reduce((r) => r.toString());
    const activeStepOrder = state[activeStep as keyof ITeamAssignmentWizardStepsState__UndefinedFiltered].order;

    switch (action.type) {
        case TeamAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP: {
            if (activeStepOrder >= 1 && activeStepOrder < Object.keys(state).length) {
                const nextStepFromActiveStepOrder = Object.keys(state).find(
                    (f) => state[f as keyof ITeamAssignmentWizardStepsState].order === activeStepOrder + 1
                );
                return {
                    ...state,
                    [activeStep as keyof ITeamAssignmentWizardStepsState]: {
                        ...state[activeStep as keyof ITeamAssignmentWizardStepsState],
                        isStepActive: false,
                        isStepAnswered: true
                    },
                    [nextStepFromActiveStepOrder as keyof ITeamAssignmentWizardStepsState]: {
                        ...state[nextStepFromActiveStepOrder as keyof ITeamAssignmentWizardStepsState],
                        isStepActive: true
                    }
                };
            } else {
                // Error: Next step not found.
                return state;
            }
        }
        case TeamAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP: {
            const prevStepFromActiveStepOrder = Object.keys(state).find(
                (f) => state[f as keyof ITeamAssignmentWizardStepsState__UndefinedFiltered].order === activeStepOrder - 1
            );
            if (activeStepOrder > 1 && activeStepOrder <= Object.keys(state).length) {
                return {
                    ...state,
                    [activeStep as keyof ITeamAssignmentWizardStepsState]: {
                        ...state[activeStep as keyof ITeamAssignmentWizardStepsState],
                        isStepActive: false
                    },
                    [prevStepFromActiveStepOrder as keyof ITeamAssignmentWizardStepsState]: {
                        ...state[prevStepFromActiveStepOrder as keyof ITeamAssignmentWizardStepsState],
                        isStepActive: true,
                        isStepAnswered: false
                    }
                };
            } else {
                // Error: Previous step not found.
                return state;
            }
        }
        case TeamAssignmentWizardStepsActionTypesEnum.SET_TEAM_MEMBER_NAME: {
            return {
                ...state,
                [TeamAssignmentWizardStepIdentifierEnum.TeamMemberName]: {
                    ...action.payload
                }
            };
        }
        case TeamAssignmentWizardStepsActionTypesEnum.SET_SUBSCRIPTION_TYPE: {
            return {
                ...state,
                [TeamAssignmentWizardStepIdentifierEnum.SubscriptionType]: {
                    ...action.payload
                }
            };
        }
        case TeamAssignmentWizardStepsActionTypesEnum.SET_DAILY_RATE: {
            return {
                ...state,
                [TeamAssignmentWizardStepIdentifierEnum.Rate]: {
                    ...action.payload
                }
            };
        }
        case TeamAssignmentWizardStepsActionTypesEnum.SET_COUNTRY: {
            return {
                ...state,
                [TeamAssignmentWizardStepIdentifierEnum.Country]: {
                    ...action.payload
                }
            };
        }
        case TeamAssignmentWizardStepsActionTypesEnum.SET_START_DATE: {
            return {
                ...state,
                [TeamAssignmentWizardStepIdentifierEnum.StartDate]: {
                    ...action.payload
                }
            };
        }
        case TeamAssignmentWizardStepsActionTypesEnum.SET_END_DATE: {
            return {
                ...state,
                [TeamAssignmentWizardStepIdentifierEnum.EndDate]: {
                    ...action.payload
                }
            };
        }
        case TeamAssignmentWizardStepsActionTypesEnum.SET_DEVICE_SELECTION: {
            return {
                ...state,
                [TeamAssignmentWizardStepIdentifierEnum.DeviceSelection]: {
                    ...action.payload
                }
            };
        }
        case TeamAssignmentWizardStepsActionTypesEnum.SET_TEAM_SELECTION: {
            return {
                ...state,
                [TeamAssignmentWizardStepIdentifierEnum.TeamSelection]: {
                    ...action.payload
                }
            };
        }        
        case TeamAssignmentWizardStepsActionTypesEnum.SET_CONFIRMATION: {
            return {
                ...state,
                [TeamAssignmentWizardStepIdentifierEnum.Confirmation]: {
                    ...action.payload
                }
            };
        }
        default:
            return state;
    }
};

interface ITeamAssignmentStepsContextProps {
    state: ITeamAssignmentWizardStepsState;
    dispatch: React.Dispatch<TeamAssignmentWizardStepsActions>;
}

export const TeamAssignmentStepsContext = React.createContext<ITeamAssignmentStepsContextProps>({
    state: initialTeamAssignmentStepsState,
    dispatch: () => null
});

export const useTeamAssignmentStepsContext = (): ITeamAssignmentStepsContextProps =>
    React.useContext(TeamAssignmentStepsContext);
