/* eslint-disable @typescript-eslint/naming-convention */
import { ITaskItem } from "./shared";

export enum JoinProjectTaskAutomationKeyEnum {
    JOIN_REQUEST_APPROVED = "JOIN_REQUEST_APPROVED",
}

export enum JoinProjectMemberBlockerEnum {
    MemberOfCurrentProject = "MemberOfCurrentProject",
    Onboarding = "Onboarding",
    JoiningCurrentProject = "JoiningCurrentProject",
    LeavingAnyProject = "LeavingAnyProject",
    Offboarding = "Offboarding",
    OutsideProjectDomain = "OutsideProjectDomain",
    InFlightAdminAssignmentToCurrentProject = "InFlightAdminAssignmentToCurrentProject",
}

export enum ApproverTypeEnum {
    ProjectAdmin = "ProjectAdmin",
    Moderator = "Moderator"
}

export interface ICreateJoinProject {
    UserPrincipalName: string;
}

export interface IJoinProjectResponse {
    ProjectSlug: string;
    ProjectName: string;
    Id: string;
    FirstName: string;
    LastName: string;
    DateCreatedUtc: Date;
    UserPrincipalName: string;
    CreatedByName: string;
    BoardId: string;
    Status: string; // TODO: Change it to enum types. Find statuses from the state machine doc
    StatusDateTimeUtc: Date;
    ApprovedDateTimeUtc: Date;
    ModeratorName: string;
    ModeratorEmail: string;
    TaskItems: ITaskItem[];
}

export interface IJoinProjectMemberEligibility {
    FirstName: string;
    LastName: string;
    ContactEmailAddress: string;
    UserPrincipalName: string;
    Blocker: JoinProjectMemberBlockerEnum;
    IsEligible: boolean;
}

export interface IJoinProjectApprovalPayload {
    UserId?: string;
    ApproverType: ApproverTypeEnum;
}

export enum CreateJoinProjectDomainValidationFailureReasonEnum {
    Success = 0,
    ProjectNotFound = 1 << 0, // 1
    AuthenticatedUserDoesNotHavePermission = 1 << 1, // 2
    ContactEmailDomainDoesNotMatchProjectDomain = 1 << 2, // 4
    InFlightManagedProcesses = 1 << 3, // 8
    AlreadyJoiningProject = 1 << 4, // 16
    AlreadyMemberOfProject = 1 << 5, // 32
    HomeProjectNotFound = 1 << 6, // 64
}

export interface IJoinProjectErrorResponse {
    ValidationResult: CreateJoinProjectDomainValidationFailureReasonEnum;
}
