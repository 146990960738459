import axios from "axios";
import { UseMutationResult, useMutation } from "react-query";
import { protectedResources } from "../auth/authConfig";
import { IManagedContext, TaskAutomationKeyUnion } from "../types/shared";

const notifyAutomationServiceUpdate = async (
    context: IManagedContext,
    projectSlug: string,
    taskId: TaskAutomationKeyUnion,
    accessToken: string | undefined,
    payload: unknown
): Promise<any> => {
    const url = `${protectedResources.wavecore.endpoint}/api/AutomationServiceDispatcher/${context.ResourceType}/${projectSlug}/${context.Id}/${taskId}/${accessToken}`;

    const response = await axios({
        method: "POST",
        url: url,
        data: payload
    });
    return response;
};

function useNotifyAutomationService (
    context: IManagedContext,
    projectSlug: string,
    accessToken: string | undefined,
    taskId: TaskAutomationKeyUnion
): UseMutationResult<unknown> {
    return useMutation((payload: unknown) =>
        notifyAutomationServiceUpdate(context, projectSlug, taskId, accessToken, payload)
    );
}

export default useNotifyAutomationService;
