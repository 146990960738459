/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { IProject } from "../../../../types/projectCatalogue";
import { IJoinProjectWizard, ILeaveProjectWizard, IOffboardTeamMemberWizard, IProjectAdminAssignmentWizard, IProjectPageState, IRevokeProjectAdminRightsWizard, ITeamAssignmentWizard } from "./types";
import { ProjectPageActionTypesEnum } from "./actions";
import initialProjectPageState from "./initialState";

export interface ISetProjectAction {
    type: ProjectPageActionTypesEnum.SET_PROJECT;
    payload: IProject;
}

export interface ISetTeamAssignmentWizardAction {
    type: ProjectPageActionTypesEnum.SET_TEAM_ASSIGNMENT_WIZARD;
    payload: ITeamAssignmentWizard;
}

export interface ISetJoinProjectWizardAction {
    type: ProjectPageActionTypesEnum.SET_JOIN_PROJECT_WIZARD;
    payload: IJoinProjectWizard;
}

export interface ISetLeaveProjectWizardAction {
    type: ProjectPageActionTypesEnum.SET_LEAVE_PROJECT_WIZARD;
    payload: ILeaveProjectWizard;
}

export interface ISetOffboardTeamMemberWizardAction {
    type: ProjectPageActionTypesEnum.SET_OFFBOARD_TEAM_MEMBER_WIZARD;
    payload: IOffboardTeamMemberWizard;
}

export interface ISetProjectAdminAssignmentWizardAction {
    type: ProjectPageActionTypesEnum.SET_PROJECT_ADMIN_ASSIGNMENT_WIZARD;
    payload: IProjectAdminAssignmentWizard;
}

export interface ISetRevokeProjectAdminRightsWizardAction {
    type: ProjectPageActionTypesEnum.SET_REVOKE_ADMIN_RIGHTS_WIZARD;
    payload: IRevokeProjectAdminRightsWizard;
}

export interface ICancelPageLevelWizards {
    type: ProjectPageActionTypesEnum.CANCEL_PAGE_LEVEL_WIZARDS;
}

export type ProjectPageActions =
    | ISetProjectAction
    | ISetTeamAssignmentWizardAction
    | ISetJoinProjectWizardAction
    | ISetLeaveProjectWizardAction
    | ISetOffboardTeamMemberWizardAction 
    | ISetProjectAdminAssignmentWizardAction
    | ISetRevokeProjectAdminRightsWizardAction
    | ICancelPageLevelWizards;

export const reducer: React.Reducer<IProjectPageState, ProjectPageActions> = (state, action) => {
    switch (action.type) {
        // When changing project we should clear down all temporary wizard page level state.
        case ProjectPageActionTypesEnum.SET_PROJECT: {
            return {
                ...initialProjectPageState,
                selectedProject: action.payload
            };
        }
        case ProjectPageActionTypesEnum.SET_TEAM_ASSIGNMENT_WIZARD: {
            return {
                ...state,
                teamAssignmentWizard: action.payload
            };
        }
        case ProjectPageActionTypesEnum.SET_JOIN_PROJECT_WIZARD: {
            return {
                ...state,
                joinProjectWizard: action.payload
            };
        }        
        case ProjectPageActionTypesEnum.SET_LEAVE_PROJECT_WIZARD: {
            return {
                ...state,
                leaveProjectWizard: action.payload
            };
        }     
        case ProjectPageActionTypesEnum.SET_OFFBOARD_TEAM_MEMBER_WIZARD: {
            return {
                ...state,
                offboardTeamMemberWizard: action.payload
            };
        }       
        case ProjectPageActionTypesEnum.SET_PROJECT_ADMIN_ASSIGNMENT_WIZARD: {
            return {
                ...state,
                projectAdminAssignmentWizard: action.payload
            };
        }                
        case ProjectPageActionTypesEnum.SET_REVOKE_ADMIN_RIGHTS_WIZARD: {
            return {
                ...state,
                revokeProjectAdminRightsWizard: action.payload
            };
        }

        case ProjectPageActionTypesEnum.CANCEL_PAGE_LEVEL_WIZARDS: {
            return {
                ...state,
                teamAssignmentWizard: {
                    isActive: false,
                    isCompleted: false,
                    tempTeamAssignmentStepsData: undefined         
                },
                joinProjectWizard: {
                    isActive: false,
                    isCompleted: false,
                    tempJoinProjectStepsData: undefined,
                    lastSavedJoinUPN: undefined    
                },
                projectAdminAssignmentWizard: {
                    isActive: false,
                    isCompleted: false,
                    tempProjectAdminAssignmentStepsData: undefined,
                    lastSavedAdminAssignmentUPN: undefined             
                }
            };
        }        

        default:
            return state;
    }
};

export interface IProjectPageContextProps {
    state: IProjectPageState;
    dispatch: React.Dispatch<ProjectPageActions>;
}

export const ProjectPageContext = React.createContext<IProjectPageContextProps>({
    state: initialProjectPageState,
    dispatch: () => null
});

export function useProjectPageContext (): IProjectPageContextProps {
    return React.useContext(ProjectPageContext);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const ProjectPageProvider = ({ children }: any) => {
    const [state, dispatch] = React.useReducer(reducer, initialProjectPageState);
    const value = { state, dispatch };
    return <ProjectPageContext.Provider value={value}>{children}</ProjectPageContext.Provider>;
};
