import { IOnboardingProjectAdminState } from "./types";

export const initialOnboardingProjectAdminState: IOnboardingProjectAdminState = {
    ManagedContext: {
        Id: undefined,
        ProjectSlugOrProjectId: undefined,
        ResourceType: undefined
    },
    ManagedProcess: undefined,
    AccessToken: ""
};
