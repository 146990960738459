import { CreateLeaveProjectDomainValidationFailureReasonEnum } from "../types/leaveProject";

const leaveProjectDomainValidationFailureReason = (bitwise: number): string => {

    if ((bitwise & CreateLeaveProjectDomainValidationFailureReasonEnum.ProjectNotFound) === 
            CreateLeaveProjectDomainValidationFailureReasonEnum.ProjectNotFound) {
        return "Project not found";
    }

    if ((bitwise & CreateLeaveProjectDomainValidationFailureReasonEnum.NotAMemberOfProject) === 
            CreateLeaveProjectDomainValidationFailureReasonEnum.NotAMemberOfProject) {
        return "Team member is not a member of this project";
    }

    if ((bitwise & CreateLeaveProjectDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) === 
            CreateLeaveProjectDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) {
        return "You do not have permission to add this user";
    }

    if ((bitwise & CreateLeaveProjectDomainValidationFailureReasonEnum.UserBelongsToSingleProject) === 
            CreateLeaveProjectDomainValidationFailureReasonEnum.UserBelongsToSingleProject) {
        return "User must belong to at least one project"; // TODO: remove this restriction?
    }   
    
    if ((bitwise & CreateLeaveProjectDomainValidationFailureReasonEnum.InFlightManagedProcesses) === 
            CreateLeaveProjectDomainValidationFailureReasonEnum.InFlightManagedProcesses) {
        return "User has existing managed processes";
    }      
    
    if ((bitwise & CreateLeaveProjectDomainValidationFailureReasonEnum.ProjectAdminCannotRemoveThemselfFromProject) === 
            CreateLeaveProjectDomainValidationFailureReasonEnum.ProjectAdminCannotRemoveThemselfFromProject) {
        return "You cannot remove yourself from the project";
    }          

    return "An error occurred";
};

export {
    leaveProjectDomainValidationFailureReason
};
