import styled from "styled-components";

const PageTitleSection = styled.section`
    background-color: #000;
    margin-bottom: 32px;
`;

const BodySection = styled.section`
    text-align: center;
`;

const ConfirmationSection = styled.section`
    background-color: #000000;
    padding: 32px 0;
`;

// TODO: Make this one a component on its own
const Hr = styled.hr<{ type?: string; color?: string; hidden?: boolean }>`
    border-top: 1px ${({ type }) => type ?? "solid"} ${({ color }) => color ?? "#000"};
    background-color: transparent;
    visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
`;

export { PageTitleSection, BodySection, ConfirmationSection, Hr };
