import React from "react";
import dayjs from "dayjs";
import { Text } from "@breakingwave/react-ui-components";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../../types/teamMemberDisplayItems";
import useGroupStatusesCompletePercent from "../../../../../hooks/useGroupStatusesCompletePercent";
import useUserType from "../../../../../hooks/useUserType";
import TeamAccordion from "../../../../../components/accordion/TeamAccordion";
import { AccordionToggleIconTypeEnum } from "../../../../../types/accordions";
import formatRateDisplay from "../../../../../utils/formatRateDisplay";
import GroupStatusIcon from "../../../../../components/group-status-icon";
import { InferredProjectTeamMemberStatusEnum } from "../../../../../types/teamAssignment";
import { teamMemberGroupStatusColours, TeamMemberGroupStatusColourModeEnum } from "./index.colours";
import * as S from "./index.styles";

interface ITeamMemberGroupStatusesProps {
    data: IProjectTeamMemberDisplayItemsResponse;
    colourMode: TeamMemberGroupStatusColourModeEnum;
    defaultOpenState?: boolean;
}

const TeamMemberGroupStatuses: React.FC<ITeamMemberGroupStatusesProps> = ({
    data: teamMember,
    colourMode,
    ...rest
}) => {

    const { groupStatusPercentage } = useGroupStatusesCompletePercent(teamMember?.GroupStatuses ?? []);

    const { userType } = useUserType(teamMember);

    const coloursForMode = teamMemberGroupStatusColours.mode[colourMode as keyof typeof teamMemberGroupStatusColours.mode];

    return (
        <TeamAccordion
            profileProgressCircleProps={{
                size: 30,
                percentage: groupStatusPercentage,
                userType,
                percentagePathColour: coloursForMode.memberIcon[teamMember.InferredProjectTeamMemberStatus]
            }}
            isToggleable={true}
            hasToggleIcon={true}
            toggleIconType={AccordionToggleIconTypeEnum.Chevron}
            homeProjectName={teamMember.HomeProjectName}
            teamMemberDisplayName={`${teamMember.FirstName} ${teamMember.LastName}`}
            country={teamMember.Country}
            deviceType={teamMember.DeviceType}
            startDate={dayjs(teamMember.StartDate).format("DD MMM YYYY")}
            team={teamMember.Team}
            rate={formatRateDisplay(teamMember.Rate, teamMember.RateCurrency)}
            defaultOpenState={rest.defaultOpenState ?? false}
        >
            <S.GroupStatusesAccordionBody>
                <Text size={14} weight={500} style={{ marginBottom: 18 }}>
                    {teamMember.InferredProjectTeamMemberStatus === InferredProjectTeamMemberStatusEnum.Indeterminate ? "Unable to determine the managed process status" : "Current status"}
                </Text>
                <S.AllGroupStatusesWrapper>
                    {teamMember.GroupStatuses?.map((group, i) => (
                        <S.EachGroupWrapper key={i}>
                            <S.GroupIcon>
                                <GroupStatusIcon
                                    size={26}
                                    status={group.Status}
                                    color={coloursForMode.status[group.Status]}
                                />
                            </S.GroupIcon>
                            <S.GroupName>
                                {group.GroupId}
                            </S.GroupName>
                            <S.GroupStatus color={coloursForMode.status[group.Status]}>
                                {group.Status}
                            </S.GroupStatus>
                        </S.EachGroupWrapper>
                    ))}
                </S.AllGroupStatusesWrapper>
            </S.GroupStatusesAccordionBody>
        </TeamAccordion>
    );
};

export default TeamMemberGroupStatuses;
