import styled from "styled-components";
import Column from "../grid/Column";
import { breakpoints } from "../grid/mediaQueries";

const Footer = styled.footer`
    display: flex;
    margin-top: auto;
    background-color: rgba(0, 0, 0, 0.25);
    color: #c7c7c7;
    padding: 32px 0;

    @media ${breakpoints.sm} {
        padding-top: 40px;
    } ;
`;

const FooterLinksList = styled.ul`
    li {
        display: flex;
        margin-bottom: 24px;
        margin-right: 0;

        @media ${breakpoints.md} {
            display: inline-flex;
            margin-bottom: 0;

            &:not(:last-child) {
                margin-right: 56px;
            }
        }

        @media ${breakpoints.xl} {
            &:not(:last-child) {
                margin-right: 112px;
            }
        }

        a {
            font-size: 14px;
            color: #c7c7c7;

            &:hover {
                color: #46e1ff;
                text-decoration: underline;
            }
        }
    }
`;

const FooterDBInitiativeWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const FooterDBInitiativeSvg = styled.svg`
    filter: brightness(0) saturate(100%) invert(84%) sepia(47%) saturate(10%) hue-rotate(16deg) brightness(89%)
        contrast(87%);
`;

const PushRightBelowTablet = styled.div`
    display: flex;
    justify-content: initial;

    @media ${breakpoints.md} {
        justify-content: flex-end;
    }
`;

const DividerAboveTablet = styled(Column)`
    display: block;

    @media ${breakpoints.md} {
        display: none;
    }

    // TODO: We should move hr styles into the bw-react-ui-components repo. We could add it into global styles and/or create a custom component.
    hr {
        background-color: rgba(255, 255, 255, 0.25);
        border: none;
        display: block;
    }
`;

const DividerBelowTablet = styled(Column)`
    display: none;

    @media ${breakpoints.md} {
        display: block;
    }

    // TODO: We should move hr styles into the bw-react-ui-components repo. We could add it into global styles and/or create a custom component.
    hr {
        background-color: rgba(255, 255, 255, 0.25);
        border: none;
        display: block;
    }
`;

export {
    Footer,
    FooterLinksList,
    FooterDBInitiativeWrapper,
    FooterDBInitiativeSvg,
    PushRightBelowTablet,
    DividerAboveTablet,
    DividerBelowTablet
};
