// TODO: These breakpoints weren't discussed and finalized.
// BACKLOG: Once this work is merged into react-ui-components, these will become part of the DefaultTheme.
// Note: No breakpoint for xs because it is default
interface IBreakpointKeys {
    sm: number;
    md: number;
    lg: number;
    xl: number;
    xxl: number;
}

export const breakpointKeys: IBreakpointKeys = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
};
