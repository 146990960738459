import React from "react";
import PropTypes from "prop-types";
import { useGridContext } from "./context";
import * as S from "./index.styles";

type ColProps =
    | number
    | {
        size?: number;
        offset?: number;
        order?: number;
    };
export interface IColumnProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    sm?: ColProps; // TODO: Limit 1- 12
    md?: ColProps; // TODO: Limit 1- 12
    lg?: ColProps; // TODO: Limit 1- 12
    xl?: ColProps; // TODO: Limit 1- 12
    xxl?: ColProps; // TODO: Limit 1- 12
    // TODO: Add utility props to show/hide on mobile
}

/**
 * Columns are smallest units of the grid that are rendered by rows.
 * @param {React.ReactNode} children - A React node that the <Column /> renders.
 * @param {ColProps} [sm] - ...
 * @param {ColProps} [md] - ...
 * @param {ColProps} [lg] - ...
 * @param {ColProps} [xl] - ...
 * @param {ColProps} [xxl] - ...
 * */

const Column: React.FC<IColumnProps> = ({ children, ...rest }) => {
    const { isResposive } = useGridContext();

    return (
        <S.Column isResponsive={isResposive} {...rest}>
            {children}
        </S.Column>
    );
};

const columnProps = PropTypes.oneOfType([PropTypes.number, PropTypes.object]);

Column.propTypes = {
    children: PropTypes.node.isRequired,
    sm: columnProps,
    md: columnProps,
    lg: columnProps,
    xl: columnProps,
    xxl: columnProps
};

export default Column;
