import React from "react";
import { Heading, Text, TextField } from "@breakingwave/react-ui-components";
import { useProjectPageContext } from "../_state/context";
import Column from "../../../../components/grid/Column";
import Container from "../../../../components/grid/Container";
import Row from "../../../../components/grid/Row";
import useGetProjectTeamMemberDisplayItems from "../../../../api/getProjectTeamMemberDisplayItems";
import Spinner from "../../../../components/spinner";
import { queryClient } from "../../../../App/queryClient";
import { QueryKeysEnum } from "../../../../types/queryKeys";
import usePrevious from "../../../../hooks/usePrevious";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../types/teamMemberDisplayItems";
import AddOrCreateTeamWizardsDropdown from "./add-or-create-team-wizards-dropdown";
import TeamMembers from "./TeamMembers";
import ProjectAdminAssignmentWizard from "./wizards/project-admin-assignment-wizard";
import JoinProjectWizard from "./wizards/join-project-wizard";
import TeamAssignmentWizard from "./wizards/team-assignment-wizard";
import * as S from "../index.styles";

const TeamMembersTab: React.FC = () => {
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    const {
        state: {
            selectedProject: { ProjectSlug },
            teamAssignmentWizard: { isActive: isTeamAssignmentWizardActive },
            joinProjectWizard: { isActive: isJoinProjectWizardActive },
            leaveProjectWizard: { isActive: isLeaveProjectWizardActive },
            offboardTeamMemberWizard: { isActive: isOffboardTeamMemberWizardActive },
            projectAdminAssignmentWizard: { isActive: isProjectAdminWizardActive, tempProjectAdminAssignmentStepsData }
        }
    } = useProjectPageContext();

    const previousProjectSlug = usePrevious(ProjectSlug);

    const {
        data: teamMembers,
        isLoading: isLoadingTeamMembers,
        refetch: reloadTeamMembers        
    } = useGetProjectTeamMemberDisplayItems(ProjectSlug);

    React.useEffect(() => {
        // Clear the team members cache and force an immediate 
        // reload when the project slug drop down is changed.
        if (!!previousProjectSlug && previousProjectSlug !== ProjectSlug) {
            queryClient.removeQueries(QueryKeysEnum.teamMemberDisplayItems);        
            reloadTeamMembers();
        }
    }, [ProjectSlug]);

    /**
     * Total number of team members, newly activated team assignment wizard or join project wizard.
     */
    const numberTeamMemberDisplayItems = React.useMemo(() => {
        let count = teamMembers?.length ?? 0;

        if (isTeamAssignmentWizardActive || isJoinProjectWizardActive) {
            count++;
        }
        return count;
    }, [teamMembers, isTeamAssignmentWizardActive, isJoinProjectWizardActive, ProjectSlug]);

    React.useEffect(() => {
        (isTeamAssignmentWizardActive ||
            isJoinProjectWizardActive) &&
            
            setSearchTerm("");
    }, [
        isTeamAssignmentWizardActive,
        isJoinProjectWizardActive
    ]);

    return (
        <>
            <S.TabHeader isSticky={false}>
                <Container isResponsive={false}>
                    <Row style={{ marginBottom: 48 }}>
                        <Column>
                            <Heading variant="h5" weight={300}>
                                Team members ({numberTeamMemberDisplayItems})
                            </Heading>
                        </Column>
                        <Column>
                            <S.ButtonGroupWrapper>
                                <AddOrCreateTeamWizardsDropdown />
                            </S.ButtonGroupWrapper>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <S.SearchFieldWrapper>
                                <TextField
                                    variant="search"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.currentTarget.value)}
                                    disabled={
                                        isTeamAssignmentWizardActive ||
                                        isJoinProjectWizardActive ||
                                        isLeaveProjectWizardActive ||
                                        isOffboardTeamMemberWizardActive
                                    }
                                />
                            </S.SearchFieldWrapper>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <S.TabHeaderTeamAssignmentsAndMembers>
                                <Text variant="span" size={14} weight={500} color="white.75">
                                    Home
                                </Text>
                                <Text variant="span" size={14} weight={500} color="white.75">
                                    Name
                                </Text>
                                <Text variant="span" size={14} weight={500} color="white.75">
                                    Team
                                </Text>
                                <Text variant="span" size={14} weight={500} color="white.75">
                                    Country
                                </Text>                                                                                                
                                <Text variant="span" size={14} weight={500} color="white.75">
                                    Device Type
                                </Text>                                
                                <Text variant="span" size={14} weight={500} color="white.75">
                                    Start Date
                                </Text>
                                <Text variant="span" size={14} weight={500} color="white.75">
                                    Rate
                                </Text>
                            </S.TabHeaderTeamAssignmentsAndMembers>
                        </Column>
                    </Row>
                </Container>
            </S.TabHeader>
            <S.TabBodyTeamAssignmentsAndMembers>
                <Container isResponsive={false}>
                    <Row>
                        <Column>
                            {isTeamAssignmentWizardActive && <TeamAssignmentWizard />}
                            {isJoinProjectWizardActive && <JoinProjectWizard />}
                            {isProjectAdminWizardActive && 
                                !tempProjectAdminAssignmentStepsData?.UserPrincipalName && 
                                <ProjectAdminAssignmentWizard />}

                            {!isLoadingTeamMembers && teamMembers && (
                                <TeamMembers
                                    data={teamMembers as IProjectTeamMemberDisplayItemsResponse[]}
                                    searchTerm={searchTerm}
                                />
                            )}

                            {isLoadingTeamMembers && <Spinner />}
                        </Column>
                    </Row>
                </Container>
            </S.TabBodyTeamAssignmentsAndMembers>
        </>
    );
};

export default TeamMembersTab;
