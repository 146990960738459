import * as yup from "yup";

type SearchMemberStepInputs = yup.InferType<typeof searchMemberStepSchema>;
type ConfirmationStepInputs = yup.InferType<typeof confirmationStepSchema>;

const searchMemberStepSchema = yup
    .object()
    .shape({
        searchMember: yup
            .object()
            .shape({
                label: yup.string().required("Please search for the team member"),
                value: yup.string().required()
            })
            .required("Please search for the team member")
    })
    .required(); // https://github.com/jquense/yup/issues/946#issuecomment-657327900

const confirmationStepSchema = yup
    .object({
        confirmation: yup.bool().oneOf([true], "Please confirm that you are happy to proceed")
    })
    .required();

export {
    searchMemberStepSchema,
    confirmationStepSchema
};

export type {
    SearchMemberStepInputs,
    ConfirmationStepInputs
};
