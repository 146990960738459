import styled from "styled-components";

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    div:first-child {
        height: auto;
    }
`;

export { LoadingContainer };
