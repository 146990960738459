import axios from "axios";
import { UseQueryOptions, UseQueryResult, useQuery } from "react-query";
import { QueryKeysEnum } from "../types/queryKeys";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { protectedResources } from "../auth/authConfig";
import { IProjectMembershipDto } from "../types/shared";

const getProjectMembership = async (
    accessToken: string,
    upn: string
): Promise<IProjectMembershipDto[]> => {
    const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_WAVE_CORE_SERVICE_URL}/api/ProjectMembership/${upn}`,
        headers: {
            authorization: `Bearer ${accessToken}`
        }
    });
    return response.data;
};

const useGetProjectMembership = (
    projectSlug: string,
    upn: string,
    options?: UseQueryOptions<IProjectMembershipDto[], unknown, IProjectMembershipDto[], string[]>
): UseQueryResult<IProjectMembershipDto[], unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(
        protectedResources.wavecore.scopes
    );
    return useQuery(
        [QueryKeysEnum.projectMembership, projectSlug, upn],
        () => 
            getAccessToken().then((accessToken) => {
                if (accessToken && isAuthenticated) {
                    return getProjectMembership(accessToken, upn);
                }
                return Promise.reject();
            }),
        {
            ...options,
            enabled: isAuthenticated && options?.enabled
        }
    );
};

export default useGetProjectMembership;
