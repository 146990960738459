import React from "react";
import { useJoinProjectWizardStepsContext } from "../_state/context";
import { JoinProjectWizardStepIdentifierEnum, IJoinProjectWizardStepsState } from "../_state/types";
import SearchMember from "./SearchMember";
import Confirmation from "./Confirmation";
import * as S from "../../wizard.styles";

const JoinProjectWizardSteps: React.FC = () => {
    const { state } = useJoinProjectWizardStepsContext();

    const [activeStep, setActiveStep] = React.useState(Object.values(state).find((f) => f.order === 1).identifier);

    React.useEffect(() => {
        const activeStepFromJoinProjectWizard = Object.keys(state).find(
            (f) => state[f as keyof IJoinProjectWizardStepsState].isStepActive
        );
        setActiveStep(activeStepFromJoinProjectWizard);
    }, [state]);

    return (
        <S.Form autoComplete="off">
            {activeStep === JoinProjectWizardStepIdentifierEnum.SearchedMemberData && <SearchMember />}
            {activeStep === JoinProjectWizardStepIdentifierEnum.Confirmation && <Confirmation />}
        </S.Form>
    );
};

export default JoinProjectWizardSteps;
