import styled from "styled-components";

const Spinner = styled.div<{ padding: string | undefined }>`
    width: 100%;
    height: 100%;
    padding: ${({ padding }) => padding ?? "5%"};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export { Spinner };
