import React from "react";
import { Link } from "react-router-dom";
import { Heading } from "@breakingwave/react-ui-components";
import { IHtmlHeadProps } from "../../../components/html-head";
import { IHeaderProps } from "../../../components/header";
import { IFooterProps } from "../../../components/footer";
import Layout from "../../../components/layout";
import Row from "../../../components/grid/Row";
import Column from "../../../components/grid/Column";
import dots from "../../../assets/images/mixed-space-dots-circular@3x.png";
import * as S from "./index.styles";

const NotFound: React.FC = () => {
    const htmlHeadProps: IHtmlHeadProps = {
        pageTitle: "Breaking Wave - 404 Not Found",
        indexPage: false
    };
    const headerProps: IHeaderProps = {
        showHideOnScroll: true,
        hasLoginButtonAndAvatar: true,
        isHeaderResponsive: true
    };

    const footerProps: IFooterProps = {
        hasFooterLinks: false,
        isFooterResponsive: false
    };
    return (
        <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
            <S.StyledCentredDiv>
                <Row>
                    <Column md={{ size: 4, offset: 4 }}>
                        <img
                            src={dots}
                            alt="thank you page mixed space dots circular"
                            style={{ marginBottom: 16 }}
                        />
                    </Column>
                </Row>                
                <Heading variant="h1" weight={500}>
                    404
                </Heading>
                <S.StyledNotFoundHeader>Not Found</S.StyledNotFoundHeader>
                <Link to="/">&#8592; Go Home</Link>
            </S.StyledCentredDiv>
        </Layout>
    );
};

export default NotFound;
