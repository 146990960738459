import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, Tick, Checkbox, FormControl, FormErrorText } from "@breakingwave/react-ui-components";
import { useJoinProjectWizardStepsContext } from "../_state/context";
import { JoinProjectWizardStepsActionTypesEnum } from "../_state/actions";
import { JoinProjectWizardStepIdentifierEnum } from "../_state/types";
import { confirmationStepSchema, ConfirmationStepInputs } from "../index.schema";
import { useProjectPageContext } from "../../../../_state/context";
import { ProjectPageActionTypesEnum } from "../../../../_state/actions";
import useGetProjectTeamMemberDisplayItems from "../../../../../../../api/getProjectTeamMemberDisplayItems";
import { IJoinProjectErrorResponse } from "../../../../../../../types/joinProject";
import { joinProjectDomainValidationFailureReason } from "../../../../../../../utils/joinProjectDomainValidationFailureReason";
import initialJoinProjectWizardStepsState from "../_state/initialState";
import useCreateJoinProject from "../../../../../../../api/createJoinProject";
import * as S from "../../wizard.styles";

const Confirmation: React.FC = () => {

    const { state: joinProjectWizardState, dispatch } = useJoinProjectWizardStepsContext();

    // Fully typed, shorter spelling of the step name for easy access
    const step = joinProjectWizardState[JoinProjectWizardStepIdentifierEnum.Confirmation];

    const {
        state: {
            selectedProject: { 
                ProjectSlug 
            }
        },
        dispatch: projectPageDispatch
    } = useProjectPageContext();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        watch
    } = useForm<ConfirmationStepInputs>({
        resolver: yupResolver(confirmationStepSchema),
        defaultValues: {
            confirmation: step.answer.confirmation
        }
    });

    const isConfirmed: boolean = watch("confirmation") ?? false;

    useEffect(() => {
        clearErrors();
    },[isConfirmed]);

    const goBack = (): void => {
        dispatch({
            type: JoinProjectWizardStepsActionTypesEnum.SET_SEARCHED_MEMBER_DATA, 
            payload: initialJoinProjectWizardStepsState[JoinProjectWizardStepIdentifierEnum.SearchedMemberData]
        });

        dispatch({
            type: JoinProjectWizardStepsActionTypesEnum.LOAD_PREV_STEP
        });
    };

    const { 
        refetch: refetchTeamMemberDisplayItems
    } = useGetProjectTeamMemberDisplayItems(ProjectSlug);

    const { 
        mutateAsync: joinProject, 
        isSuccess: joinProjectSuccess, 
        isLoading: joinProjectLoading,
        data: fetchedDataAfterCreate        
    } = useCreateJoinProject(ProjectSlug);  

    const onConfirm = async (data: ConfirmationStepInputs): Promise<void> => {
        const { confirmation } = data;

        dispatch({
            type: JoinProjectWizardStepsActionTypesEnum.SET_CONFIRMATION,
            payload: {
                ...step,
                answer: {
                    confirmation: confirmation as boolean
                }
            }
        });

        if (confirmation) {
            await joinProject({
                UserPrincipalName: joinProjectWizardState.searchedMemberData.answer.upn
            }).catch((error) => {

                const result = error.response.data as IJoinProjectErrorResponse;
                const bitwise: number = result.ValidationResult;

                setError("confirmation", { 
                    message: joinProjectDomainValidationFailureReason(bitwise)
                });
            });
        }        
    };
    
    React.useEffect(() => {
        if (joinProjectSuccess) {
            refetchTeamMemberDisplayItems();

            projectPageDispatch({
                type: ProjectPageActionTypesEnum.SET_JOIN_PROJECT_WIZARD,
                payload: {
                    isActive: false,
                    isCompleted: true,
                    tempJoinProjectStepsData: undefined,
                    lastSavedJoinUPN: fetchedDataAfterCreate?.UserPrincipalName
                }
            });            
        }
    }, [joinProjectSuccess]);

    return (
        <>
            <S.QuestionWrapper style={{ marginBottom: 0 }}>
                <Text size={14} weight={500}>
                    You are about add {joinProjectWizardState.searchedMemberData.answer.personalDetails.displayValue} to
                    the project team.
                    <br />
                    They will be added once the onboarding process has been completed.
                </Text>
                <Text size={12} color="white.50">
                    Billing will commence once your team member has received their credentials.
                </Text>
            </S.QuestionWrapper>
            <S.AnswerWrapper style={{ marginBottom: 10 }}>
                <FormControl as="fieldset">
                    <Checkbox {...register("confirmation")} error={!!errors.confirmation} disabled={joinProjectLoading || joinProjectSuccess}>
                        I confirm that I am happy to adhere to the above
                    </Checkbox>
                    {errors.confirmation && (
                        <FormErrorText style={{ marginTop: 4 }}>{errors.confirmation.message}</FormErrorText>
                    )}
                </FormControl>                
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button variant="secondary" onClick={goBack} disabled={joinProjectLoading || joinProjectSuccess}>
                    Back
                </Button>
                <Button 
                    iconSvg={<Tick />} 
                    iconSize={9} 
                    loadingIconSize={11} 
                    disabled={!isConfirmed || joinProjectSuccess}
                    isLoading={joinProjectLoading}
                    onClick={handleSubmit(onConfirm)}>
                    Submit
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default Confirmation;
