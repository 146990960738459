export enum OffboardTeamMemberWizardStepIdentifierEnum {
    Confirmation = "confirmation"
}

export interface IOffboardTeamMemberWizardStepsState {
    [OffboardTeamMemberWizardStepIdentifierEnum.Confirmation]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
    };
}
