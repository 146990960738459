import React from "react";
import { useTeamAssignmentStepsContext } from "../_state/context";
import { TeamAssignmentWizardStepIdentifierEnum, ITeamAssignmentWizardStepsState } from "../_state/types";
import TeamMemberName from "./TeamMemberName";
import SubscriptionType from "./SubscriptionType";
import Country from "./Country";
import StartDate from "./StartDate";
import Confirmation from "./Confirmation";
import DeviceSelectionStep from "./DeviceSelectionStep";
import TeamSelection from "./TeamSelection";
import * as S from "../../wizard.styles";

const TeamAssignmentWizardSteps: React.FC = () => {
    const { state } = useTeamAssignmentStepsContext();

    const [activeStep, setActiveStep] = React.useState(Object.values(state).find((f) => f.order === 1).identifier);

    React.useEffect(() => {
        const activeStepFromTeamAssignmentWizard = Object.keys(state).find(
            (f) => state[f as keyof ITeamAssignmentWizardStepsState].isStepActive
        );
        setActiveStep(activeStepFromTeamAssignmentWizard);
    }, [state]);

    return (
        <S.Form autoComplete="off">
            {activeStep === TeamAssignmentWizardStepIdentifierEnum.TeamMemberName && <TeamMemberName />}
            {activeStep === TeamAssignmentWizardStepIdentifierEnum.Country && <Country />}
            {activeStep === TeamAssignmentWizardStepIdentifierEnum.StartDate && <StartDate />}
            {activeStep === TeamAssignmentWizardStepIdentifierEnum.SubscriptionType && <SubscriptionType />}
            {activeStep === TeamAssignmentWizardStepIdentifierEnum.DeviceSelection && <DeviceSelectionStep />}
            {activeStep === TeamAssignmentWizardStepIdentifierEnum.TeamSelection && <TeamSelection />}
            {activeStep === TeamAssignmentWizardStepIdentifierEnum.Confirmation && <Confirmation />}
        </S.Form>
    );
};

export default TeamAssignmentWizardSteps;
