import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Text } from "@breakingwave/react-ui-components";
import Layout from "../../../../components/layout";
import Container from "../../../../components/grid/Container";
import Row from "../../../../components/grid/Row";
import Column from "../../../../components/grid/Column";
import dots from "../../../../assets/images/mixed-space-dots-circular@3x.png";
import { useOnboardingContext } from "../_state/context";
import { footerProps, headerProps, htmlHeadProps } from "./Thanks.config";
import * as S from "./Thanks.styles";

const Thanks: React.FC = () => {
    
    const navigate = useNavigate();    
    const [searchParams] = useSearchParams();

    const { state } = useOnboardingContext();    

    const { 
        ManagedProcess: projectTeamAssignment 
    } = state;

    useEffect(() => {        
        const { id, p, t } = Object.fromEntries([...searchParams]); 

        // If the user hit F5 then the managed process will not be
        // in state and so we need to go back to the landing page.
        if (!projectTeamAssignment) {
            if (id && p && t) {
                console.log("Navigating from 'Thanks' to 'Onboarding Landing'.  User probably hit F5");
                navigate(`/onboarding?id=${id}&p=${p}&t=${t}`);
            } else {
                console.log("Parameters error. Redirecting to root.");
                navigate("/");
            }
        }
    }, []);   

    return (
        <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
            <S.PageTitleSection>
                <Container isResponsive={true}>
                    <Row>
                        <Column>
                            <S.Hr color="#595959" style={{ marginTop: "8px" }} />
                        </Column>
                    </Row>
                </Container>
            </S.PageTitleSection>
            <S.BodySection>
                <Container isResponsive={true}>
                    <Row>
                        <Column md={{ size: 4, offset: 4 }}>
                            <img
                                src={dots}
                                alt="thank you page mixed space dots circular"
                                style={{ marginBottom: 16 }}
                            />
                        </Column>
                    </Row>
                    <Row>
                        <Text variant="p">
                                You have been sent an email to set up your Multi Factor Authentication
                        </Text>                                                            
                    </Row>
                </Container>
            </S.BodySection>
        </Layout>
    );
};

export default Thanks;
