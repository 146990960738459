import React, { useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Text, Button, Tick, FormControl, FormErrorText, Radio } from "@breakingwave/react-ui-components";
import { TeamAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { useTeamAssignmentStepsContext } from "../_state/context";
import { TeamAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import { DeviceSelectionStepInputs, deviceSelectionStepSchema } from "../index.schema";
import { useProjectPageContext } from "../../../../_state/context";
import getFriendlyDeviceName from "../../../../../../../utils/getFriendlyDeviceTypeName";
import { DeviceTypeEnum } from "../../../../../../../types/shared";
import { IHomeProject } from "../../../../../../../types/projectCatalogue";
import * as S from "../../wizard.styles";

interface ISelectableDevice {
    deviceType: DeviceTypeEnum;
    disabled: boolean;
    displayName: string;
}

const DeviceSelectionStep: React.FC = () => {

    const { 
        state: {
            selectedProject
        }
    } = useProjectPageContext();

    const homeProject = useMemo<IHomeProject>(() => selectedProject as IHomeProject, []);    

    const { state, dispatch } = useTeamAssignmentStepsContext();

    const step = state[TeamAssignmentWizardStepIdentifierEnum.DeviceSelection];

    const [availableDevicesConfiguredInProjectCatalogue, setAvailableDevicesConfiguredInProjectCatalogue] = React.useState<DeviceTypeEnum[]>();
    const [selectableDevices, setSelectableDevices] = React.useState<ISelectableDevice[]>();

    const {
        control,
        handleSubmit,
        setValue,
        formState: { 
            errors
        }        
    } = useForm<DeviceSelectionStepInputs>({
        resolver: yupResolver(deviceSelectionStepSchema),
        defaultValues: {
            deviceType: step.answer.deviceType
        }
    });

    React.useEffect(() => {
        const domain = state.teamMemberName.answer.contactEmailAddress.split("@")[1];

        const deviceTypes = domain === homeProject.ProjectDomain ? homeProject.ProjectDomainAvailableDevices : homeProject.NonProjectDomainAvailableDevices;

        setAvailableDevicesConfiguredInProjectCatalogue(deviceTypes);
        
        if (deviceTypes.length === 1) {
            console.log(`Auto selecting ${deviceTypes[0]}`);
            setValue("deviceType", deviceTypes[0]);
        }

        const selectableDevicesTemp: ISelectableDevice[] = [];
        
        Object.values(DeviceTypeEnum).map((deviceType) => {
            if (deviceTypes.includes(deviceType)) {
                const item: ISelectableDevice = {
                    deviceType,
                    disabled: false,
                    displayName: getFriendlyDeviceName(deviceType)
                };
                selectableDevicesTemp.push(item);
            }
        });

        return setSelectableDevices(selectableDevicesTemp);
    }, []);

    const processAndGoToNext = (data: DeviceSelectionStepInputs): void => {
        const { deviceType } = data;

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.SET_DEVICE_SELECTION,
            payload: {
                ...step,
                answer: {
                    deviceType
                }
            }
        });

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP
        });        
    };

    const goBack = (): void => {
        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP
        });
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={500}>
                    Please select the device requirement    
                </Text>         
            </S.QuestionWrapper>
            <S.AnswerWrapper>
                <FormControl as="fieldset">
                    {selectableDevices && 
                        <>
                            <Controller 
                                name="deviceType"
                                control={control}
                                render={({ field }) => (
                                    <S.RadioContainer>
                                        {selectableDevices.map((selectableDeviceType) =>                                                 
                                            <Radio 
                                                variant="withBorder"
                                                key={selectableDeviceType.deviceType} 
                                                value={selectableDeviceType.deviceType}
                                                disabled={selectableDeviceType.disabled}
                                                error={!!errors.deviceType}    
                                                onChange={field.onChange}
                                                name="deviceType"
                                                defaultChecked={
                                                    step.answer.deviceType === selectableDeviceType.deviceType ||
                                                    (availableDevicesConfiguredInProjectCatalogue?.length === 1 && selectableDeviceType.deviceType === availableDevicesConfiguredInProjectCatalogue[0])
                                                }
                                            >

                                                {selectableDeviceType.displayName}
                                            </Radio>
                                        )}                                    
                                    </S.RadioContainer>
                                )} 
                            /> 

                            <div>
                                {errors.deviceType && <FormErrorText>{errors.deviceType.message}</FormErrorText>}
                            </div>
                        </>}
                </FormControl>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button variant="secondary" onClick={goBack}>
                    Back
                </Button>
                <Button iconSvg={<Tick />} iconSize={9} onClick={handleSubmit(processAndGoToNext)}>
                    Next
                </Button>
            </S.OptionsWrapper>
        </>);
};

export default DeviceSelectionStep;
