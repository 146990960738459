import axios from "axios";
import { useMutation, UseMutationResult } from "react-query";
import {
    ITeamAssignmentRequest,
    ITeamAssignmentResponse
} from "../types/teamAssignment";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { protectedResources } from "../auth/authConfig";
import { queryClient } from "../App/queryClient";
import { QueryKeysEnum } from "../types/queryKeys";

const createProjectTeamAssignment = async (
    accessToken: string,
    teamAssignment: ITeamAssignmentRequest,
    projectSlug: string
): Promise<ITeamAssignmentResponse> => {
    const response = await axios({
        method: "POST",
        url: `${protectedResources.wavecore.endpoint}/api/v2/ProjectTeamAssignments/${projectSlug}`,
        data: JSON.stringify(teamAssignment),
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`
        }
    });

    return response.data;
};

const useCreateProjectTeamAssignment = (
    projectSlug: string
): UseMutationResult<ITeamAssignmentResponse, unknown, ITeamAssignmentRequest, unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);
    return useMutation(
        (vars) => getAccessToken().then((accessToken) => {
            if (accessToken && isAuthenticated) {
                return createProjectTeamAssignment(accessToken, vars, projectSlug);
            }
            return Promise.reject();
        }),
        {
            // Fetch a new list of team member display items by invalidating the existing cache following successfully creating a team assignment
            onSuccess: () => {
                queryClient.invalidateQueries(QueryKeysEnum.teamMemberDisplayItems);
            }
        }
    );
};

export default useCreateProjectTeamAssignment;
